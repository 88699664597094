import React, { Component } from 'react';
import firebase from 'firebase';
import { okButton } from '../redux/actions';
import { connect } from 'react-redux';
import Reload from './reload';

class AvisoPrivacidad extends Component {

    state = {
        content: false,
        loading: true
    }

    async componentDidMount() {
        try {
            await this.getInfo();
        } catch (error) {

        }
    }


    getInfo = async () => {
        this.setState({ loading: true });
        try {
            let info = await firebase.firestore().collection('configuraciones').doc('legales').get();

            if (info.exists && info.data().aviso) {
                let data = info.data();
                // console.log(data.aviso.contenido)
                this.setState({ content: data.aviso, loading: false });
            } else {
                this.props.okButton({
                    message: "No se encontro la información solicitada.", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                this.setState({ loading: false });
            }

        } catch (error) {
            this.setState({ loading: false });
            this.props.okButton({
                message: "No se logro obtener el aviso de privacidad.", title: "Advertencia", txtButton: "Vover a Intentar",
                statusCode: 404,
                actionClick: async () => {
                    try {
                        await this.getInfo();
                    } catch (error) {

                    }
                }
            });
        }
    }

    render() {
        let { loading, content } = this.state;

        if (loading) {
            return (
                <div className="row">
                    <div className="col s12 center-align">
                        <Reload action={() => { }} />
                    </div>
                </div>
            )
        }
        return (
            <div className="row">
                <div className="col s12 center-align boldText mt-1">
                    {
                        content.titulo
                    }
                </div>
                <div className="col s11 offset-s1 align-justify mt-1 contentLegal">
                    {
                        content.contenido ?
                        decodeURIComponent(escape(atob(content.contenido)))
                            : "No se encontro la información solicitada."
                    }
                </div>
            </div>
        )
    }

}

export default connect(null, {
    okButton
})(AvisoPrivacidad)