
import React, { Component } from "react";
import logoMobile from '../assets/logoMobile.png';
import { ICONS } from '../assets/icons';
import { Link } from 'react-router-dom';
import { PATHS } from "../properties";
import { connect } from 'react-redux';
import { getData_menuExterior } from "../utils";
import {
    setModalInfo
} from '../redux/actions';
import M from 'materialize-css';

class NavNoSesion extends Component {

    state = {
        dataMenuExterior: false
    }

    componentDidMount() {

        this.getDateMenuExterior();
    }

    getDateMenuExterior = async () => {
        const dataMenuE = await getData_menuExterior();

        this.setState({ dataMenuExterior: dataMenuE });
    }

    initMapUbicacion() {
        try {
            var mymap = window.L.map('mapidNS').setView([19.4478279, -99.2095531], 15);
            window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '',
                maxZoom: 15,
                tileSize: 512,
                zoomOffset: -1
            }).addTo(mymap);

            window.L.marker([19.4478279, -99.2095531]).addTo(mymap);
        } catch (error) {

        }

    }

    render() {
        return (
            <React.Fragment>
                <ul id="sidenavNoSesion" className="sidenav">
                    <li className="center-align mt-8">
                        <img src={logoMobile} className="logoSide" alt="Casa de Bolsa Valmex" />
                    </li>

                    <li className="optSide sidenav-close" >
                        <a href="!#"
                            onClick={(event) => {
                                event.preventDefault();
                                // debugger
                                // let modalContacto = M.Modal.getInstance(document.getElementById('modalContacto'));

                                // if (modalContacto) {
                                //     modalContacto.open();
                                // } else {
                                //     M.Sidenav.init(document.getElementById('modalContacto'), {}).open();
                                // }

                                this.props.setModalInfo({
                                    title: "¡Atención!",
                                    text: "contacto",
                                    estilo: "modalInfoCall",
                                    toRender: () => {
                                        return (
                                            <div className='row'>
                                                <div className='col s12 center-align'>
                                                    <p className='ft-24'>Valmex Casa de Bolsa</p>
                                                    <p className='ft-16'>Ponemos a tu disposición nuestros números de contacto:</p>

                                                    {
                                                        this.state.dataMenuExterior && this.state.dataMenuExterior.menu_exterior.numeros_contacto ?

                                                            this.state.dataMenuExterior.menu_exterior.numeros_contacto.map((v, i) => {
                                                                return <p key={i}>Llamar <a href={`tel:+52${v}`} className="brandTextPrimary" style={{ textDecoration: 'underline' }}>{v}</a></p>
                                                            })
                                                            : <p> <a href="tel:+525552791433" className="brandTextPrimary" style={{ textDecoration: 'underline' }}>(+52) 55 5279 1433</a></p>
                                                    }

                                                    <p className="ft-12">
                                                        Si nos llamas de otro país distinto a México,
                                                        recuerda agregar 52 al número telefónico de Valmex
                                                    </p>

                                                </div>
                                                <div className='col s12 center-align mt-1'>
                                                    <button className='btn primaryBtn modal-close'>Entendido</button>
                                                </div>
                                            </div>
                                        )
                                    },
                                    NoBtn: true
                                })
                            }}
                        ><img src={ICONS.CALL_CENTER} className="customIcon left"></img> Contacto Call Center <i className="material-icons right">keyboard_arrow_right</i></a></li>

                    <li className="optSide sidenav-close" >
                        <a href="!#"
                            onClick={(event) => {
                                event.preventDefault();

                                // debugger;

                                // let modalUbicaion = M.Modal.getInstance(document.getElementById('modalUbicaion'));

                                // if (modalUbicaion) {
                                //     modalUbicaion.open();
                                // } else {
                                //     M.Sidenav.init(document.getElementById('modalUbicaion'), {}).open();
                                // }

                                this.props.setModalInfo({
                                    title: "¡Atención!",
                                    text: "ubicacion",
                                    estilo: "modalInfoCall",
                                    toRender: () => {
                                        return (
                                            <div className='row'>
                                                <div className='col s12 left-align'>
                                                    <p className='ft-16 boldText'>Valmex Casa de Bolsa</p>
                                                    <p className='ft-14 mb-0px'>Calzada Legaria No. 549 Torre 2 Piso 7</p>
                                                    <p className='ft-14 mb-0px mt-0px'>Colonia 10 de Abril Delegación Miguel</p>
                                                    <p className='ft-14 mb-0px mt-0px'>Hidalgo, C.P. 11250, Ciudad de México</p>
                                                    <p className="ft-14 mb-0px mt-0px">55 5279 1345</p>
                                                </div>
                                                <div className="col s12 center-align mt-1">
                                                    <div id="mapidNS" className="mapaClass"></div>
                                                </div>
                                                <div className='col s12 center-align mt-1'>
                                                    <button className='btn primaryBtn modal-close'>Entendido</button>
                                                </div>
                                            </div>
                                        )
                                    },
                                    NoBtn: true
                                })

                                setTimeout(()=>{
                                    this.initMapUbicacion();
                                },500)
                                
                            }}
                        ><img src={ICONS.UBICACION_NEW} className="customIcon left"></img>
                            <span>Ubicación de
                                Valmex Casa de Bolsa</span> <i className="material-icons right">keyboard_arrow_right</i></a></li>

                    <li className="optSide sidenav-close" >
                        <Link to={PATHS.PREGUNTAS_FRECUENTES}
                        ><img src={ICONS.PREGUNTAS_FRECUENTES} className="customIcon left"></img> Preguntas frecuentes <i className="material-icons right">keyboard_arrow_right</i></Link></li>


                </ul>

            </React.Fragment>
        )
    }
}
export default connect(null, {
    setModalInfo
})(NavNoSesion)