export const OK_BUTTON = "OK_BUTTON";
export const HIDE_OK_BUTTON = "HIDE_OK_BUTTON";
export const SHOW_TOKEN = "SHOW_TOKEN";
export const HIDE_TOKEN = "HIDE_TOKEN";
export const SET_MENU_PERFIL = "SET_MENU_PERFIL";
export const SET_MENU_PRINCIPAL = "SET_MENU_PRINCIPAL";
export const SET_HISTORY = "SET_HISTORY";
export const SET_COMPOSICION = "SET_COMPOSICION";
export const SET_COMPOSICION_SALDO_LIQUIDO = "SET_COMPOSICION_SALDO_LIQUIDO";
export const HIDE_COMPOSICION = "HIDE_COMPOSICION";
export const SET_OPERACION = "SET_OPERACION";
export const HIDE_OPERACION = "HIDE_OPERACION";
export const SET_FINGERPRINT = "SET_FINGERPRINT";
export const SET_COORDENADAS = "SET_COORDENADAS";
export const SET_IP = "SET_IP";
export const SET_OPTION_SELECTED_MENU = "SET_OPTION_SELECTED_MENU";
export const CARGA_INFO = "CARGA_INFO";
export const SERVICIOS_DISPONIBLES = "SERVICIOS_DISPONIBLES";
export const RECARGA_CARTERA = "RECARGA_CARTERA";
export const SET_TIPOS_SERVICIOS = "SET_TIPOS_SERVICIOS";
export const SET_SALDO_TOTAL = "SET_SALDO_TOTAL";
export const SET_SALDO_LIQUIDO = "SET_SALDO_LIQUIDO";
export const SET_RENDIMIENTOS = "SET_RENDIMIENTOS";
export const SET_POSICIONCARTERA = "SET_POSCIONCARTERA";
export const SET_TOKEN_TO_PASS = "SET_TOKEN_TO_PASS";
export const SET_SALDO_OPERACION = "SET_SALDO_OPERACION";
export const CONTRATO_A_REMOVER = "CONTRATO_A_REMOVER";
export const SET_AVATAR = "SET_AVATAR";
export const MODAL_INFO = "SET_MODALINFO";
export const MODAL_FCHNAC = "SHOW_MODALFCHNAC";

/*Acciones en servicios*/
export const SET_DATA_USER = "VALIDA_USUARIO";
export const VALIDA_TOKEN = "VALIDA_TOKEN";
export const BLOQUEO_USUARIO = "BLOQUEO_USUARIO";
export const SET_CONTRATOS = "CONTRATOS";
export const SET_CURRENT_CONTRATO = "CURRENT_CONTRATO";
export const SET_CHEQUERA = "SET_CHEQUERA";