import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideComposicion } from '../redux/actions';
import { nWithCommas } from '../utils';
import { ICONS } from '../assets/icons';
import M from 'materialize-css';
import { TXT_SALDO_LIQUIDO } from '../properties';

class Composicion extends Component {

    state = {

    }

    async componentDidUpdate(prevProps, prevState) {

        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});

    }

    componentDidMount() {
    }

    processInfoSaldoLiquido() {
        const { saldoLiquido } = this.props;

        let objReturn = {};

        for (const d of saldoLiquido) {
            let obj = {
                ValDivOrig: d.ImporteDvs,
                PrecioMdoMN: d.Divisa.trim() !== "MXP" ? d.TipoCambio : d.ImporteDvs,

            };
            if (d.Plazo === 0) {
                obj.DescRama = "Mismo día";
            } else if (d.Plazo === 1) {
                obj.DescRama = "48 hrs";
            } else if (d.Plazo === 2) {
                obj.DescRama = "24 hrs";
            }

            if (objReturn[d.Divisa]) {
                objReturn[d.Divisa].push(obj)
            } else {
                objReturn[d.Divisa] = [
                    obj
                ];
            }
        }

        return objReturn;
    }


    processInfo(composicion) {
        const { detalle } = composicion;


        let obj = {};

        for (const d of detalle) {
            if (d.detalle) {
                for (let dt of d.detalle) {
                    if (d.DescRama.trim() === "Mismo día") {
                        dt.DescRama = "Mismo día";
                    } else if (d.DescRama.trim() === "48 hrs") {
                        dt.DescRama = "48 hrs";
                    } else if (d.DescRama.trim() === "24 hrs") {
                        dt.DescRama = "24 hrs";
                    }

                    if (obj[dt.Emisora]) {
                        obj[dt.Emisora].push(dt)
                    } else {
                        obj[dt.Emisora] = [
                            dt
                        ];
                    }
                }
            }

        }

        return obj;
    }



    render() {

        const composicion = this.props.composicion ? this.props.composicion : false;
        const esSaldoLiquido = composicion ? composicion.DescRama.trim().toLowerCase() === "efectivo" : false;
        const esSaldoProyectado = composicion ? composicion.esSaldoProyectado : false;

        return (
            <div id="modal_composicion" className={`modal modal_composicion ${esSaldoLiquido ? 'saldo_liquido' : ""}`}>
                <div className="closeModalOk left-align">
                    <i className="material-icons cursorPointer" onClick={() => {
                        this.props.hideComposicion();
                    }}>chevron_left</i> <span className="titleModal">Composición</span>
                </div>
                <div className="modal-content contentModalComposicion">
                    {
                        !composicion ?
                            <div className="row">
                                <div className="col s12 center-align">
                                    <p>Seleccione un indice válido.</p>
                                </div>

                            </div>

                            :

                            <div className="row">
                                {
                                    composicion.DescRama.trim().toLowerCase() === "efectivo" ?

                                        <div className="col s12">
                                            <div className="row">
                                                <div className="col s12 txtBlackie ft-14">
                                                    No. de contrato: {this.props.currentContrato ? this.props.currentContrato.Contrato : ""}
                                                </div>
                                                <div className="col s6 left-align">
                                                    <p>{esSaldoProyectado ? "Saldo proyectado" : TXT_SALDO_LIQUIDO}

                                                        <i className="material-icons pAbsolute tooltipped cursorPointer"
                                                            data-position="bottom" data-tooltip="Incluye moneda nacional y otras divisas"
                                                        >info_outline</i></p>
                                                </div>
                                                {
                                                    !esSaldoProyectado ?
                                                        <div className="col s6 right-align boldText">
                                                            <p>{`$${nWithCommas(parseFloat(composicion.MontoMdoMN).toFixed(2).toString())}`}</p>
                                                        </div>
                                                        : null
                                                }


                                            </div>
                                        </div>
                                        :
                                        <div className="col s12">
                                            <p className="txtBlackie ft-14">No. de contrato: {this.props.currentContrato ? this.props.currentContrato.Contrato : ""}</p>
                                            <span><p className="ft-14">{composicion.DescRama}</p></span>
                                            <p className="boldText ft-16">
                                                {
                                                    composicion.DescRama.trim().toLowerCase() !== "efectivo"
                                                        ?
                                                        `$${nWithCommas(parseFloat(composicion.MontoMdoMN).toFixed(2).toString())}`
                                                        :
                                                        this.props.saldoTotal !== false ?
                                                            `$${nWithCommas(parseFloat(this.props.saldoTotal).toFixed(2).toString())}`
                                                            : ""
                                                }


                                            </p>
                                        </div>
                                }

                                {
                                    this.getComposicionDos()
                                }
                                {
                                    esSaldoLiquido ?
                                        esSaldoProyectado ?
                                            <div className="col s12 ft-12 left-align">
                                                {
                                                    esSaldoProyectado ?
                                                        "Incluye movimientos pendientes de aplicar."
                                                        :
                                                        " Considera unicamente movimientos de aplicados. Tipo de cambio FIX para valuación de USD a MXP.Para EURO y YEN considera tipo de cambio mismo día valuación PIP."
                                                }

                                            </div>
                                            :
                                            <React.Fragment>
                                                <div className="col s12 ft-12 left-align">
                                                    *Al tipo de cambio del cierre anterior.
                                                </div>
                                                <div className="col s12 ft-12 left-align">
                                                    *Incluye movimientos de efectivo pendientes de aplicar.
                                                </div>
                                            </React.Fragment>

                                        : null
                                }

                            </div>
                    }
                </div>
            </div>
        )
    }

    getComposicionDos = () => {
    
        if (!this.props.composicion) {
            return <div></div>;
        }

        let { detalle, esSaldoProyectado } = this.props.composicion;

        const esSaldoLiquido = this.props.composicion ? this.props.composicion.DescRama.trim().toLowerCase() === "efectivo" : false;


        if (esSaldoLiquido) {
            let objToShow = this.processInfo(this.props.composicion);

            if (esSaldoProyectado) {
                objToShow = this.processInfoSaldoLiquido();
            }

            const keys = Object.keys(objToShow);
            let suma = 0;

            return keys.map((j, k) => {

                let toolTipText = "";

                if (j === "MXP") {
                    toolTipText = "Importe presentado en moneda nacional";
                } else if (j === "USD") {
                    toolTipText = "Importe presentado en dólares de los E.U.A.";
                } else if (j === "EUR") {
                    toolTipText = "Importe presentado en Euros";
                } else if (j === "YEN") {
                    toolTipText = "Importe presentado en Yenes";
                }

                let tipoSaldo = "Saldo líquido";

                if (esSaldoProyectado) {
                    tipoSaldo = "Saldo proyectado";
                } else {
                    suma = objToShow[j].map(i => parseFloat(i.ValDivOrig)).reduce((a, b) => a + b, 0);
                }

                return (<div className="col s12" key={k}>
                    <div className="row bgSky SaldoLiquido white-text mb-10">
                        <div className="col s6">
                            <span>
                                {tipoSaldo} en {j} <i className="material-icons tooltipped cursorPointer pAbsolute"
                                    data-position="bottom" data-tooltip={toolTipText}
                                >info_outline</i></span>
                        </div>
                        {
                            !esSaldoProyectado ?
                                <div className="col s6 right-align">
                                    {j !== 'MXP' ? "*" : ""}${`${nWithCommas(parseFloat(suma).toFixed(2).toString())}`}
                                </div> :
                                null
                        }

                    </div>
                    {
                        objToShow[j].map((i, index) => {
                            return <div className="row" key={index}>
                                <div className="col s6 left-align">
                                    {i.DescRama} {i.DescRama === "Mismo día" && !esSaldoProyectado ? <i className="material-icons tooltipped cursorPointer pAbsolute"
                                        data-position="bottom" data-tooltip={`${tipoSaldo} mismo día
                                    disponible para retiro antes de la 1:30pm`}
                                    >info_outline</i> : null}
                                </div>
                                {
                                    j !== 'MXP' ?
                                        <React.Fragment>
                                            <div className="col s3 right-align">
                                                ${`${nWithCommas(parseFloat(i.PrecioMdoMN).toFixed(6).toString())}`}
                                            </div>
                                            <div className="col s3 right-align">
                                                ${`${nWithCommas(parseFloat(i.ValDivOrig).toFixed(2).toString())}`}
                                            </div>
                                        </React.Fragment>

                                        :
                                        <div className="col s6 right-align">
                                            {j !== 'MXP' ? "*" : ""}${`${nWithCommas(parseFloat(i.ValDivOrig).toFixed(2).toString())}`}
                                        </div>
                                }


                            </div>
                        })

                    }
                </div>)
            });

        }

        return detalle.map((j, k) => {
            let dt = j.detalle;
            if (j.DescRama.trim().toLowerCase() === 'mismo día') {
                return dt.map((v, i) => {
                    return (
                        <div className="col s12" key={i}>
                            <div className="row bgSky white-text mb-10">
                                <div className="col s6">
                                    <img src={ICONS.WHITEMONEDA} alt="divisa" /> {v.Emisora ? <span className="emiComp">{v.Emisora.trim()}
                                        <i className="material-icons right tooltipped cursorPointer"
                                            data-position="bottom" data-tooltip="Saldo líquido mismo día
                                    disponible para retiro antes de la 1:30pm"
                                        >info_outline</i></span> : ""}

                                </div>
                                <div className="col s6 right-align">
                                    {v.Emisora.trim() !== 'MXP' ? "*" : ""}${`${nWithCommas(parseFloat(v.ValDivOrig).toFixed(2).toString())}`}
                                </div>
                            </div>

                            {
                                v.Emisora.trim() !== 'MXP'
                                    ?
                                    <div className="row">
                                        <div className="col s12 right-align">
                                            (MXN ${`${nWithCommas(parseFloat(v.PrecioMdoMN).toFixed(2).toString())}`}) ${`${nWithCommas(parseFloat(v.MontoMdoMN).toFixed(2).toString())}`}
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                v.detalle && v.detalle.length ?
                                    <div>Detalle</div>
                                    : null
                            }
                        </div>
                    )
                });
            }
            if (j.DescRama.trim().toLowerCase() === '48 hrs' || j.DescRama.trim().toLowerCase() === '24 hrs') {
                return dt.map((v, i) => {
                    return (
                        <div className="row optSide" key={i}>
                            <div className="col s6">
                                {j.DescRama.trim().toLowerCase()}
                            </div>
                            <div className="col s6 right-align">
                                ${`${nWithCommas(parseFloat(v.MontoMdoMN).toFixed(2).toString())}`}
                            </div>
                        </div>
                    )
                });
            }

            // if (j.DescRama.trim().toLowerCase() === 'fondos de inversión de deuda' || j.DescRama.trim().toLowerCase() === 'fondos de inversión de renta variable'
            //     || j.DescRama.trim().toLowerCase() === 'renta variable' || j.DescRama.trim().toLowerCase() === 'deuda tasa nominal' 
            // ) 
            if(j.DescRama.trim().toLowerCase() !== 'mismo día' && j.DescRama.trim().toLowerCase() !== '48 hrs' && j.DescRama.trim().toLowerCase() !== '24 hrs')
            {
                return dt.map((v, i) => {
                    return (
                        <div className="col s12" key={i}>
                            <div className="row" >
                                {
                                    i == 0 ?
                                        <div className="col s12 left-align  bgSky white-text panding-5">
                                            {j.DescRama.trim()}
                                        </div>
                                        : null
                                }

                                <div className="col s12 left-align  bgSub subText panding-5">
                                    {v.DescRama.trim()}
                                </div>
                                {
                                    v.detalle && v.detalle.length ?
                                        v.detalle.map((j, k) => {
                                            return (
                                                <div className="col s12">
                                                    <div className="row mt-1">
                                                        <div className="col s6 left-align">
                                                            {j.Emisora} - {j.Serie}
                                                        </div>
                                                        <div className="col s6 right-align">
                                                            ${`${nWithCommas(parseFloat(j.MontoMdoMN).toFixed(2).toString())}`}
                                                        </div>

                                                        <div className="col s6 left-align txtBlackie ft-12">
                                                            {j.Titulos} títulos
                                                        </div>

                                                        <div className="col s6 right-align txtBlackie ft-12">
                                                            Precio valuación ${`${nWithCommas(parseFloat(j.PrecioMdoMN).toFixed(6).toString())}`}
                                                        </div>
                                                    </div>
                                                    <div className="divider"></div>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </div>

                    )
                });
            }

        });

    }

    getComposicion = () => {

        if (!this.props.composicion) {
            return <div></div>;
        }

        let { DescRama, detalle } = this.props.composicion;

        if (DescRama.trim().toLowerCase() === 'mismo día') {
            return detalle.map((v, i) => {
                // console.log(v);
                return (
                    <div className="col s12" key={i}>
                        <div className="row bgSky white-text mb-10">
                            <div className="col s6">
                                <img src={ICONS.WHITEMONEDA} alt="divisa" /> {v.Emisora ? <span className="emiComp">{v.Emisora.trim()}</span> : ""}
                            </div>
                            <div className="col s6 right-align">
                                {v.Emisora.trim() !== 'MXP' ? "*" : ""}${`${nWithCommas(parseFloat(v.ValDivOrig).toFixed(2).toString())}`}
                            </div>
                        </div>

                        {
                            v.Emisora.trim() !== 'MXP'
                                ?
                                <div className="row">
                                    <div className="col s12 right-align">
                                        (MXN ${`${nWithCommas(parseFloat(v.PrecioMdoMN).toFixed(2).toString())}`}) ${`${nWithCommas(parseFloat(v.MontoMdoMN).toFixed(2).toString())}`}
                                    </div>
                                </div>
                                : null
                        }
                        {
                            v.detalle && v.detalle.length ?
                                <div>Detalle</div>
                                : null
                        }
                        {/* <div className="row optSide">
                            <div className="col s6">
                                24h
                                                        </div>
                            <div className="col s6 right-align">
                                $3,200,00.00
                                                        </div>
                            <div className="col s6">
                                48h
                                                        </div>
                            <div className="col s6 right-align">
                                $3,800,00.00
                                                        </div>
                            <div className="divider"></div>
                        </div> */}
                    </div>
                )
            });
        }

        if (DescRama.trim().toLowerCase() === 'fondos de inversión de deuda' || DescRama.trim().toLowerCase() === 'fondos de inversión de renta variable') {
            return detalle.map((v, i) => {
                return (
                    <div className="col s12" key={i}>
                        <div className="row" >
                            <div className="col s12 left-align">
                                {v.DescRama.trim()}
                                <div className="divider mt-1"></div>
                            </div>
                            {
                                v.detalle && v.detalle.length ?
                                    v.detalle.map((j, k) => {
                                        return (
                                            <div className="col s12">
                                                <div className="row mt-1">
                                                    <div className="col s6 left-align">
                                                        {j.Emisora} - {j.Serie}
                                                    </div>
                                                    <div className="col s6 right-align">
                                                        ${`${nWithCommas(parseFloat(j.MontoMdoMN).toFixed(2).toString())}`}
                                                    </div>

                                                    <div className="col s6 left-align txtBlackie ft-12">
                                                        {j.Titulos} títulos
                                                    </div>

                                                    <div className="col s6 right-align txtBlackie ft-12">
                                                        Precio valuación ${`${nWithCommas(parseFloat(j.PrecioMdoMN).toFixed(2).toString())}`}
                                                    </div>
                                                </div>
                                                <div className="divider"></div>
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>

                )
            });
        }
    }

}

const mapStateToProps = state => {
    let { composicion } = false;
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, saldoTotal, saldoLiquido
    } = false;

    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false



    }

    if (state.commonsActions) {
        composicion = state.commonsActions.composicion || false;
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        saldoLiquido = state.commonsActions.saldoLiquido || false;
    }

    return {
        composicion, dataUser, currentContrato, menuPerfil, contratosUser, chequera,
        history, saldoTotal, saldoLiquido
    };
};


export default connect(mapStateToProps, { hideComposicion })(Composicion)