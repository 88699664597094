import React, { Component } from 'react';
import firebase from 'firebase';
import { okButton, setOperacion } from '../redux/actions';
import { connect } from 'react-redux';
import Reload from './reload';

class TerminosCondiciones extends Component {

    state = {
        content: false,
        loading: true
    }

    async componentDidMount() {
        try {
            await this.getTerminos();
        } catch (error) {

        }
    }


    getTerminos = async () => {
        this.setState({ loading: true });
        try {

            let info = await firebase.firestore().collection('configuraciones').doc('legales').get();

            if (info.exists && info.data().terminos) {
                let data = info.data();
                this.setState({ content: data.terminos, loading: false });
            } else {
                this.props.okButton({
                    message: "No se encontro la información solicitada.", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                this.setState({ loading: false });
            }

        } catch (error) {
            this.setState({ loading: false });
            this.props.okButton({
                message: "No se logro obtener los terminos y condiciones intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                statusCode: 404,
                actionClick: async () => {
                    try {
                        await this.getTerminos();
                    } catch (error) {

                    }
                }
            });
        }
    }

    render() {
        let { loading, content } = this.state;

        if (loading) {
            return (
                <div className="row">
                    <div className="col s12 center-align">
                        <Reload action={() => { }} />
                    </div>
                </div>
            )
        }
        return (
            <div className="row">
                <div className="col s12 center-align boldText mt-1">
                    {
                        content.titulo
                    }
                </div>
                <div className="col s11 offset-s1 align-justify mt-1 contentLegal">
                    {
                        content.contenido ?
                            decodeURIComponent(escape(atob(content.contenido)))
                            : "No se encontro la información solicitada."
                    }
                </div>
                {
                    !this.props.data || !this.props.data.hasOwnProperty('esRF04') ?

                        <div className="col s12 center-align mt-2">
                            <button className="btn primaryBtn_oc"
                                onClick={() => {
                                    this.props.setOperacion({
                                        title: `Aviso de privacidad`,
                                        opt: "aviso_privacidad"
                                    })
                                }}
                            >Ver Aviso de Privacidad</button>
                        </div>
                        : null


                }

                <div className="col s12 mt-1">

                </div>
            </div>
        )
    }
}

export default connect(null, {
    okButton, setOperacion
})(TerminosCondiciones)