import React, { Component } from 'react';
import { makeCall, toCamelCase, nWithCommas, encryptValues } from '../../utils';
import { SERVICES } from '../../properties';
import { okButton } from '../../redux/actions';
import { connect } from 'react-redux'
import moment from 'moment';
import Reload from '../reload';
import M from 'materialize-css';
import { ICONS } from '../../assets/icons';
import firebase from 'firebase';
import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
registerLocale('es', es);

class Movimientos extends Component {

    state = {
        clasificaciones: [],
        emisoras: [],
        ms: false,
        loading: false,
        movimientos: false,
        movimientosResp: false,
        fFinal: this.props.startDate ? this.props.startDate : moment().format('DD/MM/YYYY'),
        fInicial: this.props.startDate ? this.props.startDate : moment().format('DD/MM/YYYY'),
        ramasFiltro: [],
        divisaSelected: "",
        emisoraSelected: "",
        startDate: this.props.startDate ? this.props.startDate : moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        startDateTwo: new Date(),
        endDateTwo: new Date()

    }

    componentDidUpdate(prevProps, prevState) {

        M.FormSelect.init(document.querySelectorAll('select'), {});
    }

    componentDidMount = async () => {

        try {
            if (this.props.dataUser && this.props.dataUser.FechaSistema) {

                let fInicial = this.props.startDate ? this.props.startDate : moment(this.props.dataUser.FechaSistema).format('DD/MM/YYYY');
                let fFinal = this.props.startDate ? this.props.startDate : fInicial;
                let startDate = this.props.startDate ? this.props.startDate : moment(this.props.dataUser.FechaSistema).format('DD/MM/YYYY');
                let endDate = this.props.startDate ? this.props.startDate : moment(this.props.dataUser.FechaSistema).format('DD/MM/YYYY');
                this.setState({ fInicial, fFinal, startDate, endDate }, () => {
                    this.getMovimientos();
                });


            } else {
                this.getMovimientos();
            }


            //await this.getClasificaciones();
        } catch (error) {
        }
    }

    aplicarFiltro = (toToggle) => {
        let { divisaSelected, movimientosResp } = this.state;
        let movimientos = {};

        if (divisaSelected && divisaSelected.trim() !== "") {
            let dataS = this.ramasFiltro(movimientosResp[divisaSelected]);

            movimientos = {
                [divisaSelected]: dataS
            }

            this.setState({ movimientos, ms: false }, () => {
                if (!toToggle) {
                    this.props.toggleFiltro()
                }

            });
        } else {
            let keys = Object.keys(movimientosResp);

            for (let k of keys) {
                let dataI = this.ramasFiltro(movimientosResp[k]);

                movimientos[k] = dataI;
            }

            this.setState({ movimientos, ms: false }, () => {
                if (!toToggle) {
                    this.props.toggleFiltro()
                }
            });
        }

    }

    ramasFiltro = (dataS) => {
        let { emisoraSelected, ramasFiltro } = this.state;

        if (emisoraSelected.trim() !== "" || ramasFiltro.length) {
            let info = dataS.filter((v, i) => {
                if (emisoraSelected.trim() !== "") {
                    if (v.Emisora === emisoraSelected) {
                        return v;
                    }
                }

                if (ramasFiltro.length) {
                    if (ramasFiltro.indexOf(v.Rama) !== -1) {
                        return v;
                    }
                }
            });
            return info;
        } else {
            return dataS;
        }




    }

    formatDate(date) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        if (day < 10) {
            day = `0${day}`;
        }

        if (month < 10) {
            return `${day}/0${month}/${year}`;
        } else {
            return `${day}/${month}/${year}`;
        }
    }

    getMovimientos = async () => {
        this.setState({ loading: true });
        try {

            let bodyClasificaciones = SERVICES.CLASIFICACION_MOVIMIENTOS.body;

            bodyClasificaciones.IdUsuario = encryptValues(this.props.dataUser.IdUsuario);
            bodyClasificaciones.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");

            bodyClasificaciones.FInicial = this.state.fInicial;
            bodyClasificaciones.FFinal = this.state.fFinal;

            let resultMovimientos = await makeCall(SERVICES.CLASIFICACION_MOVIMIENTOS, bodyClasificaciones);

            if (resultMovimientos && resultMovimientos.response && resultMovimientos.response.Exito === 200) {

                if (resultMovimientos.response.TTCLASIFICACIONES
                    &&
                    resultMovimientos.response.TTCLASIFICACIONES['TT-CLASIFICACIONES']
                ) {
                    this.setState({
                        clasificaciones: resultMovimientos.response.TTCLASIFICACIONES['TT-CLASIFICACIONES']
                    });
                } else {
                    if (resultMovimientos.response && resultMovimientos.response.Exito !== 200) {
                        this.props.okButton({
                            message: resultMovimientos.response.MensajeError, title: "Advertencia", txtButton: "Entendido",
                            statusCode: resultMovimientos.response.Exito
                        });
                        // M.toast({ html: resultClasificaciones.response.MensajeError, classes: 'red darken-3' });
                    } else {
                        this.props.okButton({
                            message: "No se lograrón obtener las clasificaciones", title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                        // M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' });
                    }

                }
            } else {
                this.props.okButton({
                    message: "No se lograrón obtener las clasificaciones", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                // M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' })
            }

            if (resultMovimientos && resultMovimientos.response) {
                if (resultMovimientos.response.wfMovimientos
                    &&
                    resultMovimientos.response.wfMovimientos['wfMovimientos']
                ) {

                    let mv = this.orderMovimientosIdsvoper(resultMovimientos.response.wfMovimientos['wfMovimientos']);
                    this.getEmisoras(resultMovimientos.response.wfMovimientos['wfMovimientos']);
                    this.setState({
                        movimientos: mv, movimientosResp: mv
                    }, () => {

                        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
                        M.FormSelect.init(document.querySelectorAll('select'), {});
                    });
                } else {
                    this.setState({
                        movimientos: {}
                    });

                }
            } else {
                this.setState({
                    movimientos: {}
                });
                //M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' })
            }

        } catch (error) {
            this.setState({
                movimientos: {}
            });
            this.props.okButton({
                message: "No se lograrón obtener los movimientos.", title: "Advertencia", txtButton: "Entendido",
                statusCode: 404
            });
            // M.toast({ html: "No se lograrón obtener los movimientos", classes: 'red darken-3' })
        }
        this.setState({ loading: false });
    }

    getClasificaciones = async () => {
        try {

            let bodyClasificaciones = SERVICES.CLASIFICACION_MOVIMIENTOS.body;

            bodyClasificaciones.IdUsuario = encryptValues(this.props.dataUser.IdUsuario);
            bodyClasificaciones.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyClasificaciones.FInicial = this.state.fInicial;
            bodyClasificaciones.FFinal = this.state.fFinal;

            let resultClasificaciones = await makeCall(SERVICES.CLASIFICACION_MOVIMIENTOS, bodyClasificaciones);

            if (resultClasificaciones && resultClasificaciones.response && resultClasificaciones.response.Exito === 200) {

                if (resultClasificaciones.response.TTCLASIFICACIONES
                    &&
                    resultClasificaciones.response.TTCLASIFICACIONES['TT-CLASIFICACIONES']
                ) {
                    this.setState({
                        clasificaciones: resultClasificaciones.response.TTCLASIFICACIONES['TT-CLASIFICACIONES']
                    });
                } else {
                    if (resultClasificaciones.response && resultClasificaciones.response.Exito !== 200) {
                        this.props.okButton({
                            message: resultClasificaciones.response.MensajeError, title: "Advertencia", txtButton: "Entendido",
                            statusCode: resultClasificaciones.response.Exito
                        });
                        // M.toast({ html: resultClasificaciones.response.MensajeError, classes: 'red darken-3' });
                    } else {
                        this.props.okButton({
                            message: "No se lograrón obtener las clasificaciones", title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                        // M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' });
                    }

                }
            } else {
                this.props.okButton({
                    message: "No se lograrón obtener las clasificaciones", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                // M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' })
            }
        } catch (error) {
            // console.log(error);
            this.props.okButton({
                message: "No se lograrón obtener las clasificaciones", title: "Advertencia", txtButton: "Entendido",
                statusCode: 404
            });
            // M.toast({ html: "No se lograrón obtener las clasificaciones", classes: 'red darken-3' })
        }
    }

    orderMovimientosIdsvoper = (movimientos) => {
        let objToReturn = {};
        if (movimientos && movimientos.length) {
            for (let m of movimientos) {
                if (objToReturn[m.Idsvoper]) {
                    objToReturn[m.Idsvoper].push(m)
                } else {
                    objToReturn[m.Idsvoper] = [
                        m
                    ];
                }
            }
        }
        return objToReturn;
    }

    getEmisoras = (movimientos) => {
        let objtToSet = {};
        if (movimientos && movimientos.length) {
            for (let m of movimientos) {
                if (m.Emisora.trim() !== "") {
                    if (!objtToSet[m.Emisora]) {
                        objtToSet[m.Emisora] = {}
                    }
                }
            }
        }

        this.setState({ emisoras: Object.keys(objtToSet) });
    }

    render() {
        let { clasificaciones, movimientos, movimientosResp, ms, emisoras, ramasFiltro,
            startDate, endDate, loading
        } = this.state;

        let esCompraVenta = ms ? ms.Concepto.toUpperCase().includes('COMPRA')
            || ms.Concepto.toUpperCase().includes('VENTA') : false;

        // if (!clasificaciones.length) {
        //     return (
        //         <div className="row">
        //             <div className="col s12 center-align">
        //                 <Reload action={() => { }} />
        //             </div>
        //         </div>
        //     )
        // }

        if (this.props.filtro && clasificaciones.length) {
            return (<div className="row">
                <div className="col s6 offset-s6">
                    <div className="row">
                        <div className="col s12 borderBFiltro pt-1r pb-1r ft-14">
                            Filtros <span className="floatRight"><i className="material-icons cursorPointer"
                                onClick={() => {
                                    this.props.toggleFiltro();
                                }}
                            >clear</i></span>
                        </div>
                        {
                            clasificaciones.map((v, i) => {
                                return (
                                    <div className="col s12 borderBFiltro" key={i}>
                                        <p>
                                            <label>
                                                <input type="checkbox" className="filled-in"
                                                    value={v.Rama}
                                                    onClick={() => {
                                                        firebase.analytics().logEvent('Mov_secciones');
                                                    }}
                                                    onChange={(event) => {

                                                        let irF = ramasFiltro.indexOf(v.Rama);
                                                        if (event.target.checked) {
                                                            if (irF === -1) {
                                                                ramasFiltro.push(v.Rama);
                                                            }
                                                        } else {
                                                            if (irF !== -1) {
                                                                ramasFiltro.splice(irF, 1);
                                                            }
                                                        }

                                                        this.setState({ ramasFiltro });
                                                    }}
                                                    checked={ramasFiltro.indexOf(v.Rama) !== -1 ? true : false}
                                                />
                                                <span className="ft-14">{toCamelCase(v.Descripcion.toLowerCase())}</span>
                                            </label>
                                        </p>
                                    </div>
                                )
                            })
                        }

                        {
                            emisoras.length ?
                                <div className="col s12 borderLeftFiltro">
                                    <label>Emisora</label>
                                    <select className="browser-default" onChange={(event) => {
                                        this.setState({ emisoraSelected: event.target.value });
                                    }} value={this.state.emisoraSelected}

                                        onClick={() => {
                                            firebase.analytics().logEvent('mov_emisoras');
                                        }}
                                    >
                                        <option value="">Todas las emisoras</option>
                                        {
                                            emisoras.map((v, i) => {
                                                return (
                                                    <option value={v} key={i}>{v}</option>
                                                )
                                            })
                                        }

                                    </select>

                                </div>
                                : null
                        }

                        {
                            Object.keys(movimientosResp).length ?
                                <div className="col s12 borderLeftFiltro">
                                    <div className="row">

                                        <div className=" col s12">
                                            <label>Divisas</label>
                                            <select className="browser-default" onChange={(event) => {
                                                this.setState({ divisaSelected: event.target.value });
                                            }} value={this.state.divisaSelected}
                                            >
                                                <option value="" >Todas</option>
                                                {
                                                    Object.keys(movimientosResp).map((v, i) => {
                                                        return (
                                                            <option value={v} key={i}>{v}</option>
                                                        )
                                                    })
                                                }

                                            </select>

                                        </div>
                                    </div>
                                </div>
                                : null
                        }


                        <div className="col s12 center-align borderBFiltro">
                            <button className="waves-effect btn-flat flatPrimary mt-1 mb-1"
                                onClick={() => {
                                    this.aplicarFiltro()
                                }}
                            >Aplicar filtros</button>
                            <button className="waves-effect btn mt-1 mb-1 primaryBtn"
                                onClick={() => {
                                    this.setState({
                                        ramasFiltro: [], divisaSelected: "",
                                        emisoraSelected: ""
                                    }, () => {
                                        this.aplicarFiltro(true)
                                    })
                                }}
                            >Limpiar filtros</button>
                        </div>
                    </div>
                </div>
            </div>)
        }


        return (
            <div className="row">
                <div className="col s6">
                    <div className="row mb-0px">
                        <div className='col s12 left-align' style={{paddingLeft:"0px"}}>
                            {
                                this.props.currentContrato ? 
                                <span className='titleNot'>No. de contrato: {this.props.currentContrato.Contrato} </span>
                                : null
                            }
                              


                        </div>
                        <div className="col s12 center-align">
                            <DatePicker
                                id="datePickerMov"
                                selected={this.state.startDateTwo}
                                onChange={(dates) => {
                                    const [start, end] = dates;

                                    this.setState({ startDateTwo: start, endDateTwo: end }, () => {

                                        if (start && end) {
                                           
                                            firebase.analytics().logEvent('mov_periodo');
                                            let fInicial = this.formatDate(start);
                                            let fFinal = this.formatDate(end);

                                            this.setState({
                                                fInicial, fFinal, ramasFiltro: [],
                                                divisaSelected: "",
                                                ms: false,
                                                emisoraSelected: ""
                                            }, async () => {
                                                await this.getMovimientos();
                                            })
                                        }
                                    });


                                }}
                                startDate={this.state.startDateTwo}
                                endDate={this.state.endDateTwo}
                                selectsRange
                                locale='es'
                                monthsShown={2}
                            />
                            <i className="material-icons right cursorPointer" 
                            
                            style={{
                                position: 'absolute',
                                marginTop: '1rem',
                                marginLeft: '0.5rem'
                            }}
                            onClick={()=>{
                                document.getElementById('datePickerMov').click();
                            }}
                            >insert_invitation</i>
                        </div>
                    </div>
                    <div className="row contentLisMov">

                        {
                            loading ?
                                <div className="col s12 center-align mt-1">
                                    <Reload action={() => { }} />
                                </div>
                                : null
                        }
                        {
                            !loading ?
                                Object.keys(movimientos).map((mov, j) => {
                                    let dataM = movimientos[mov];
                                    return (
                                        <React.Fragment key={j}>
                                            <div className="col s12 bgSky white-text">
                                                <img src={ICONS.WHITEMONEDA} alt={mov} className="floatLeft" />  {mov}
                                            </div>
                                            {
                                                dataM.map((v, i) => {
                                                    return (
                                                        <div className="col s12">
                                                            <div className="row contentMov cursorPointer"
                                                                onClick={() => {
                                                                    
                                                                    this.setState({ ms: v });
                                                                }}
                                                            >
                                                                <div className="col s6 ft-12">
                                                                    <p>{moment(v.foper, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                                                    <p className="truncate tooltipped"
                                                                        data-position="bottom" data-tooltip={v.Concepto ? toCamelCase(v.Concepto.toLowerCase()) : ''}
                                                                    >{v.Concepto ? toCamelCase(v.Concepto.toLowerCase()) : ''}</p>
                                                                </div>
                                                                <div className="col s4 ft-12 right-align paddingRightCero" >
                                                                    <p> ${nWithCommas(parseFloat(v.Cargo ? v.Cargo : v.Abono).toFixed(2))}</p>
                                                                    <p>{v.Origen}
                                                                        {(v.Origen && v.Emisora)
                                                                            &&
                                                                            (v.Origen.trim() !== "" && v.Emisora.trim() !== "") ? ' - ' : ''}
                                                                        {v.Emisora} </p>
                                                                </div>
                                                                <div className="col s1 left-align paddingLeftCero" >
                                                                    {
                                                                        v.Cargo ?
                                                                            <img src={ICONS.CARGO} alt="CARGO" className="mt-m5" />
                                                                            : <img src={ICONS.ABONO} alt="ABONO" className="mt-m5" />
                                                                    }
                                                                </div>
                                                                <div className="col s1 left-align">
                                                                    <i className="material-icons mt-m1_5">chevron_right</i>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </React.Fragment>
                                    )
                                }) : null
                        }

                    </div>

                </div>
                <div className="col s6">
                    {
                        ms ?
                            <div className="row mt-1">
                                <div className="col s12 mt-1 pl-20" >
                                    <div className="row containerDetalleOpera">
                                        <div className='col s12 navyBackground white-text ft-14' 
                                            style={{
                                                borderTopLeftRadius:"6px",
                                                borderTopRightRadius:"6px"
                                            }}
                                        >
                                            <div className='row'>
                                            <div className='col s4 center-align'>
                                            <img src={ICONS.LOGO_CIRCULO} alt="LOGO" style={{marginTop:"2rem"}}/>
                                            </div>
                                            <div className='col s8'>
                                                <div style={{marginTop:"3rem"}}>
                                                <span>{toCamelCase(ms.Concepto.toUpperCase())}</span>
                                                <br/>
                                                <span> ${nWithCommas(parseFloat(ms.Cargo ? ms.Cargo : ms.Abono).toFixed(2))}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/* <div className="col s12 navyBackground white-text ft-14" 
                                                style={{ borderTopLeftRadius: '8px',
                                                 borderTopRightRadius: '8px',
                                                 height: "28px",
                                                 paddingTop: "4px" }}>
                                            <span>{toCamelCase(ms.Concepto.toUpperCase())}</span>
                                            <span className="floatRight"> ${nWithCommas(parseFloat(ms.Cargo ? ms.Cargo : ms.Abono).toFixed(2))}</span>
                                        </div> */}

                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Contrato</p>
                                            <p className="noMTop">{ms.Contrato || ""}</p>
                                        </div>
                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Folio</p>
                                            <p className="noMTop">{ms.Iorden || " "}</p>
                                        </div>

                                        <div className="col s6 left-align bgGray">
                                            <p className="noMBottom ft-10">Emisora</p>
                                            <p className={`noMTop ${ms.Emisora && ms.Emisora.trim().length ? '' : 'txtGray'}`}>
                                                {ms.Emisora && ms.Emisora.trim().length ? ms.Emisora : "_"}</p>
                                        </div>
                                        <div className="col s6 left-align bgGray">
                                            <p className="noMBottom ft-10">Serie</p>
                                            <p className={`noMTop ${ms.Serie && ms.Serie.trim().length ? '' : 'txtGray'}`}>
                                                {ms.Serie && ms.Serie.trim().length ? ms.Serie : "_"}</p>
                                        </div>

                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Títulos</p>
                                            <p className={`noMTop ${ms.Titulos ? '' : 'txtGray'}`}>
                                                {ms.Titulos ? nWithCommas(ms.Titulos) : '_'}</p>
                                        </div>
                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Precio</p>
                                            <p className={`noMTop ${ms.Precio || esCompraVenta ? '' : 'txtGray'}`}>

                                                {

                                                    esCompraVenta ? `$${nWithCommas(parseFloat(ms.Precio).toFixed(6))}`

                                                        : ms.Precio ? `$${nWithCommas(parseFloat(ms.Precio).toFixed(6))}`
                                                            : '_'
                                                }
                                            </p>
                                        </div>

                                        <div className="col s6 bgGray">
                                            <p className="noMBottom ft-10">Plazo días</p>
                                            <p className={`noMTop ${ms.Plazo ? '' : 'txtGray'}`}>
                                                {ms.Plazo ? nWithCommas(ms.Plazo) : '_'}</p>
                                        </div>

                                        <div className="col s6 bgGray">
                                            <p className="noMBottom ft-10">Moneda</p>
                                            <p className={`noMTop ${ms.Idsvoper ? '' : 'txtGray'}`}>
                                                {ms.Idsvoper || " "}</p>
                                        </div>

                                        <div className="col s6 left-align ">
                                            <p className="noMBottom ft-10">Tasa</p>
                                            <p className={`noMTop ${ms.Tasa ? '' : 'txtGray'}`}>
                                                {ms.Tasa !== 0 ? parseFloat(ms.Tasa).toFixed(6) : '_'}</p>
                                        </div>

                                        <div className="col s6 left-align ">
                                            <p className="noMBottom ft-10">Premio</p>
                                            <p className={`noMTop ${ms.Devengo ? '' : 'txtGray'}`}>
                                                {ms.Tasa !== 0 ? `$${nWithCommas(parseFloat(ms.Devengo).toFixed(2))}` : '_'}</p>
                                        </div>

                                        <div className="col s6 left-align bgGray ">
                                            <p className="noMBottom ft-10">Comision</p>
                                            <p className={`noMTop ${ms.Comision !== 0 ? '' : 'txtGray'}`}>{
                                                ms.Comision !== 0 ? `$${nWithCommas(parseFloat(ms.Comision).toFixed(2))}`
                                                    : '_'
                                            }</p>
                                        </div>

                                        <div className="col s6 left-align bgGray ">
                                            <p className="noMBottom ft-10">ISR/IVA</p>
                                            <p className={`noMTop ${ms.Impuesto !== 0 ? '' : 'txtGray'}`}>{
                                                ms.Impuesto !== 0 ? `$${nWithCommas(parseFloat(ms.Impuesto).toFixed(2))}`
                                                    : '_'
                                            }</p>
                                        </div>


                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Fecha operación</p>
                                            <p className="noMTop">{moment(ms.foper, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                        </div>
                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Fecha Liquidación</p>
                                            <p className="noMTop">{moment(ms.Fliq, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                        </div>

                                        <div className="col s12 left-align bgGray">
                                            <p className="noMBottom ft-10">Origen</p>
                                            <p className="noMTop">{ms.Origen}</p>
                                        </div>


                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Fecha</p>
                                            <p className="noMTop">{moment(ms.foper, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
                                        </div>
                                        <div className="col s6 left-align">
                                            <p className="noMBottom ft-10">Hora</p>
                                            <p className="noMTop">{ms.Hora}</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col s12 ft-12">
                                    Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};

export default connect(mapStateToProps, { okButton })(Movimientos);