import {
    SET_DATA_USER,
    SET_CONTRATOS,
    SET_CURRENT_CONTRATO,
    SET_CHEQUERA
} from './actionTypes';


export const setDataUser = dataUser => ({
    type: SET_DATA_USER,
    payload:dataUser
});

export const setContratos = contratosUser => ({
    type: SET_CONTRATOS,
    payload:contratosUser
})

export const setCurrentContrato = currentContrato => ({
    type: SET_CURRENT_CONTRATO,
    payload:currentContrato
})

export const setChequera = chequera => ({
    type: SET_CHEQUERA,
    payload: chequera
})