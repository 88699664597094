import React, { Component } from 'react';
import { connect } from 'react-redux';
import { numberWithCommas, makeCall, encryptValues } from '../../utils';
import { TIPOS_CHEQUERAS, SERVICES } from '../../properties';
import { setChequera } from '../../redux/actionsServices';
import { okButton } from '../../redux/actions';
import Reload from '../reload';
import firebase from 'firebase';


class Chequera extends Component {

    state = {
        permiteTransaccion: false,
        rango: this.props.data.TipoCta !== TIPOS_CHEQUERAS.INFORMATIVA ? parseInt(this.props.data.ImpMax) : 0,
        esInformativa: false,
        loading: false
    }

    onChangeValue = (event) => {
        const esConsulta = parseInt(this.props.currentContrato.Consulta);

        if (!esConsulta) {
            let { id } = event.target;
            let target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            if (id === 'rango') {
                firebase.analytics().logEvent('Perfil_limite_retiro');
            }
            this.setState({ [id]: value });
        }

    }

    componentDidMount = () => {

        if (this.props && this.props.data) {
            if (this.props.data.TipoCta !== TIPOS_CHEQUERAS.INFORMATIVA) {
   
                this.setState({
                    permiteTransaccion: this.props.data.Opera === "1" ? true : false,
                    rango: parseInt(this.props.data.ImpMax)
                })
            }
        }
    }

    getTipo() {
        if (this.props.data) {
            if (this.props.data.TipoCta === TIPOS_CHEQUERAS.DEPOSITO) {
                return "Cuenta para depósito";
            }

            if (this.props.data.TipoCta === TIPOS_CHEQUERAS.RETIRO) {
                return "Cuenta para retiro";
            }

            if (this.props.data.TipoCta === TIPOS_CHEQUERAS.INFORMATIVA) {
                return "Cuenta del contrato";
            }
        }
        return "";
    }

    guardaLimite = async () => {
        let { rango, permiteTransaccion } = this.state;
        this.setState({ loading: true });

        try {
            let bodyConfChquera = SERVICES.CONF_CHEQUERA.body;
            bodyConfChquera.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyConfChquera.IdUsuario = encryptValues(this.props.dataUser.IdUsuario);
            bodyConfChquera.Chequera = encryptValues(this.props.data.Cuenta);
            bodyConfChquera.LimiteRetiro = parseInt(rango);
            bodyConfChquera.Titular = this.props.data.Titular;
            bodyConfChquera.Banco = this.props.data.Banco;
            bodyConfChquera.Habilitada = permiteTransaccion ? 1 : 0;

            let resultConfChquera = await makeCall(SERVICES.CONF_CHEQUERA, bodyConfChquera);

            if (resultConfChquera && resultConfChquera.response) {
                this.setState({ loading: false }, async () => {
                    if (resultConfChquera.response.Exito !== 200) {
                        this.props.okButton({
                            message: resultConfChquera.response.MensajeError, title: "Advertencia", txtButton: "Entendido",
                            statusCode: resultConfChquera.response.Exito
                        });
                        // M.toast({ html: resultConfChquera.response.MensajeError, classes: 'red darken-3' });
                    } else {
                        await this.reloadChequera();
                        this.props.okButton({
                            message: "Apreciable cliente, la configuración ha sido guardada con éxito.", title: "Información", txtButton: "Entendido",
                            statusCode: 200
                        });
                        // M.toast({ html:'Configuración guardada.', classes: 'green' });
                    }
                });

            }

        } catch (error) {
            this.setState({ loading: false }, () => {
                this.props.okButton({
                    message: 'Ocurrió un error inesperado intent de nuevo.', title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });

            });
        }
    }

    reloadChequera = async () => {
        try {
            let serviceChequera = SERVICES.CHEQUERA;
            let bodyChequera = serviceChequera.body;
            bodyChequera.IdUsuario = encryptValues(this.props.dataUser.IdUsuario);

            let chequera = await makeCall(serviceChequera, bodyChequera);

            this.props.setChequera(chequera.response.TTCUENTAS['TT-CUENTAS']);
        } catch (error) {
            this.props.okButton({
                message: 'No se logro actualizar la chequera.', title: "Advertencia", txtButton: "Entendido",
                statusCode: 404
            });
            // M.toast({html:"No se logro actualizar la chequera.",classes:'red darken-3'})
        }
    }


    render() {

        if (!this.props.data) {
            return null;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col s12 mt-1 left-align">
                        {this.getTipo()}
                    </div>
                    <div className="col s12 mt-1">
                        <div className="row grayBorder" style={{ marginLeft: '0px', marginRight: '0px' }}>
                            <div className="col s12 boldText mt-1">
                                {this.props.data.DescBanco} - {this.props.data.Cuenta}
                            </div>
                            <div className="col s12">
                                {this.props.data.CLABE}
                            </div>
                            <div className="col s12 mb-1" style={{ textTransform: 'capitalize' }}>
                                {this.props.data.Nombre.toLowerCase()}
                            </div>
                        </div>
                    </div>
                </div>
                {
                     this.props.data.TipoCta !== TIPOS_CHEQUERAS.INFORMATIVA ?
                        <div className="row">
                            <div className="col s12 mt-1">
                                Permisos
                                <div className="divider mt-1" />
                            </div>
                            <div className="col s12 switch mt-1">
                                Permitir transacciones
                                <label className="floatRight">
                                    <input type="checkbox"
                                        id="permiteTransaccion"
                                        onChange={this.onChangeValue}
                                        checked={this.state.permiteTransaccion}
                                        value={this.state.permiteTransaccion} />
                                    <span className="lever"></span>
                                </label>
                                <div className="divider mt-1" />
                            </div>

                            {this.props.data && this.props.data.TipoCta == "R" ?
                                <React.Fragment>
                                    <div className="col s12 mt-1">
                                        Límite de retiro
                                    </div>
                                    <div className="col s12 range-field">

                                        <input type="range" id="rango" min="0" max="300000" step="50000"
                                            value={this.state.rango}
                                            onChange={this.onChangeValue}
                                        />

                                    </div>
                                    <div className="col s6 left-align">
                                        <label>$0.00</label>
                                    </div>
                                    <div className="col s6 right-align">
                                        <label>$300,000.00</label>
                                    </div>
                                    <div className="col s12 mt-1 boldText">
                                        ${numberWithCommas(parseFloat(this.state.rango).toFixed(2))}
                                    </div>
                                </React.Fragment>

                                : null
                            }


                            <div className="col s12 center-align mt-1">
                                {
                                    this.state.loading ?
                                        <Reload action={() => { }} />

                                        :
                                        <button className="waves-effect btn-flat flatPrimary" type="button"
                                            onClick={() => {
                                                this.guardaLimite();
                                            }} > Guardar  {this.props.data && this.props.data.TipoCta == "R" ? "límite de retiro" : "permisos"}  </button>
                                }

                            </div>
                        </div>
                        : null
                }
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    let { operacion, currentContrato, dataUser } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
    }
    if (state.commonsActions) {
        operacion = state.commonsActions.operacion || false;
    }

    return { operacion, currentContrato, dataUser };
};

export default connect(mapStateToProps, { setChequera, okButton })(Chequera)