import {OK_BUTTON,HIDE_TOKEN,SHOW_TOKEN,HIDE_OK_BUTTON, 
        SET_MENU_PERFIL, SET_MENU_PRINCIPAL, SET_HISTORY,
        SET_COMPOSICION,HIDE_COMPOSICION, SET_OPERACION,
        HIDE_OPERACION, SET_FINGERPRINT, SET_COMPOSICION_SALDO_LIQUIDO,
        SET_COORDENADAS, SET_IP,SET_OPTION_SELECTED_MENU, CARGA_INFO,
        SERVICIOS_DISPONIBLES, RECARGA_CARTERA, SET_TIPOS_SERVICIOS,
        SET_SALDO_TOTAL, SET_RENDIMIENTOS, SET_POSICIONCARTERA, SET_SALDO_LIQUIDO,
        SET_TOKEN_TO_PASS, SET_SALDO_OPERACION, CONTRATO_A_REMOVER, SET_AVATAR, MODAL_INFO,
        MODAL_FCHNAC
    } from '../actionTypes';
import M from 'materialize-css';

const initialState={
    ok_btn_message:""
}


 const commonsActions =(state = initialState,action) =>{
    switch(action.type){
        case OK_BUTTON:{

            const {message,actionClick,txtButton,title,closeAfterClick, statusCode} = action.payload;
            if(message && message.trim() !== ""){
                M.Modal.getInstance(document.querySelectorAll('.modal_okButton')[0]).open();
            }
            return {...state,ok_btn_message:message,ok_btn_action:actionClick,txtButton,title,closeAfterClick,statusCode}
        }
        case HIDE_OK_BUTTON:{
            M.Modal.getInstance(document.querySelectorAll('.modal_okButton')[0]).close();
          
            return {...state,ok_btn_message:false,ok_btn_action:false,txtButton:false,title:false}
        }
        case SHOW_TOKEN:{
            M.Modal.getInstance(document.querySelectorAll('.modal_token')[0]).open();
            return {...state,getValidation:action.payload}
        }
        case HIDE_TOKEN:{
            M.Modal.getInstance(document.querySelectorAll('.modal_token')[0]).close();
            return {...state, getValidation: false}
        }
        case SET_MENU_PERFIL:{
            return {...state, menuPerfil:action.payload}
        }
        case SET_MENU_PRINCIPAL:{
            return {...state, menuPrincipal:action.payload}
        }
        case SET_HISTORY:{
            return {...state,history:action.payload}
        }
        case SET_COMPOSICION:{
            M.Modal.getInstance(document.querySelectorAll('.modal_composicion')[0]).open();
            return {...state,composicion:action.payload}
        }
        case SET_COMPOSICION_SALDO_LIQUIDO:{
            return {...state,composicionSL:action.payload}
        }
        case HIDE_COMPOSICION:{
            M.Modal.getInstance(document.querySelectorAll('.modal_composicion')[0]).close();
            return {...state,composicion:false}
        }
        case SET_OPERACION:{
            M.Modal.getInstance(document.querySelectorAll('.model_operacion')[0]).open();
            return {...state,operacion:action.payload}
        }
        case HIDE_OPERACION:{
            M.Modal.getInstance(document.querySelectorAll('.model_operacion')[0]).close();
            return {...state,operacion:false}
        }
        case SET_FINGERPRINT:{
            return {...state,fingerPrint:action.payload}
        }
        case SET_COORDENADAS:{
            return {...state,coordenadas:action.payload}
        }
        case SET_IP:{
            return {...state,ip:action.payload}
        }
        case SET_OPTION_SELECTED_MENU:{
            return {...state,optionSelected:action.payload}
        }
        case CARGA_INFO:{
            return {...state,cargaInfo:action.payload}
        }
        case SERVICIOS_DISPONIBLES:{
            return {...state,sDisponibles:action.payload}
        }
        case RECARGA_CARTERA:{
            return {...state,esRecargaCartera: action.payload}
        }
        case SET_TIPOS_SERVICIOS:{
            return {...state,tiposServicios: action.payload}
        }
        case SET_SALDO_TOTAL:{
       
            return {...state,saldoTotal: action.payload}
        }
        case SET_SALDO_LIQUIDO:{
       
            return {...state,saldoLiquido: action.payload}
        }
        case SET_SALDO_OPERACION:{
            return {...state,saldoOperacion: action.payload}
        }
        case SET_RENDIMIENTOS:{
            return {...state,rendimientos:action.payload} 
        }
        case SET_POSICIONCARTERA:{
            return {...state,posicionCartera:action.payload} 
        }
        case SET_TOKEN_TO_PASS:{
            return {...state,tokentoPass:action.payload} 
        }
        case CONTRATO_A_REMOVER:{
            return {...state,contratoARemover:action.payload}
        }
        case SET_AVATAR:{
            return {...state,userAvatar:action.payload}
        }
        case MODAL_INFO:{
   
            if(action.payload && action.payload.text && action.payload.text.trim() !== ""){
                M.Modal.getInstance(document.getElementById('modalInfo')).open();
            }
           
            return {...state, infoModalData: action.payload}
            
        }
        case MODAL_FCHNAC:{
   
            M.Modal.getInstance(document.getElementById('modalFchNac')).open();
            return {...state, infoModalData: action.payload}
            
        }
        default:{
            return state;
        }
    }
}

export default commonsActions;