import ALERTS from './alerts.svg';
import ATM from './atm.svg';
import BRIEFCASE from './briefcase.svg';
import CARD from './card.svg';
import CERTIFY from './certify.svg';
import CONTACTS from './contacts.svg';
import DATA from './data.svg';
import DATE from './date.svg';
import DOCS from './docs.svg';
import EURO from './euro.svg';
import EXPENSIVE from './expensive.svg';
import FILTER from './filter.svg';
import HE from './he.svg';
import HIDE from './hide.svg';
import LOCK from './lock.svg';
import MAIL from './mail.svg';
import MENU from './menu.svg';
import MOVINGS from './movings.svg';
import PESO from './peso.svg';
import PIE from './pie.svg';
import SAFEBOX from './safebox.svg';
import SESION from './sesion.svg';
import SHARE from './share.svg';
import SHOW from './show.svg';
import SMARTHPHONE from './smartphone.svg';
import STAMP from './stamp.svg';
import WINS from './wins.svg';
import YEN from './yen.svg';
import AVATAR from '../avatar.jpg';
import WHITEMONEDA from './whitemoneda.svg';
import UNO from './uno.svg';
import DOS from './dos.svg';
import TRES from './tres.svg';
import CHECK_BLACK from './checkBlack.svg';
import LOGO_CIRCULO from './logoCirculo.svg';
import CARGO from './cargo.svg';
import ABONO from './abono.svg';
import FILTER_WHITE from './filter_white.svg';
import NO_SERVICES from './no_services.svg';
import SUCCESS from './success.png';
import CIRCLE_OUTLINE from './circleOutline.svg';
import CHAT from './chat.svg';
import NEWS from './news.svg'
import FOTO_FONDO from './fotoFondo.png';
import NEW_LOGO_LOGIN from './newLogoLogin.svg';
import CALL_CENTER from './callCenter.svg';
import UBICACION_NEW from './ubicacionNew.svg';
import PREGUNTAS_FRECUENTES from './preguntasFrecuentes.svg';
import DATOS_VALIDOS from './datosValidos.svg';
import ERROR_DATOS from './errorData.svg';
import OK_SUCCESS from './ok_success.svg';

export const ICONS = {
    ALERTS,ATM,BRIEFCASE,CARD,CERTIFY, CONTACTS,DATA,DATE,DOCS,
    EURO,EXPENSIVE,FILTER,
    HE,HIDE,LOCK,MAIL,MENU,MOVINGS,PESO,PIE,
    SAFEBOX,SESION,SHARE,SHOW,SMARTHPHONE,STAMP,WINS,YEN,
    AVATAR, WHITEMONEDA, UNO,DOS,TRES,CHECK_BLACK, LOGO_CIRCULO,
    CARGO, ABONO, FILTER_WHITE, NO_SERVICES, SUCCESS,CIRCLE_OUTLINE,
    CHAT, NEWS,FOTO_FONDO, NEW_LOGO_LOGIN, CALL_CENTER, UBICACION_NEW,
    PREGUNTAS_FRECUENTES, DATOS_VALIDOS, ERROR_DATOS, OK_SUCCESS
}