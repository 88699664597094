import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICONS } from '../../assets/icons';
import { showToken, okButton, recargaCartera, setCoordenadas } from '../../redux/actions';
import {
    OPERACION_EMISORA_FONDO, SERVICES, TIPOS_CHEQUERAS,
    ACCION_CODIGO
} from '../../properties';
import {
    makeCall, nWithCommas, encryptValues, decryptValue, getSaldoLiquido, getComision, getIva, getCoordenadas,
    roundDouble, shareDetail,openTip,closeTip
} from '../../utils';
import Reload from '../reload';
import M from 'materialize-css';
import firebase from 'firebase';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Compra extends Component {

    state = {
        importe: "",
        fondos: false,
        fondo: false,
        cuentaSelected: false,
        errorMsg: false,
        fondoSelected: "",
        titulos: false,
        total: false,
        saldoLiquido: false,
        sendingData: false,
        operacionFinalizada: false,
        detail: false,
        esSaldoLiquido: false,
        saldoOperacion: 0
    }


    async componentDidMount() {
        try {
            await this.getEmisoraFondos();
            let saldoOperacion = await getSaldoLiquido({
                dataUser: this.props.dataUser,
                currentContrato: this.props.currentContrato,
                TipoSaldo: "D"
            });
            this.setState({ saldoOperacion });
        } catch (error) {

        }

        M.updateTextFields();
    }


    getEmisoraFondos = async () => {
        try {
            openTip();
            let bodyEmisoraFondos = SERVICES.EMISORA_FONDOS.body;
            bodyEmisoraFondos.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyEmisoraFondos.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyEmisoraFondos.TipoOper = OPERACION_EMISORA_FONDO.COMPRA;
            bodyEmisoraFondos.DeviceToken = this.props.dataUser ? this.props.dataUser.deviceToken : "";

            let result = await makeCall(SERVICES.EMISORA_FONDOS, bodyEmisoraFondos);

            if (result && result.response && result.response.Exito === 200) {
                // console.log("EMISORAS FONDOS");
                // console.log(result.response['TTFONDOS']['TT-FONDOS']);
                this.setState({ fondos: result.response['TTFONDOS']['TT-FONDOS'] }, () => {
                    M.FormSelect.init(document.querySelectorAll('select'), {});
                    closeTip();
                });
            } else {
                this.setState({ fondos: [] },()=>{
                    closeTip();
                });
            }


        } catch (error) {

        }
    }

    onChangeValue = (event) => {
        let { fondo } = this.state;
        let { id, value } = event.target;
        let errorMsg = false;

        if (id === 'fondoSelected' && value.trim() !== "") {
            let { fondos } = this.state;
            let f = fondos[parseInt(value.trim())];
            fondo = f;
        } else if (id === 'fondoSelected' && value.trim() === "") {
            fondo = false;
        }



        this.setState({ [id]: value, errorMsg, fondo }, () => {

            this.getTotales();


        });
    }

    getTotales = () => {

        let { importe, fondo, saldoOperacion } = this.state;
        let imp = parseFloat(importe.trim() != "" ? importe.trim() : 0);

        //let { saldoOperacion } = this.props;

        let titulos = false;
        let total = false;
        let saldoLiquido = false;
        let errorMsg = false;

        const f = fondo;
        let fechaActual = new Date();
        let ha = fechaActual.getHours();  //hora actual
        let ma = fechaActual.getMinutes(); //minuto actual
        if (!fondo) {
            errorMsg = "Seleccione un fondo";
        } else {
            if (!f.FondoAbierto || !f.CpaAbierta) {
                errorMsg = "El fondo  está cerrado para operaciones, por favor intente más tarde";
            } else if (
                Date.parse(`01/01/2011 ${ha}:${ma}:00`) < Date.parse(`01/01/2011 ${f.HApertura}:00`)
                ||
                Date.parse(`01/01/2011 ${ha}:${ma}:00`) > Date.parse(`01/01/2011 ${f.HCierre}:00`)
            ) {
                errorMsg = `El horario de operación es de ${f.HApertura} a ${f.HCierre} Hrs. Horario de la Ciudad de México (GMT-6), Por favor intente después.`;
            }
        }


        if (fondo && imp > 0 && !errorMsg) {

            if (imp <= saldoOperacion.totalSaldoMXP) {

                let fondoPrecio = fondo.Precio;

                let importeCalculado = parseFloat(importe);
                titulos = Math.floor(importeCalculado / parseFloat(fondoPrecio));
                importeCalculado = roundDouble(parseFloat(titulos * parseFloat(fondoPrecio)), 2);
                let comision = getComision(fondo, importeCalculado);
                let iva = getIva(fondo, comision);
                total = roundDouble((importeCalculado + iva + comision), 2);

                if (parseFloat(total) > parseFloat(importe)) {
                    while (parseFloat(total) > parseFloat(importe)) {
                        titulos = titulos - 1;
                        importeCalculado = roundDouble(parseFloat(titulos * parseFloat(fondoPrecio)), 2);
                        comision = getComision(fondo, importeCalculado);
                        iva = getIva(fondo, parseFloat(comision));
                        total = roundDouble((importeCalculado + iva + comision), 2);

                    }
                }
                //  else {
                //     total = roundDouble((titulos * parseFloat(fondoPrecio)), 2);
                //     importeCalculado = total;
                // }
                saldoLiquido = (parseFloat(importe) - total).toFixed(2);



                if (fondo && imp > 0) {

                    if (imp > fondo.MontoMaximo) {
                        errorMsg = `El monto máximo de compra es de $${nWithCommas(`${parseFloat(fondo.MontoMaximo).toFixed(2)}`)}.`;
                    }

                    if (imp < fondo.MontoMinimo) {
                        errorMsg = `El monto mínimo de compra es de $${nWithCommas(`${parseFloat(fondo.MontoMinimo).toFixed(2)}`)}.`;
                    }

                    if (titulos > fondo.TitulosMaximo) {
                        errorMsg = `Los títulos máximos a comprar son ${nWithCommas(`${fondo.TitulosMaximo}`)}.`;
                    }

                    if (titulos < fondo.TitulosMinimo) {
                        errorMsg = `Los títulos mínimos a comprar son ${nWithCommas(`${fondo.TitulosMinimo}`)}.`;
                    }
                }


                this.setState({ titulos, total, saldoLiquido, importe, importeCalculado, iva, comision: parseFloat(comision).toFixed(2), errorMsg });



            } else {
                this.setState({ errorMsg: "No cuenta son saldo suficiente para esta operación." });
            }

            if (parseFloat(imp) <= 0) {
                this.setState({ errorMsg: "Indique un monto válido mayor a $0.00." });
            }
        } else {
            this.setState({ errorMsg: errorMsg });
        }



    }

    render() {

        let { importe,
            fondos,
            fondoSelected,
            cuentaSelected,
            errorMsg,
            titulos,
            iva,
            comision,
            importeCalculado,
            esSaldoLiquido,
            total,
            saldoLiquido,
            sendingData,
            operacionFinalizada,
            detail,
            fondo
        } = this.state;

        if (!fondos) {
            return (
                <div className="row marginLR-1r">
                    <div className="col s12 center-align mt-1">
                        <Reload action={() => { }} />
                    </div>
                </div>
            )
        }

        if (fondos && !fondos.length) {
            return (
                <div className="row marginLR-1r">
                    <div className="col s12 center-align mt-1">
                        No se encontrarón fondos para realizar compras.
                    </div>
                </div>
            )
        }

        if (operacionFinalizada && detail.length) {
            return (
                <React.Fragment>
                    <div className="row marginLR-1r detalleRecibo" id="detalleRecibo">
                        <div id="cabeceraComprobante">
                            <div className="col s2 center-align">
                                <img src={ICONS.LOGO_CIRCULO} alt="LOGO" />
                            </div>
                            <div className="col s6" style={{ paddingTop: "1.5rem" }}>
                                <span>Operación de Compra</span>
                            </div>
                            <div className='col s4 right-align' style={{ paddingTop: "1.5rem" }}>
                                <span>{moment().format('yyyy/MM/DD HH:mm')}</span>
                            </div>
                        </div>
                        {
                            detail.map((v, i) => {
                         
                                let detalle = v;
                                let emisora = detalle.Emisora || "";
                                return (
                                    <div className="col s12 mt-1 detailCard" key={i}>
                                        <div className="row containerDetalleOpera">
                                            <div className="col s12 navyBackground white-text" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: "30px", paddingTop: "5px" }}>
                                                {
                                                    emisora.trim() === "" ?
                                                        <span>Saldo Líquido</span>
                                                        :
                                                        <React.Fragment>
                                                            <span>{emisora}</span>
                                                            {/* <span>{cuentaSelected.DescBanco}</span>  <span className="floatRight">*****{`${cuentaSelected.Cuenta}`.substring(`${cuentaSelected.Cuenta}`.length - 4)}</span> */}
                                                        </React.Fragment>
                                                }
                                                <span className="floatRight"> ${detalle.Importe.trim()}</span>

                                            </div>
                                            <div className="col s12">
                                                <p className="noMBottom ft-10">Tipo de Operación</p>
                                                <p className="noMTop">{detalle.TipoOper}</p>
                                            </div>
                                            {
                                                emisora.trim() !== "" ?

                                                    <div className="col s6 bgGray">
                                                        <p className="noMBottom ft-10">Títulos</p>
                                                        <p className={`noMTop ${detalle.Titulos ? '' : 'txtGray'}`}>
                                                            {detalle.Titulos ? detalle.Titulos : '_'}</p>
                                                    </div>

                                                    : null
                                            }
                                            <div className={emisora.trim() === "" ? 'col s12 bgGray' : 'col s6 bgGray'}>
                                                <p className="noMBottom ft-10">Precio</p>
                                                <p className="noMTop">${fondo.Precio}</p>
                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Comisión</p>
                                                {
                                                    detalle.Comision !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.Comision)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }

                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">IVA</p>
                                                {
                                                    detalle.IVA !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.IVA)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }
                                            </div>

                                            <div className="col s12 left-align bgGray">
                                                <p className="noMBottom ft-10">Neto Liquidar</p>
                                                {
                                                    detalle.NetoLiquidar !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.NetoLiquidar)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }
                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Fecha de operación</p>
                                                <p className="noMTop">{detalle.FOper}</p>
                                            </div>
                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Hora de operación</p>
                                                <p className="noMTop">{detalle.Hora}</p>
                                            </div>
                                            <div className="col s12 bgGray">
                                                <p className="noMBottom ft-10">Fecha de liquidación</p>
                                                <p className="noMTop">{detalle.FLiq}</p>
                                            </div>
                                            <div className="col s6 left-align ">
                                                <p className="noMBottom ft-10">No. de contrato</p>
                                                <p className="noMTop">{this.props.currentContrato ? this.props.currentContrato.Contrato : ""}</p>
                                            </div>
                                            <div className="col s6 left-align ">
                                                <p className="noMBottom ft-10">Folio</p>
                                                <p className="noMTop">{detalle.Folio}</p>
                                            </div>
                                            <div className="col s12 bgGray">
                                                <p className="noMBottom ft-10">Servicio de Inversión</p>
                                                <p className="noMTop">{detalle.TipoServicio && detalle.TipoServicio.trim() !== "" ?
                                                    decryptValue(detalle.TipoServicio) : ""
                                                }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="col s12 ft-12 " id="footerCardDetail">
                            Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                        </div>
                    </div>
                    <div className="col s12 center-align">
                        <button onClick={() => {
                            shareDetail('detalleRecibo');
                        }} className="btn primaryBtn_oc width100 boldText mb-1" >Compartir</button>
                    </div>
                </React.Fragment>
            )
        }



        return (
            <div className="row marginLR-1r">

                <div className="col s12 mt-1">
                    <span><img src={ICONS.UNO} alt="icon uno" /></span>  Indica el importe:
                </div>
                <div className="input-field col s12 mt-1">
                    <NumberFormat
                        id="importe"
                        thousandSeparator={','}
                        decimalSeparator="."
                        value={importe}
                        isNumericString
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        onValueChange={(values) => {

                            let errorMsg = false;

                            if (parseFloat(values.value) <= 0) {
                                errorMsg = "Indique un monto válido mayor a $0.00.";
                            }

                            this.setState({ importe: values.value, errorMsg }, () => {
                                this.getTotales();
                            })

                        }}
                    />
                    <label htmlFor="importe">Importe</label>
                    {errorMsg ?
                        <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                        : null}
                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.DOS} alt="icon dos" /></span>  Selecciona un fondo:
                </div>
                <div className="input-field col s12 mt-1">
                    <select className="browser-default" id='fondoSelected' onChange={this.onChangeValue} value={fondoSelected}>
                        <option value="">Selecciona una opción</option>
                        {
                            fondos.map((v, i) => {
                                return (
                                    <option key={i} value={i}>{v.Emisora} - {v.Precio} (Liquida {parseInt(v.Plazo) > 0 ? `en ${v.Plazo} días` : `hoy`})</option>
                                )
                            })
                        }
                    </select>
                </div>
                {
                    titulos && fondoSelected && !errorMsg ?
                        <React.Fragment>
                            <div className="col s6 right-align">
                                Títulos
                            </div>
                            <div className="col s6 right-align boldText">
                                {nWithCommas(`${titulos}`)}
                            </div>
                            <div className="col s6 right-align">
                                Importe
                            </div>
                            <div className="col s6 right-align boldText">

                                ${importeCalculado !== null ? nWithCommas(`${importeCalculado}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                Comisión
                            </div>
                            <div className="col s6 right-align boldText">

                                ${comision !== null ? nWithCommas(`${comision}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                IVA
                            </div>
                            <div className="col s6 right-align boldText">

                                ${iva !== null ? nWithCommas(`${iva}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                Neto a liquidar
                            </div>
                            <div className="col s6 right-align boldText">
                                ${nWithCommas(`${total}`)}
                            </div>
                        </React.Fragment>
                        : null
                }

                {
                    this.state.fondo && this.state.fondo.Plazo.trim() !== "0" ?
                        <div className="col s12 mt-1">
                            <div className='row'>
                                <div className='col s1'>
                                    <label><i className="material-icons left">info_outline</i></label>
                                </div>
                                <div className='col s11'>
                                    <p className='noMargin'> <label> Con el fin de garantizar los recursos para la compra del fondo seleccionado que no liquida mismo día, instruyo una compra del fondo valmx10 y una venta de valmx10 con la fecha en que liquida la compra.</label></p>
                                </div>
                            </div>
                        </div>

                        : null
                }


                <div className="col s12 mt-1">
                    <div className='row'>
                        <div className='col s1'>
                            <label><i className="material-icons left">info_outline</i></label>
                        </div>
                        <div className='col s11'>
                            <p className='noMargin'> <label> Para fondos que no liquidan el mismo día, las cantidades de precio, títulos, importe, comisión, IVA y neto a liquidar, pueden cambiar de acuerdo al precio de cierre de día. La instrucción se gira al amparo del servicio de Ejecución de Operaciones.</label></p>
                        </div>
                    </div>
                </div>

                <div className="col s12 mt-1">
                    <span><img src={ICONS.TRES} alt="icon tres" /></span>  Selecciona una cuenta para obtener los fondos:
                </div>

                <div className="col s12 mt-1">
                    <div className="row boxDepositoRetiro">
                        {
                            this.getTipoChequera(TIPOS_CHEQUERAS.DEPOSITO)
                        }
                    </div>
                </div>
                {
                    importe.trim() !== "" && (esSaldoLiquido || cuentaSelected && fondoSelected.trim() !== "")
                        ?
                        <div className="col s12 center-align">
                            {
                                sendingData ?

                                    <Reload action={() => { }} />

                                    : <button className="btn primaryBtn_oc width100 boldText"
                                        disabled={errorMsg || !fondo}
                                        onClick={this.sendData}
                                    >Comprar</button>
                            }

                        </div>
                        : null
                }
            </div>
        )
    }

    sendData = async () => {

        let { fondo, importe, cuentaSelected, titulos, esSaldoLiquido, total } = this.state;

        if (await getCoordenadas(this.props)) {

            this.props.showToken({
                idUsuario: this.props.dataUser.IdUsuario, accion: ACCION_CODIGO.ACCESO,
                title: "Código de Seguridad",
                message: "Ingresar el código de seguridad generado en tu aplicación móvil (token) para validar tu operación.",
                txtButton: "Confirmar Operación",
                onlyPass: true,
                esOperacion: true,
                actionClick: async (softToken) => {
                    let P = await new Promise(async (resolve) => {
                        try {
                            openTip();
                            let bodyOperafondo = SERVICES.OPERA_FONDOS.body;
                            bodyOperafondo.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
                            bodyOperafondo.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
                            bodyOperafondo.TipoOper = OPERACION_EMISORA_FONDO.COMPRA;
                            bodyOperafondo.Emisora = fondo.Emisora;
                            bodyOperafondo.Serie = fondo.Serie;
                            bodyOperafondo.Importe = importe;
                            bodyOperafondo.Precio = fondo.Precio;
                            bodyOperafondo.Titulos = titulos;
                            bodyOperafondo.TipoLiq = esSaldoLiquido ? 'S' : 'Q';
                            bodyOperafondo.Titular = !esSaldoLiquido ? cuentaSelected.Titular : null;
                            bodyOperafondo.Banco = !esSaldoLiquido ? cuentaSelected.Banco : null;
                            bodyOperafondo.Cuenta = !esSaldoLiquido ? encryptValues(cuentaSelected.Cuenta) : null;
                            bodyOperafondo.SoftToken = encryptValues(softToken);
                            bodyOperafondo.Latitud = encryptValues(this.props.coordenadas.latitude);
                            bodyOperafondo.Longitud = encryptValues(this.props.coordenadas.longitude);
                            bodyOperafondo.Pais = encryptValues(this.props.coordenadas.pais);
                            bodyOperafondo.Ciudad = encryptValues(this.props.coordenadas.ciudad);
                            bodyOperafondo.Estado = encryptValues(this.props.coordenadas.estado);
                            bodyOperafondo.Direccion = encryptValues(this.props.coordenadas.direccion);
                            bodyOperafondo.IP = encryptValues(this.props.ip);

                            let resultOperaFondo = await makeCall(SERVICES.OPERA_FONDOS, bodyOperafondo);

                            if (resultOperaFondo && resultOperaFondo.response) {
                                closeTip();
                                if (resultOperaFondo.response.Exito !== 200) {
                                  
                                    this.setState({ errorMsg: resultOperaFondo.response.MensajeError });

                                }
                                else if (resultOperaFondo.response.Exito === 408) {
                                  
                                    this.setState({ errorMsg: resultOperaFondo.response.MensajeError });
                                }
                                else {
                                    let detail = resultOperaFondo.response['TTOPERACIONES']['TT-OPERACIONES'];

                                    let instance = M.FormSelect.getInstance(document.getElementById('fondoSelected'));
                                    if (instance) {
                                        instance.destroy();
                                    }

                                    this.setState({ operacionFinalizada: true, detail }, () => {
                                        this.props.recargaCartera(true);
                                    });
                                }


                                firebase.analytics().logEvent('Opera_compra_f');
                                this.setState({ sendingData: false });
                                resolve(true);
                            } else {
                                this.setState({ sendingData: false, errorMsg: "Apreciable cliente ocurrió un error al procesar su compra, por favor intente más tarde o contacte a su promotor." },
                                ()=>{
                                    closeTip();
                                });
                                resolve(true);
                            }

                        } catch (error) {
                            this.setState({ sendingData: false, errorMsg: "Apreciable cliente ocurrió un error al procesar su compra, por favor intente más tarde o contacte a su promotor." },
                            ()=>{
                                closeTip();
                            });
                            resolve(true);

                        }
                    });


                    return P;

                }
            });
        }

    }

    getTipoChequera = (tipo) => {

        let { cuentaSelected, saldoOperacion } = this.state;
        // let { saldoOperacion } = this.props;

        if (this.props.chequera && this.props.chequera.length) {
            let filterChequera = this.props.chequera.filter((v, i) => {
                if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato) {
                    return v;
                }
            });

            if (true) {


                return (<div className="col s12">
                    <ul className="collection">
                        <li className="collection-item row hoverContent" onClick={() => {
                            this.setState({ esSaldoLiquido: true, cuentaSelected: false });
                        }}>
                            <div className="row mb-0px">
                                <div className="col s10">
                                    <div className="row mb-0px">
                                        <div className="col s12 left-align">
                                            Saldo líquido
                                        </div>
                                        <div className="col s12 left-align boldText mt-1">
                                            Contrato - {this.props.currentContrato ? this.props.currentContrato.Contrato : ""}
                                        </div>
                                        <div className="col s12 left-align">
                                            ${nWithCommas(parseFloat(saldoOperacion.totalSaldoMXP ?? 0).toFixed(2))}
                                        </div>
                                    </div>

                                </div>
                                <div className="col s2 center-align">
                                    {
                                        this.state.esSaldoLiquido ?
                                            <img src={ICONS.CHECK_BLACK} className="mt-1" alt='Check' />
                                            : <img src={ICONS.CIRCLE_OUTLINE} className="mt-1" alt='Check' />
                                    }

                                </div>
                            </div>

                        </li>
                    </ul>
                </div>
                )

            }



        }

        return <div></div>;

    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, coordenadas, ip, saldoTotal, saldoOperacion
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false

    }

    if (state.commonsActions) {
        coordenadas = state.commonsActions.coordenadas || false;
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        ip = state.commonsActions.ip || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        saldoOperacion = state.commonsActions.saldoOperacion || 0;
    }

    return {
        dataUser, currentContrato, menuPerfil, contratosUser, chequera, history, coordenadas,
        ip, saldoTotal, saldoOperacion
    };
};

export default connect(mapStateToProps,
    {
        showToken, okButton, recargaCartera, getCoordenadas, setCoordenadas
    })(Compra);