import React, { Component } from 'react';
import M from 'materialize-css';
import { getAcciones, getDivisas, getIndices } from '../../utils';
import firebase from 'firebase';
import Reload from '../reload';

class DivisasIndicesAcciones extends Component {

    state = {
        divisas: [],
        indices: [],
        acciones: [],
        currentOption: false,
        loading: true,
    }

    async componentDidMount() {
        M.Tabs.init(document.querySelector('.tabs'), {});
        this.initSelect();

        let divisas = await getDivisas();
        let indices = await getIndices();
        let acciones = await getAcciones();

        this.setState({ divisas, indices, acciones, loading: false },
            () => {
                M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
            });
    }

    render() {
        let { divisas, indices, acciones, currentOption, loading } = this.state;
        return (
            <div className="col s12 z-depth-2 br20">
                <div className="col s12">
                    <ul className="tabs">
                        <li className="tab col s4"><a href="#divisas" className="active"
                            onClick={() => {
                                firebase.analytics().logEvent('Mercado_divisas')
                            }}
                        >Divisas</a></li>
                        <li className="tab col s4"><a href="#indices"
                            onClick={() => {
                                firebase.analytics().logEvent('mercado_indices')
                            }}
                        >Índices</a></li>
                        <li className="tab col s4"><a href="#acciones"
                            onClick={() => {
                                firebase.analytics().logEvent('mercado_acciones')
                            }}
                        >Acciones</a></li>
                    </ul>
                </div>
                <div id="divisas" className="col s12">
                    {this.getLoading()}
                    {
                        divisas.length ?
                            <div className="row indicesContainer">
                                <ul className="collapsible" >
                                    {
                                        divisas.map((v, i) => {
                                            let name = "";
                                            if (v.name === 'Divisas') {
                                                name = 'Divisa';
                                            } else if (v.name.includes("Mercan")) {
                                                name = 'Mercancía';
                                            } else if (v.name === "Deuda") {
                                                name = "Instrumento";
                                            }
                                            return (

                                                <li key={i}
                                                    className={v.name === 'Divisas' ? 'active' : ''}
                                                    onClick={() => {
                                                        let { currentOption } = this.state;
                                                        if (currentOption === v.name) {
                                                            currentOption = "";
                                                        } else {
                                                            currentOption = v.name;
                                                        }


                                                        this.setState({ currentOption })
                                                    }}>
                                                    <div className="collapsible-header"><i className="material-icons">
                                                        {
                                                            currentOption === v.name ? "arrow_downward" : "arrow_forward"
                                                        }
                                                    </i>{v.name}</div>
                                                    <div className="collapsible-body">
                                                        {
                                                            v.d.length ?
                                                                <div className="row divisasContainer">
                                                                    {
                                                                        this.getDivisas(v.d, name)
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </li>

                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            : !loading ? <div className="row">
                                <div className="col s12 center-align mt-1">
                                    Información no disponible por el momento, por favor intente más tardes.
                                </div>
                            </div> : null
                    }

                </div>
                <div id="indices" className="col s12">
                    {this.getLoading()}
                    {
                        indices.length ?
                            <div className="row divisasContainer">
                                {
                                    this.getDivisas(indices, "IPC")
                                }
                            </div>
                            : !loading ? <div className="row">
                                <div className="col s12 center-align mt-1">
                                    Información no disponible por el momento, por favor intente más tardes.
                                </div>
                            </div> : null
                    }

                </div>
                <div id="acciones" className="col s12">
                    {this.getLoading()}
                    {
                        acciones.length ?
                            <div className="row divisasContainer">
                                {
                                    this.getAcciones(acciones)
                                }
                            </div>
                            : !loading ? <div className="row">
                                <div className="col s12 center-align mt-1">
                                    Información no disponible por el momento, por favor intente más tardes.
                                </div>
                            </div> : null
                    }


                </div>

                <div className='col s12 ft-12 mb-10 mt-0'>

                    Fuente Infosel.

                </div>
            </div>
        )
    }

    getLoading() {
        let { loading } = this.state;

        if (loading) {
            return (<div className="row">
                <div className="col s12 center-align">
                    <Reload action={() => { }} />
                </div>
            </div>)
        } else {
            return null;
        }
    }

    getDivisas(divisas, titulo) {
        return (
            <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            <th className="center-align bgGray ft-12 NavyPrimary">{titulo}</th>
                            <th className="center-align bgGray ft-12 NavyPrimary">Actual</th>
                            {
                                titulo.toLowerCase() !== "instrumento" ?
                                    <th className={`center-align bgGray ft-12 NavyPrimary`}>
                                        Anterior
                                    </th>
                                    : null
                            }
                            <th className="center-align bgGray ft-12 NavyPrimary">Variación</th>
                            <th className="center-align bgGray ft-12 NavyPrimary">%</th>
                            <th className="center-align bgGray ft-12 NavyPrimary">Fecha</th>
                            <th className="center-align bgGray ft-12 NavyPrimary">Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            divisas.map((v, i) => {
                                let isPar = i % 2 === 0 ? "" : "bgGray";
                                let isUp = parseFloat(v.variacion_porcentual) > 0 ? "greenTxt" : 'redTxt';
                                let diasFormat = "";
                                if (v.dias_por_vencer) {
                                    let split = v.dias_por_vencer.split(' ');
                                    if (split.length > 1) {
                                        diasFormat = v.dias_por_vencer;
                                    } else {
                                        diasFormat = `${v.dias_por_vencer} días.`;
                                    }
                                }
                                return (
                                    <tr key={i} className="tablaDivisas">
                                        <td className={`left-align borderRight ft-12 ${isPar}`}>
                                            {v.divisa || v.instrumento} {v.dias_por_vencer ?
                                                <span style={{ fontSize: "10px", color: 'gray' }}>{diasFormat}</span> : ""}
                                        </td>
                                        <td className={`right-align ft-12 borderRight ${isPar} ${isUp}`}>
                                            {v.valor || ""}
                                        </td>
                                        {
                                            titulo.toLowerCase() !== "instrumento" ?
                                                <td className={`right-align borderRight ft-12 ${isPar} ${isUp} `}>
                                                    {v.valor_anterior || ""}
                                                </td>
                                                : null}
                                        <td className={`right-align borderRight ft-12 ${isPar} ${isUp} `}>
                                            {v.variacion_unitaria || ""}
                                        </td>

                                        <td className={`right-align  borderRight ft-12 ${isPar} ${isUp} `}>
                                            {` ${v.variacion_porcentual || ""}%`}
                                            <i className="material-icons right divisaUpDown">
                                                {
                                                    isUp === "greenTxt" ? "arrow_upward" : "arrow_downward"
                                                }
                                            </i>
                                        </td>

                                        <td className={`right-align  borderRight ${isPar} ft-12 `}>
                                            {v.fecha || ""}
                                        </td>

                                        <td className={` right-align ${isPar} ft-12`}>
                                            {v.hora || ""}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </React.Fragment>
        )

    }

    getAcciones(acciones) {
        return (
            <React.Fragment>
                <div className={`col s1 center-align bgGray ft-12 NavyPrimary`}>
                    Emisora
                </div>
                <div className={`col s1 center-align bgGray ft-12 NavyPrimary`}>
                    Serie
                </div>
                <div className={`col s1 center-align bgGray ft-12 NavyPrimary`}>
                    Precio
                </div>

                <div className={`col s2 center-align bgGray ft-12 NavyPrimary`}>
                    Precio anterior
                </div>

                <div className={`col s2 center-align bgGray ft-12 NavyPrimary`}>
                    Variación
                </div>

                <div className={`col s2 center-align bgGray ft-12 NavyPrimary`}>
                    %
                </div>

                <div className={`col s2 center-align bgGray ft-12 NavyPrimary`}>
                    Fecha
                </div>

                <div className={`col s1 center-align bgGray ft-12 NavyPrimary`}>
                    Hora
                </div>
                {
                    acciones.map((v, i) => {
                        let isPar = i % 2 === 0 ? "" : "bgGray";
                        let isUp = parseFloat(v.variacion_porcentual) > 0 ? "greenTxt" : 'redTxt';
                        return (
                            <React.Fragment key={i}>
                                <div className={`col s1 left-align borderRight ft-12 ${isPar}`}>
                                    {v.emisora || ""}
                                </div>
                                <div className={`col s1 right-align borderRight ft-12 ${isPar} ${isUp} `}>
                                    {v.serie || ""}
                                </div>
                                <div className={`col s1 right-align borderRight  ft-12 ${isPar} ${isUp} `}>
                                    {v.valor || ""}
                                </div>
                                <div className={`col s2 right-align borderRight  ft-12 ${isPar} ${isUp} `}>
                                    {v.valor_anterior || ""}
                                </div>

                                <div className={`col s2 right-align borderRight ft-12 ${isPar} ${isUp} `}>
                                    {v.variacion_unitaria || ""}
                                </div>

                                <div className={`col s2 right-align borderRight ft-12 ${isPar} ${isUp} `}>
                                    {` ${v.variacion_porcentual || ""}%`}
                                    <i className="material-icons right divisaUpDown">
                                        {
                                            isUp === "greenTxt" ? "arrow_upward" : "arrow_downward"
                                        }
                                    </i>
                                </div>

                                <div className={`col s2 right-align ${isPar} ft-12 borderRight `}>
                                    {v.fecha || ""}
                                </div>

                                <div className={`col s1 right-align ${isPar} ft-12`}>
                                    {v.hora || ""}
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </React.Fragment>
        )

    }

    initSelect = () => {
        M.FormSelect.init(document.querySelectorAll('select'), {});
    }

}

export default DivisasIndicesAcciones;

