import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideOperacion } from '../redux/actions';

class ContactoValmex extends Component {


    state = {
        muestraTelAsesor: false,
        muestraTelCat: false,
    }


    componentDidMount() {


    }

    render() {
        const {muestraTelAsesor, muestraTelCat} = this.state;

        return (
            <div className="row">
                <div className='col s12 center-align ft-24 NavyPrimary mt-6'>
                    {this.props.currentContrato ? this.props.currentContrato.NombreProm : ""}
                </div>


                <div className='col s12 mt-3'>
                    <div className='col s6 right-align'>
                        <a className="waves-effect btn-flat flatPrimary w-159p"
                            href={this.props.currentContrato ?
                                `mailto:${this.props.currentContrato.MailProm}`
                                : ""}
                        >Escribir Correo</a>
                    </div>
                    <div className='col s6 left-align'>
                        <button className="btn primaryBtn_oc ml-10 w-159p"
                            onClick={() => {
                                let { muestraTelAsesor } = this.state;
                                this.setState({ muestraTelAsesor: !muestraTelAsesor })
                            }}
                        >
                            {
                                muestraTelAsesor ?
                                    this.props.currentContrato ?
                                        `${this.props.currentContrato.TelProm}`
                                        : ""
                                    : "Teléfono"
                            }
                        </button>
                    </div>

                </div>

                <div className='col s12 center-align ft-16 NavyPrimary mt-3'>
                    Centro de Atención Telefónica
                </div>
                <div className='col s12 center-align mt-3'>
                    <button className="waves-effect btn-flat flatPrimary w-292p"
                        onClick={() => {
                            let { muestraTelCat } = this.state;
                            this.setState({ muestraTelCat: !muestraTelCat })

                        }}

                    >{muestraTelCat ?

                        `${this.props.dataUser.TelCat}` : `Teléfono CAT`}</button>
                </div>

             
            </div>

        )
    }

}

const mapStateToProps = state => {
    let { currentContrato, dataUser
    } = false;

    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
    }

    return { currentContrato, dataUser };
};

export default connect(mapStateToProps, { hideOperacion })(ContactoValmex)