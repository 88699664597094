import React, { Component } from 'react';
import { ICONS } from '../assets/icons';
import { connect } from 'react-redux';
import { hideOperacion } from '../redux/actions';

class SuccessActivacion extends Component {


    render() {

        return (
            <div className='col row'>
                <div className='col s12 center-align mt-4'>
                    <img src={ICONS.OK_SUCCESS} alt='Activación Exitosta' />
                </div>
                <div className='col s12 center-align'>
                    <p className='ft-24 NavyPrimary'>
                        ¡Excelente!
                    </p>
                    <p className='ft-16 NavyPrimary'>
                        Activaste correctamente tu
                        contraseña, ahora puedes
                        ingresar al portal Valmex Web.
                    </p>
                    <p className='ft-16 NavyPrimary'>
                        Te recordamos que tu cuenta
                        es únicamente para consulta.
                    </p>
                </div>
                <div className='col s12 center-align mt-1'>
                    <button className="btn primaryBtn"
                    onClick={()=>{
                        this.props.hideOperacion();
                    }}
                    >Continuar</button>
                </div>
            </div>
        )
    }

}


export default connect(null, {hideOperacion })(SuccessActivacion)
