import {OK_BUTTON,SHOW_TOKEN,HIDE_TOKEN, HIDE_OK_BUTTON, SET_MENU_PERFIL, 
    SET_MENU_PRINCIPAL, SET_HISTORY, SET_COMPOSICION,HIDE_COMPOSICION,
    SET_OPERACION, HIDE_OPERACION, SET_FINGERPRINT,SET_COMPOSICION_SALDO_LIQUIDO,
    SET_COORDENADAS, SET_IP, SET_OPTION_SELECTED_MENU, CARGA_INFO,
    SERVICIOS_DISPONIBLES, RECARGA_CARTERA, SET_TIPOS_SERVICIOS,
    SET_SALDO_TOTAL, SET_RENDIMIENTOS, SET_POSICIONCARTERA, SET_SALDO_LIQUIDO,
    SET_TOKEN_TO_PASS, SET_SALDO_OPERACION, CONTRATO_A_REMOVER, SET_AVATAR, MODAL_INFO, MODAL_FCHNAC
} from './actionTypes';


export const okButton = message => ({
    type: OK_BUTTON,
    payload:{
        message:message.message,
        actionClick: message.actionClick || false,
        txtButton: message.txtButton || false,
        title: message.title || false, 
        statusCode: message.statusCode || false
    }
});

export const hide_okButton = message => ({
    type: HIDE_OK_BUTTON,
    payload:{}
});


export const showToken = getValidation => ({
    type: SHOW_TOKEN,
    payload: getValidation
});

export const hideToken = _ => ({
    type: HIDE_TOKEN,
    payload:{}
});


export const setMenuPerfil = menuPerfil => ({
    type: SET_MENU_PERFIL,
    payload: menuPerfil
});


export const setMenuPrincipal = menuPrincipal => ({
    type: SET_MENU_PRINCIPAL,
    payload: menuPrincipal
})

export const setHistory = history =>({
    type: SET_HISTORY,
    payload: history
});

export const setComposicion = composicion => ({
    type: SET_COMPOSICION,
    payload: composicion
});

export const setComposicionSaldoLiquido = composicionSL => ({
    type: SET_COMPOSICION_SALDO_LIQUIDO,
    payload: composicionSL
});

export const hideComposicion = _ => ({
    type: HIDE_COMPOSICION,
    payload:{}
});

export const setOperacion = operacion => ({
    type: SET_OPERACION,
    payload: operacion
});

export const hideOperacion = _ => ({
    type: HIDE_OPERACION,
    payload:{}
});

export const setFingerPrint = fingerPrint => ({
    type: SET_FINGERPRINT,
    payload:fingerPrint
})

export const setCoordenadas = coordenadas => ({
    type: SET_COORDENADAS,
    payload: coordenadas
})

export const setIP = ip => ({
    type: SET_IP,
    payload: ip
})

export const setOptionSelectedMenu = optionSelected => ({
    type: SET_OPTION_SELECTED_MENU,
    payload: optionSelected
})

export const cargaInfo =  cargaInfo => ({
    type: CARGA_INFO,
    payload: cargaInfo
})


export const serviciosNoDisponibles =  sDisponibles => ({
    type: SERVICIOS_DISPONIBLES,
    payload: sDisponibles
})


export const recargaCartera = esRecargaCartera => ({
    type: RECARGA_CARTERA,
    payload: esRecargaCartera
})

export const setTiposServicios = tiposServicios => ({
    type: SET_TIPOS_SERVICIOS,
    payload: tiposServicios
});

export const setSaldoTotal = saldoTotal => ({
    type: SET_SALDO_TOTAL,
    payload: saldoTotal
});

export const setSaldoOperacion = saldoOperacion => ({
    type: SET_SALDO_OPERACION,
    payload: saldoOperacion
});


export const setRendimientos = rendimientos =>({
    type: SET_RENDIMIENTOS,
    payload: rendimientos
})

export const setPosicionCartera = posicionCartera => ({
    type: SET_POSICIONCARTERA,
    payload: posicionCartera
});

export const setSaldoLiquido = saldoLiquido => ({
    type: SET_SALDO_LIQUIDO,
    payload: saldoLiquido
});


export const setTokenToPass = tokentoPass =>({
    type:  SET_TOKEN_TO_PASS,
    payload: tokentoPass
})

export const setContratoARemover = contratoARemover => ({
    type: CONTRATO_A_REMOVER,
    payload: contratoARemover
});

export const setAvatar = userAvatar =>({
    type: SET_AVATAR,
    payload: userAvatar
})

export const setModalInfo = infoModalData =>({
    type: MODAL_INFO,
    payload: infoModalData
})

export const showModalFchNac = infoModalData =>({
    type: MODAL_FCHNAC,
    payload: infoModalData
})