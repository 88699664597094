import React,{Component} from 'react';


class Reload extends Component{

    state={
        spin:true
    }
    
    render(){
        let {spin} = this.state;
        let {action} = this.props;

        if(!action){
            return null;
        }
        return(
        <div className="center-align loader">
            <p><i className={`material-icons ${spin ? `icn-spinner` : ""}`}
            onClick={
                ()=>{
              
                    this.setState({spin:!spin},async () =>{
                        await action();
                    });
                }
            }
            >autorenew</i></p>
            <p>{spin ? `Cargando...`: `Recargar`}</p>
        </div>
        )
    }


}

export default Reload;