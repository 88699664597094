import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase';
import { hideOperacion } from '../redux/actions';

class BitacoraInfo extends Component {



    state = {

    }


    componentDidMount() {
      
        let {latitud, longitud} = this.props.data;

        var mymap = window.L.map('mapid').setView([latitud,longitud], 17);
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '',
            maxZoom: 18,
            tileSize: 512,
            zoomOffset: -1
        }).addTo(mymap);

         window.L.marker([latitud,longitud]).addTo(mymap);
    }

    render() {
        let {ciudad, date, descripcion, direccion_ip, 
            estado, plataforma} = this.props.data;
            
            let dateDbi =
            moment(new firebase.firestore.Timestamp(date.seconds, date.nanoseconds).toDate())
                .format('DD/MM/YYYY HH:mm:ss');
        return (
            <div className="row">
                <div className="col s12 mt-1 ft-14 boldText">
                    {descripcion}
                </div>
                <div className="col s12 optOperacionTxt ft-12" >
                    {dateDbi}
                </div>
                <div className="col s12 optOperacionTxt ft-12">
                    {plataforma} - {direccion_ip}
                </div>
                <div className="col s12 optOperacionTxt ft-12">
                    {ciudad}, {estado}
                </div>
                <div className="col s12 mt-1">
                    <div id="mapid" className="mapaClass"></div>
                </div>

                <div className="col s12 center-align mt-2">
                <button className="btn primaryBtn waves-effect" type="button" onClick={() => {
                        this.props.hideOperacion();
                    }}>
                       Entendido
                    </button>
                </div>
            </div>

        )
    }

}

export default connect(null, { hideOperacion })(BitacoraInfo)