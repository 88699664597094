import React from 'react';
import { connect } from 'react-redux';
import { ICONS } from '../assets/icons';

const OK_BUTTON_MODAL = ({ ok_btn_message, ok_btn_action, txtButton, title, statusCode }) => (
    <div id="modal_okButton" className="modal modal_okButton modal-fixed-footer">

        <div className="modal-content center-align">
            <div className="row">
                <div className="col s12">
                    {title ? <h4>{title}</h4> : null}
                    {
                        statusCode && statusCode !== 200 ?

                            <p><img src={ICONS.NO_SERVICES} alt="Servicios no disponibles" /></p>
                            :
                            <p><img src={ICONS.SUCCESS} className="responsive-img" alt="Operación Exitosa" /></p>
                    }
                    <p>{ok_btn_message} </p>
                </div>
                <div className="col s12 mt-1 center-align">
                    {
                        ok_btn_action ?
                            <button className="btn primaryBtn waves-effect modal-close" type="button" onClick={() => {
                                ok_btn_action();
                            }}>
                                {txtButton ? txtButton : "Ok"}
                            </button>
                            : <button className="modal-close waves-effect btn primaryBtn">{txtButton ? txtButton : "Entendido"}</button>
                    }
                </div>
            </div>

        </div>
        <div className="modal-footer">
            <div className="closeModalOk left-align">
                <i className="material-icons cursorPointer modal-close">close</i>
            </div>
        </div>
    </div>
);


const mapStateToProps = state => {
    let { ok_btn_message, ok_btn_action, txtButton, title, statusCode } = false;
    if (state.commonsActions) {

        ok_btn_message = state.commonsActions.ok_btn_message;
        ok_btn_action = state.commonsActions.ok_btn_action || false;
        txtButton = state.commonsActions.txtButton || false;
        title = state.commonsActions.title || false;
        statusCode = state.commonsActions.statusCode || false;
    }

    return { ok_btn_message, ok_btn_action, txtButton, title, statusCode };
};

export default connect(mapStateToProps)(OK_BUTTON_MODAL);