import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICONS } from '../assets/icons';
import M from 'materialize-css';
import {
    setMenuPerfil, setOperacion, setOptionSelectedMenu,
    setSaldoTotal, setRendimientos, setTiposServicios, setPosicionCartera, recargaCartera,
    setTokenToPass, okButton, setContratoARemover, setAvatar, setModalInfo
} from '../redux/actions';
import { setCurrentContrato, setDataUser, setContratos, setChequera } from '../redux/actionsServices';
import { MENUS, PATHS, TIPOS_CHEQUERAS } from '../properties'
import firebase from 'firebase';
import Reload from '../components/reload';
import { deleteSesion, addSuscribe, getDownloadUrl } from '../utils';
import moment from 'moment';
import galaxia from '../assets/fondos/galaxia.png';
import cumple from '../assets/fondos/cumple.png';
import mexico from '../assets/fondos/mexico.png';
import navidad from '../assets/fondos/navidad.png';

class Perfil extends Component {

    state = {
        showMenu: true,
        perfilInversionSelected: false,
        servicioInversionSelected: false,
        perfiles: [],
        servicios: [],
        contrato: false,
        muestraTelAsesor: false,
        muestraTelCat: false,
        dataUserConfig: false,
        bitacora: false,
        ultimoInicio: false,
        fondos: [galaxia, cumple, mexico, navidad],
        userAvatar: false
    }

    getRandomFoto() {
        let randomIndex = Math.floor(Math.random() * this.state.fondos.length);

        return randomIndex;
    }

    async componentDidUpdate(prevProps, prevState) {

        if (prevProps.optionSelected !== this.props.optionSelected) {
            if (this.props.optionSelected === false) {
                this.setState({ showMenu: true, muestraTelAsesor: false, muestraTelCat: false });
            }
        }

        if (this.props.currentContrato && this.props.currentContrato.Contrato && this.props.dataUser) {

            if (this.state.contrato !== this.props.currentContrato.Contrato) {

                let instance = M.Sidenav.getInstance(document.querySelectorAll('.sidenavUser')[0]);
                if (!instance) {
                    M.Sidenav.init(document.querySelectorAll('.sidenavUser'), {
                        edge: "right",
                        onCloseEnd: () => {

                        }
                    });
                }

                this.setState({ contrato: this.props.currentContrato.Contrato }, () => {
                    this.getConfig();
                    this.getAvatar();
                    this.getBitacora();
                    this.getMenu();
                    this.getPerfiles();
                    this.getBitacoraListener();
                })


            }
        }

    }

    getMenu = () => {

        try {
            let refSnap = firebase.firestore().collection(MENUS.USUARIO).orderBy('posicion', "asc").onSnapshot((doc) => {
                if (doc.docs.length) {
                    this.props.setMenuPerfil(doc.docs);
                }
            });

            addSuscribe(refSnap);
        } catch (error) {

        }
    }

    getBitacora = async () => {
        try {
            firebase.firestore().collection(`/usuarios/${this.props.dataUser.IdUsuario}/bitacora`).orderBy('date', 'desc').get().then((sna) => {

                if (!sna.empty) {

                    let ultimoInicio = sna.docs[0].data();


                    const fechaActual = new Date();

                    // Restar 30 días
                    const fechaInicio = new Date();
                    fechaInicio.setDate(fechaActual.getDate() - 30);

                    // Ajustar horas, minutos, segundos y milisegundos a 0:00
                    fechaInicio.setHours(0, 0, 0, 0);

                    // Fecha fin es la fecha actual ajustada a 0:00
                    const fechaFin = new Date();
                    fechaFin.setHours(0, 0, 0, 0);

                    let bitacoraS = [];

                    for (let snapb of sna.docs) {
                        let dataB = snapb.data();
                        const timestampFechaB = new firebase.firestore.Timestamp(dataB.date.seconds, dataB.date.nanoseconds).toDate();

                        if (timestampFechaB >= fechaInicio.getTime()) {
                            bitacoraS.push(dataB);
                        }
                    }

                    this.setState({
                        ultimoInicio,
                        bitacora: bitacoraS
                    });
                }
            }).catch((er) => {

            })


        } catch (error) {

        }
    }

    getBitacoraListener = async () => {
        try {
            window.unsubscribe = firebase.firestore().collection(`/usuarios/${this.props.dataUser.IdUsuario}/bitacora`).orderBy('date', 'desc')
                .limit(1).onSnapshot((sna) => {

                    if (!sna.empty) {

                        let ultimoInicio = sna.docs[0].data();

                        let tieneOtraSesion = false;

                        if (!ultimoInicio.descripcion.toUpperCase().includes("WEB")) {
                            tieneOtraSesion = true;

                        } else {
                            let fechaFirebase = moment(ultimoInicio.date.seconds * 1000);
                            //obtiene la fecha de la ultima sesion
                            let fechaSesion = moment(this.state.ultimoInicio.date.seconds * 1000);
                            //compara las fechas
                            let diferencia = fechaFirebase.diff(fechaSesion, 'seconds');
                            if (diferencia > 0) {
                                tieneOtraSesion = true;
                            }
                        }

                        if (tieneOtraSesion) {
                            deleteSesion(this.props.dataUser.IdUsuario, this.props);
                            this.props.okButton({
                                message: "Apreciable cliente, ha iniciado sesión en otro dispositivo, por su seguridad su sesión ha caducado.", title: "Fin de Sesión", txtButton: "Entendido",
                                statusCode: 404
                            });
                        }

                    }
                });

            // .get().then((sna) => {

            //     if (!sna.empty) {

            //         let ultimoInicio = sna.docs[0].data();

            //         this.setState({
            //             ultimoInicio,
            //             bitacora: sna.docs
            //         });
            //     }
            // }).catch((er) => {

            // })


        } catch (error) {

        }
    }

    getConfig = () => {
        try {
            firebase.firestore().doc(`/usuarios/${this.props.dataUser.IdUsuario}/configuraciones/usuario`).get().then((sna) => {
                if (sna.exists) {
                    let dataUserConfig = sna.data();
                    this.setState({ dataUserConfig });
                } else {
                    this.setState({ dataUserConfig: { mostrar_saldo: false, mostrar_mapa: false } });
                }
            }).catch((err) => {

            })
        } catch (error) {

        }
    }

    getAvatar = () => {
        try {
            firebase.firestore().doc(`/usuarios/${this.props.dataUser.IdUsuario}/configuraciones/avatar`).get().then(async (sna) => {

                if (sna.exists) {
                    let userAvatar = sna.data();

                    if (userAvatar.tapiz && userAvatar.tapiz.fullPath && userAvatar.tapiz.fullPath.trim() !== "") {
                        const urlTapiz = await getDownloadUrl(userAvatar.tapiz.fullPath);

                        if (urlTapiz) {
                            userAvatar.fontoTapiz = urlTapiz;
                        }
                    }

                    this.setState({ userAvatar }, () => {
                        this.props.setAvatar(userAvatar);
                    });
                } else {
                    this.setState({ userAvatar: false });
                }
            }).catch((err) => {

            })
        } catch (error) {

        }
    }

    updateMuestraSaldo = (value) => {
        try {
            firebase.firestore().doc(`/usuarios/${this.props.dataUser.IdUsuario}/configuraciones/usuario`).set({ mostrar_saldo: value }, { merge: true });
        } catch (error) {

        }
    }

    updateMuestraMapa = (value) => {
        try {
            firebase.firestore().doc(`/usuarios/${this.props.dataUser.IdUsuario}/configuraciones/usuario`).set({ mostrar_mapa: value }, { merge: true });
        } catch (error) {

        }
    }


    getPerfiles = () => {
        try {
            firebase.firestore().collection('configuraciones/inversion/perfiles').get().then((info) => {
                if (info.docs.length) {

                    let perfiles = [];
                    for (let doc of info.docs) {
                        let dataDoc = doc.data();
                        perfiles.push(dataDoc);
                    }
                    this.setState({ perfiles });
                }

            }).catch((er) => {

            });
        } catch (error) {

        }
    }

    render() {
        let { showMenu, ultimoInicio, userAvatar } = this.state;
        let { optionSelected } = this.props;

        let styleAvatar = {};

        if (userAvatar && userAvatar.fontoTapiz) {
            styleAvatar = {
                backgroundImage: `url("${userAvatar.fontoTapiz}")`,
                backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"
            }
        }

        return (

            <ul id="slide-menu-user" className="sidenav sidenavUser">
                <li className={`${Object.keys(styleAvatar).length ? '' : 'bgPerfil'}`} style={styleAvatar}>
                    <div className="user-view center-align">
                        {
                            userAvatar && userAvatar.avatar ?
                                <a href="#user"><img className="circle avatarN avatarPerfilN" src={`data:image/png;base64,${userAvatar.avatar}`} alt="avatar" /></a>
                                :
                                <a href="#user"><img className="circle avatar avatarPerfil" src={ICONS.AVATAR} alt="avatar" /></a>
                        }

                        <button className='btn btnscs'
                            onClick={(event) => {
                                deleteSesion(this.props.dataUser.IdUsuario, this.props,
                                    () => {
                                        this.props.setModalInfo({
                                            title: "",
                                            text: `La sesión ha sido cerrada exitosamente.`,
                                            text2: `¡Lo esperamos pronto!`,
                                            txtButton: 'Aceptar'
                                        })
                                    }

                                );


                            }}
                        >Cerrar sesión</button>
                    </div>
                </li>
                {
                    optionSelected ?

                        <li className="bgOption mtless-8">
                            <a href="!#" className="txtOption"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.setOptionSelectedMenu(false);
                                    this.setState({
                                        showMenu: true,
                                        perfilInversionSelected: false,
                                        servicioInversionSelected: false
                                    });
                                }}>
                                <i className="material-icons left txtOption"

                                >
                                    chevron_left
                                </i> {optionSelected.id === "perfil_inversion" ?
                                    <span style={{ fontSize: "17px" }}>Categoría de Perfil de Inversión</span>
                                    : optionSelected.titulo}
                            </a>
                        </li>

                        : null
                }
                {
                    showMenu ?
                        <React.Fragment>
                            <li className="center-align mt-20">
                                <h5 className="brandTextPrimary sName">¡Hola! {this.nameFromProps()} </h5>
                                {
                                    this.props.currentContrato ?
                                        <p className="noMargin contratoSelect">Contrato: {this.props.currentContrato.Contrato}</p>
                                        : null
                                }
                            </li>
                            <li className="left-align optSide">
                                <div><img src={ICONS.SMARTHPHONE} className="customIcon left" alt="icon telefono"></img> {this.props.dataUser.Celular}</div>
                                <div className="mtless-10 mb-10"><img src={ICONS.MAIL} className="customIcon left" alt="icon main"></img> {this.props.dataUser.IdUsuario}</div>
                            </li>


                            {
                                this.props.menuPerfil ?
                                    this.props.menuPerfil.map((v, i) => {
                                        let data = v.data();
                                        return (
                                            <li className="optSide" key={i} id={data.id}
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    if (data.titulo.trim() === 'Contacto Valmex') {
                                                        this.props.setOperacion({
                                                            title: `Contacto Valmex`,
                                                            opt: "contactoValmex"
                                                        })
                                                    } else {
                                                        this.setState({ showMenu: false }, () => {
                                                            this.props.setOptionSelectedMenu(data);
                                                        });
                                                        if (data.tag) {
                                                            firebase.analytics().logEvent(data.tag);
                                                        }
                                                    }

                                                }}>
                                                <a href="!#"><img src={ICONS[data.icon]} className="customIcon left" alt={`icon ${data.icon}`}></img>{data.titulo} <i className="material-icons right">keyboard_arrow_right</i></a></li>
                                        )
                                    })
                                    : <Reload action={this.getMenu} />
                            }

                            <li className="optSide sidenav-close">
                                <a href="!#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        deleteSesion(this.props.dataUser.IdUsuario, this.props);

                                    }}
                                ><img src={ICONS.SESION} className="customIcon left" alt="icon sesion"></img>Cerrar Sesión <i className="material-icons right">keyboard_arrow_right</i></a></li>
                            {
                                ultimoInicio ?
                                    <li className="contratos-ln">
                                        <div className="row">
                                            <div className="col s12 ft-12">
                                                <p>Último ingreso</p>
                                                <p>{moment(new firebase.firestore.Timestamp(ultimoInicio.date.seconds, ultimoInicio.date.nanoseconds).toDate())
                                                    .format('DD/MM/YYYY HH:mm:ss')}</p>

                                            </div>
                                        </div>
                                    </li>
                                    : null
                            }

                        </React.Fragment>
                        : null
                }

                {
                    optionSelected ?
                        this.showOption()
                        : null
                }

            </ul>
        )
    }

    nameFromProps = () => {
        if (this.props.dataUser) {
            let { Nombre, ApMaterno, ApPaterno } = this.props.dataUser;
            return `${Nombre ? Nombre : ''} ${ApPaterno ? ApPaterno : ''} ${ApMaterno ? ApMaterno : ''}`;
        }

        return "";
    }

    onChangeValueCheck = (event) => {
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let { dataUserConfig } = this.state;

        if (target.id === "muestraSaldo") {
            dataUserConfig.mostrar_saldo = value;
        }

        if (target.id === "muestraMapa") {
            dataUserConfig.mostrar_mapa = value;
        }



        this.setState({ dataUserConfig }, () => {
            if (target.id === "muestraSaldo") {
                this.updateMuestraSaldo(value);
            } else {
                this.updateMuestraMapa(value);
            }

        });
    }

    showOption = () => {
        let { perfilInversionSelected, servicioInversionSelected,
            perfiles, muestraTelAsesor, muestraTelCat, dataUserConfig, bitacora
        } = this.state;
        let toShow = null;
        let { optionSelected } = this.props;
        if (optionSelected) {

            switch (optionSelected.id) {
                case 'contratos':
                    if (this.props.contratosUser) {
                        toShow = <React.Fragment>
                            <li>
                                <span className="NavyPrimary ml-14">Selecciona tu contrato</span>
                            </li>
                            <li className="contratosContainer">
                                {
                                    this.props.contratosUser.TTCONTRATOS['TT-CONTRATOS'].map((v, i) => {
                                        return (
                                            <div className={`row flex cursorPointer ${this.props.currentContrato && this.props.currentContrato.Contrato === v.Contrato
                                                ? `contratoSelected` : 'contratoNoSelected'}`}

                                            >
                                                <div className="col s10"
                                                    onClick={() => {

                                                        let currentContrato = this.props.currentContrato.Contrato;

                                                        this.props.setCurrentContrato(v);
                                                        this.props.setOptionSelectedMenu(false);
                                                        this.setState({ showMenu: true }, () => {
                                                            if (v.Contrato.trim() !== currentContrato) {
                                                                this.props.recargaCartera(true);
                                                                this.props.history.push(PATHS.INICIO);
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <p className="noMargin ln-h30">Creado el {v.FechaAlta}</p>
                                                    <p className="noMargin ln-h30">No. de contrato: {v.Contrato} </p>

                                                </div>
                                                <div className="col s2 valign-wrapper">
                                                    {
                                                        this.props.currentContrato && this.props.currentContrato.Contrato === v.Contrato ?
                                                            <i className="material-icons NavyPrimary">check_circle</i>
                                                            : null
                                                    }

                                                </div>
                                                <div className="col s12">
                                                    {/* <p className="noMargin ln-h30">

                                                        <a href="#remueveContratoModal"
                                                            onClick={() => {
                                                                this.props.setContratoARemover(v.Contrato.trim());
                                                            }}
                                                            className="modal-trigger" >Remover contrato</a>
                                                    </p> */}
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </li>
                        </React.Fragment>
                    }
                    break;
                case 'contacto_valmex':
                    toShow = <React.Fragment>
                        <li>
                            <span className="NavyPrimary ml-14">Asesor</span>
                        </li>
                        <li>
                            <div className="row grayBorder contactoContainer">
                                <div className="col s2">
                                    <img src={ICONS.AVATAR} alt="avatar" className="circle responsive-img" />
                                </div>
                                <div className="col s10">
                                    <p className="black-text descPerfil">
                                        {this.props.currentContrato ? this.props.currentContrato.NombreProm : ""}
                                    </p>
                                </div>
                                <div className="col s12 center-align">
                                    <a className="waves-effect btn-flat flatPrimary"
                                        href={this.props.currentContrato ?
                                            `mailto:${this.props.currentContrato.MailProm}`
                                            : ""}
                                    >Escribir Correo</a>
                                    <button className="btn primaryBtn_oc ml-10"
                                        onClick={() => {
                                            let { muestraTelAsesor } = this.state;
                                            this.setState({ muestraTelAsesor: !muestraTelAsesor })
                                        }}
                                    >
                                        {
                                            muestraTelAsesor ?
                                                this.props.currentContrato ?
                                                    `${this.props.currentContrato.TelProm}`
                                                    : ""
                                                : "Teléfono"
                                        }
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <span className="NavyPrimary ml-14">Centro de Atención Telefónica</span>
                        </li>
                        <li className="center-align">
                            <button className="waves-effect btn-flat flatPrimary w-90"
                                onClick={() => {
                                    let { muestraTelCat } = this.state;
                                    this.setState({ muestraTelCat: !muestraTelCat })

                                }}

                            >{muestraTelCat ?

                                `${this.props.dataUser.TelCat}` : `Teléfono Cat`}</button>
                        </li>
                    </React.Fragment>

                    break;
                case 'cuentas_registradas':
                    toShow = <React.Fragment>
                        <li>
                            <div className="row">
                                <div className="col s12">
                                    <p>No. de contrato: {this.props.currentContrato.Contrato}</p>
                                    <p>Chequeras para Depósito</p>
                                </div>
                                {
                                    this.getTipoChequera(TIPOS_CHEQUERAS.DEPOSITO)
                                }
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <p>Chequeras para Retiro</p>
                                </div>
                                {
                                    this.getTipoChequera(TIPOS_CHEQUERAS.RETIRO)
                                }
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <p>Otras Chequeras</p>
                                </div>
                                {
                                    this.getTipoChequera(TIPOS_CHEQUERAS.INFORMATIVA)
                                }
                            </div>
                        </li>
                    </React.Fragment>
                    break;
                case 'servicios_inversion':
                    toShow = <React.Fragment>
                        <li>
                            <div className="row">
                                <div className="col s12 mt-1">
                                    Lista de servicios contratados.
                                </div>
                                <div className="col s12 paddingCero mt-1">
                                    {
                                        this.props.tiposServicios ?
                                            <ul className="collapsible expandable">
                                                {
                                                    this.props.tiposServicios.map((v, i) => {

                                                        return (
                                                            <li key={i} id={v.id}
                                                                className={`${v.NoEsUsuario ? 'noEventPointer' : 'active noEventPointer'}`}
                                                                onClick={() => {
                                                                    this.setState({ servicioInversionSelected: v.id });
                                                                }}
                                                            >
                                                                <div
                                                                    className={`${v.NoEsUsuario ?
                                                                        'collapsible-header white-text disableItemList cpadding' :
                                                                        'collapsible-header white-text bgSky cpadding'}`}
                                                                >
                                                                    <i className="material-icons optCollPer noMarginR">
                                                                        {
                                                                            servicioInversionSelected === v.id || !v.NoEsUsuario ?

                                                                                "keyboard_arrow_up"

                                                                                : "keyboard_arrow_down"
                                                                        }
                                                                    </i>{v.titulo}</div>
                                                                <div className="collapsible-body">
                                                                    <div className="row">
                                                                        <div className="col s12 pl-1">
                                                                            <p className="descPerfil">{v.descripcion}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            : null
                                    }

                                </div>
                            </div>
                        </li>
                    </React.Fragment>

                    setTimeout(() => {
                        M.Collapsible.init(document.querySelector('.collapsible.expandable'), {
                            accordion: false,
                            onOpenEnd: (el) => {

                                let { servicioInversionSelected } = this.state;

                                if (servicioInversionSelected === el.id) {
                                    servicioInversionSelected = false;
                                } else {
                                    servicioInversionSelected = el.id;
                                }

                                this.setState({ servicioInversionSelected: el.id });
                            },
                            onCloseEnd: (el) => {
                                this.setState({ servicioInversionSelected: false });
                            }
                        });
                        M.Collapsible.init(document.querySelectorAll('.collapsible'), {

                            onOpenEnd: (el) => {

                                let { servicioInversionSelected } = this.state;

                                if (servicioInversionSelected === el.id) {
                                    servicioInversionSelected = false;
                                } else {
                                    servicioInversionSelected = el.id;
                                }

                                this.setState({ servicioInversionSelected: el.id });
                            },
                            onCloseEnd: (el) => {
                                this.setState({ servicioInversionSelected: false });
                            }

                        });
                    }, 500);
                    break;
                case 'perfil_inversion':
                    toShow = <React.Fragment>
                        <li>
                            <div className="row">
                                <div className="col s12 mt-1">
                                    Lista de perfiles.
                                </div>
                                <div className="col s12 paddingCero mt-1">
                                    <ul className="collapsible">
                                        {
                                            perfiles.map((v, i) => {

                                                return (
                                                    <li key={i} id={v.id}
                                                        className={`${this.props.currentContrato.Perfil === v.id ? 'active' : 'noEventPointer'}`}

                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            if (this.props.currentContrato.Perfil === v.id) {
                                                                this.setState({ perfilInversionSelected: v.id });
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={`${this.props.currentContrato.Perfil === v.id ? 'collapsible-header  white-text bgSky cpadding' :
                                                                'collapsible-header  white-text disableItemList cpadding '}`}

                                                            disabled={this.props.currentContrato.Perfil !== v.id ? true : false}
                                                        >
                                                            <i className="material-icons optCollPer noMarginR">
                                                                {
                                                                    perfilInversionSelected === v.id ?

                                                                        "keyboard_arrow_up"

                                                                        : "keyboard_arrow_down"
                                                                }
                                                            </i>{`${i + 1} ${v.titulo}`}</div>
                                                        <div className="collapsible-body">
                                                            <div className="row">
                                                                <div className="col s12 pl-1">
                                                                    <p className="descPerfil">{
                                                                        this.props.currentContrato.Perfil === v.id ?

                                                                            v.descripcion : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>

                                </div>
                                <div className='col s12 ft-12 left-align mt-1' style={{ lineHeight: "1.5" }}>
                                    La Guía de Servicios de Inversión se encuentra a su
                                    disposición en la página de internet <a href="https://www.valmex.com.mx" target={"_blank"}>https://www.valmex.com.mx</a>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>

                    setTimeout(() => {
                        M.Collapsible.init(document.querySelectorAll('.collapsible'), {
                            onOpenEnd: (el) => {

                                let { perfilInversionSelected } = this.state;

                                if (perfilInversionSelected === el.id) {
                                    perfilInversionSelected = false;
                                } else {
                                    perfilInversionSelected = el.id;
                                }

                                this.setState({ perfilInversionSelected: el.id });
                            },
                            onCloseEnd: (el) => {
                                this.setState({ perfilInversionSelected: false });
                            }

                        });
                    }, 500);
                    break;
                case 'seguridad':
                    toShow = <React.Fragment>
                        <li>
                            <div className="row mb-0px">
                                <div className="col s12 mt-1">
                                    Contraseña
                                </div>
                                <div className="col s12 center-align">
                                    <button className="btn sidenav-close white-text waves-effect primaryBtn_oc"
                                        onClick={() => {
                                            firebase.analytics().logEvent('Perfil_cambio_contrasenia');
                                            this.props.setTokenToPass(Math.random().toString(36).substring(7));
                                            this.props.history.push(`${PATHS.RESET_PASS_REDIRECT}${this.props.dataUser.IdUsuario}`);
                                        }}
                                    >Cambiar Contraseña</button>
                                </div>
                                <div className="col s12 mt-1 paddingLeftCero">
                                    <span className="ml-1-5">Permisos</span>
                                    <div className="divider"></div>
                                </div>
                                {/* <div className="col s12 switch mt-1">
                                    Activar ingreso biométrico
                                    <label className="floatRight">
                                        <input type="checkbox"
                                            id="ingresoBiometrico" />
                                        <span className="lever"></span>
                                    </label>
                                    <div className="divider mt-1" />
                                </div> */}
                                {
                                    dataUserConfig ?
                                        <React.Fragment>
                                            <div className="col s12 switch mt-1 paddingLeftCero">
                                                <span className="ml-1-5">Mostrar saldo</span>
                                                <label className="floatRight">
                                                    <input type="checkbox"
                                                        onChange={this.onChangeValueCheck}
                                                        checked={dataUserConfig.mostrar_saldo}
                                                        value={dataUserConfig.mostrar_saldo}
                                                        id="muestraSaldo" />
                                                    <span className="lever"></span>
                                                </label>
                                                <div className="divider mt-1" />
                                            </div>
                                            <div className="col s12 switch mt-1 paddingLeftCero">
                                                <span className="ml-1-5">Mostrar mapa</span>
                                                <label className="floatRight">
                                                    <input type="checkbox"
                                                        onChange={this.onChangeValueCheck}
                                                        checked={dataUserConfig.mostrar_mapa}
                                                        value={dataUserConfig.mostrar_mapa}
                                                        id="muestraMapa" />
                                                    <span className="lever"></span>
                                                </label>
                                                <div className="divider mt-1" />
                                            </div>

                                        </React.Fragment>

                                        : null
                                }

                            </div>

                            <div className="row cursorPointer hoverContent borderInfoF"
                                onClick={() => {

                                    this.props.setOptionSelectedMenu({
                                        titulo: 'Bitácora de accesos',
                                        id: 'bitacora_accesos'
                                    });
                                }}>
                                <div className="col s10 left-align">
                                    <img src={ICONS.CONTACTS} className="customIcon left" alt="bitacora"></img>
                                    Bitácora de accesos
                                </div>
                                <div className="col s2 right-align">
                                    <i className="material-icons mt-1"
                                    >keyboard_arrow_right</i>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col s1'>
                                    <i className="material-icons left ">info_outline</i>
                                </div>
                                <div className='col s11 ft-10' style={{
                                    lineHeight: "1.5"
                                }}>
                                    <span>Consulta más recomendaciones de seguridad en <a href='https://www.valmex.com.mx' target='_blank' className='boldText black-text'>valmex.com.mx</a></span>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                    break;
                case 'legales':
                    toShow = <ul className="collection with-header">
                        <li className="collection-item cursorPointer hoverContent"
                            onClick={(event) => {
                                event.preventDefault();
                                firebase.analytics().logEvent('Perfil_terminos');
                                this.props.setOperacion({
                                    title: `Términos y Condiciones`,
                                    opt: "terminos_condiciones"
                                })
                            }}><div>Términos y Condiciones
                                <a href="#!" className="secondary-content">
                                    <i className="material-icons">keyboard_arrow_right</i>
                                </a>
                            </div></li>
                        <li className="collection-item cursorPointer hoverContent"
                            onClick={(event) => {
                                event.preventDefault();
                                firebase.analytics().logEvent('Perfil_aviso_privacidad');
                                this.props.setOperacion({
                                    title: `Aviso de privacidad`,
                                    opt: "aviso_privacidad"
                                })
                            }}><div>Aviso de privacidad
                                <a href="#!" className="secondary-content"

                                >
                                    <i className="material-icons">keyboard_arrow_right</i></a>
                            </div></li>
                        {/* <li className="collection-item"><div>Servicios de inversión
                            <a href="#!" className="secondary-content"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.props.setOperacion({
                                        title: `Servicios de inversión`,
                                        opt: "servicios_inversion"
                                    })
                                }}
                            >
                                <i className="material-icons">keyboard_arrow_right</i></a>
                        </div></li> */}
                    </ul>
                    break;
                case 'bitacora_accesos':
                    toShow = <React.Fragment>
                        <li>
                            <div className="row">
                                <div className="col s12">
                                    {
                                        bitacora.length ?
                                            <ul className="collapsible">
                                                {
                                                    bitacora.map((v, i) => {
                                                        let dbi = v; //v.data();
                                                        let dateDbi =
                                                            moment(new firebase.firestore.Timestamp(dbi.date.seconds, dbi.date.nanoseconds).toDate())
                                                                .format('DD/MM/YYYY HH:mm:ss');
                                                        return (
                                                            <li className={`ln-h20 ${dataUserConfig.mostrar_mapa ? 'cursorPointer' : ''}`}
                                                                onClick={() => {
                                                                    if (dataUserConfig.mostrar_mapa) {
                                                                        this.props.setOperacion({
                                                                            title: `Bitácora de accesos`,
                                                                            opt: "bitacoraInfo",
                                                                            data: dbi

                                                                        });
                                                                    }

                                                                }}
                                                            >
                                                                <div className="row ft-12 mb-0px hoverContent">
                                                                    <div className="col s6">
                                                                        {dbi.descripcion}
                                                                    </div>
                                                                    <div className="col s6">
                                                                        {dateDbi}
                                                                    </div>
                                                                    <div className="col s6 optOperacionTxt ft-10">
                                                                        {dbi.plataforma} - {dbi.direccion_ip}
                                                                    </div>
                                                                    <div className="col s6 optOperacionTxt ft-10">
                                                                        <i className="material-icons left "
                                                                            style={{ marginTop: '2px', fontSize: '17px' }}
                                                                        >place</i>{dbi.estado}
                                                                    </div>
                                                                    <div className="col s12 divider"></div>
                                                                </div>

                                                            </li>
                                                        )

                                                    })
                                                }

                                            </ul>
                                            :

                                            'No se cuenta con accesos registrados.'
                                    }
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                    break;
                default:
                    toShow = <div></div>
                    break;
            }
        }

        return toShow;
    }


    getTipoChequera = (tipo) => {

        if (this.props.chequera && this.props.chequera.length) {
            let filterChequera = this.props.chequera.filter((v, i) => {
                if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato) {
                    return v;
                }
            });

            if (filterChequera.length) {


                return (<div className="col s12">
                    <ul className="collection">
                        {
                            filterChequera.map((v, i) => {

                                return (
                                    <li className="collection-item row cursorPointer hoverContent" onClick={(event) => {
                                        event.preventDefault();
                                        this.props.setOperacion({
                                            title: `${v.DescBanco} - *****${`${v.Cuenta}`.substring(`${v.Cuenta}`.length - 4)}`,
                                            opt: "chequera",
                                            data: v
                                        })
                                    }}>
                                        <div className="col s10">
                                            <p style={{ marginTop: '0px', marginBottom: '1px' }}>
                                                <span className="title">{v.DescBanco} - *****{`${v.Cuenta}`.substring(`${v.Cuenta}`.length - 4)}</span>
                                            </p>
                                            <p style={{
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                                marginTop: '0px'
                                            }}>{v.Nombre.toLowerCase()} - {v.Tipo}</p>
                                        </div>
                                        <div className="col s2 center-align">
                                            <a href="#!"


                                            ><i className="material-icons">chevron_right</i></a>

                                        </div>

                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                )

            }



        }

        return <div></div>;

    }
}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, optionSelected
    } = false;

    let { tiposServicios } = [];
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        optionSelected = state.commonsActions.optionSelected || false;
        tiposServicios = state.commonsActions.tiposServicios || []
    }


    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history, optionSelected, tiposServicios };
};

export default connect(mapStateToProps,
    {
        setMenuPerfil,
        setCurrentContrato,
        setOperacion,
        setDataUser,
        setContratos,
        setChequera,
        setOptionSelectedMenu,
        setSaldoTotal,
        setRendimientos,
        setTiposServicios,
        setPosicionCartera,
        recargaCartera,
        setTokenToPass,
        okButton,
        setContratoARemover,
        setAvatar,
        setModalInfo
    })(Perfil);