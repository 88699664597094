import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TIPOS_CHEQUERAS, SERVICES, ACCION_CODIGO, TIPO_OPERACION } from '../../properties';
import { ICONS } from '../../assets/icons';
import { showToken, okButton, recargaCartera, setCoordenadas, setModalInfo } from '../../redux/actions';
import {
    makeCall, encryptValues, nWithCommas, getSaldoLiquido, getCoordenadas,
    shareDetail, getData_menuExterior
} from '../../utils';
import Reload from '../reload';
import M from 'materialize-css';
import ip from 'ip';
import firebase from 'firebase';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Deposito extends Component {

    state = {
        importe: "",
        cuentaSelected: false,
        loading: false,
        softToken: false,
        operacionExitosa: false,
        myIp: ip.address(),
        errorMsg: false,
        detail: {
            tipo: "Depósito de saldo liquido",
            fecha: '18/09/2021',
            hora: '14:44',
            fechaL: '18/09/2021',
            noContrato: "12345",
            folio: "1123",

        },
        concepto: '',
        dataMenuExterior: false
    }


    getDateMenuExterior = async () => {
        const dataMenuE = await getData_menuExterior();

        this.setState({ dataMenuExterior: dataMenuE });
    }



    async componentDidMount() {
        M.updateTextFields();
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});

        try {
            await getSaldoLiquido({
                dataUser: this.props.dataUser,
                currentContrato: this.props.currentContrato,
                TipoSaldo: "D"
            });
        } catch (error) {

        }

        await this.getDateMenuExterior();

        this.getFilterChequera(TIPOS_CHEQUERAS.DEPOSITO);

    }

    onChangeValue = (event) => {
        let { id, value } = event.target;

        this.setState({ [id]: value });
    }

    submitData = async () => {
        let { importe, cuentaSelected, myIp, concepto } = this.state;
        this.setState({ loading: true });
        if (await getCoordenadas(this.props)) {
            this.setState({ loading: false });
            this.props.showToken({
                idUsuario: this.props.dataUser.IdUsuario, accion: ACCION_CODIGO.ACCESO,
                title: "Código de Seguridad",
                message: "Ingresar el código de seguridad generado en tu aplicación móvil (token) para validar tu operación.",
                txtButton: "Confirmar Operación",
                onlyPass: true,
                esOperacion: true,
                actionClick: async (softToken) => {
                    let P = await new Promise(async (resolve) => {
                        try {

                            let bodyOperaEfectivo = SERVICES.OPERA_EFECTIVO.body;
                            bodyOperaEfectivo.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
                            bodyOperaEfectivo.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
                            bodyOperaEfectivo.Importe = parseFloat(importe);
                            bodyOperaEfectivo.TipoOper = TIPO_OPERACION.DEPOSITO;
                            bodyOperaEfectivo.Banco = cuentaSelected.Banco;
                            bodyOperaEfectivo.Titular = cuentaSelected.Titular;
                            bodyOperaEfectivo.Cuenta = encryptValues(cuentaSelected.Cuenta);
                            bodyOperaEfectivo.SoftToken = encryptValues(softToken);
                            bodyOperaEfectivo.IP = encryptValues(this.props.ip);
                            bodyOperaEfectivo.Latitud = encryptValues(this.props.coordenadas.latitude);
                            bodyOperaEfectivo.Longitud = encryptValues(this.props.coordenadas.longitude);
                            bodyOperaEfectivo.Pais = encryptValues(this.props.coordenadas.pais);
                            bodyOperaEfectivo.Ciudad = encryptValues(this.props.coordenadas.ciudad);
                            bodyOperaEfectivo.Estado = encryptValues(this.props.coordenadas.estado);
                            bodyOperaEfectivo.Direccion = encryptValues(this.props.coordenadas.direccion);
                            bodyOperaEfectivo.Concepto = concepto;
                            bodyOperaEfectivo.DeviceToken = this.props.dataUser ? this.props.dataUser.deviceToken : "";

                            let resultOperaEfectivo = await makeCall(SERVICES.OPERA_EFECTIVO, bodyOperaEfectivo);

                            if (resultOperaEfectivo && resultOperaEfectivo.response) {
                                if (resultOperaEfectivo.response.Exito !== 200) {
                                    // this.props.okButton({
                                    //     message: resultOperaEfectivo.response.MensajeError, title: "Advertencia", txtButton: "Entendido",
                                    //     statusCode: resultOperaEfectivo.response.Exito
                                    // });

                                    this.setState({ errorMsg: resultOperaEfectivo.response.MensajeError });

                                    // M.toast({ html: resultOperaEfectivo.response.MensajeError, classes: "red darken-3" })
                                } else if (resultOperaEfectivo.response.Exito === 408) {
                                    // this.props.okButton({
                                    //     message: resultOperaEfectivo.response.MensajeError,
                                    //     txtButton: "Entendido",
                                    //     actionClick: () => {
                                    //         getCoordenadas(this.props);
                                    //     }
                                    // })
                                    this.setState({ errorMsg: resultOperaEfectivo.response.MensajeError });
                                } else {
                                    let detail = resultOperaEfectivo.response['TTOPERACIONES']['TT-OPERACIONES'][0];
                                    this.setState({ operacionExitosa: true, detail }, () => {
                                        this.props.recargaCartera(true);
                                    });

                                }
                                firebase.analytics().logEvent('Opera_Deposito');
                                this.setState({ loading: false });
                                resolve(true);
                            } else {
                                this.setState({ loading: false, errorMsg: "Ocurrió un error inesperado intente nuevamente." });
                                // this.props.okButton({
                                //     message: "Ocurrió un error inesperado intente nuevamente.", title: "Advertencia", txtButton: "Entendido",
                                //     statusCode: 404
                                // });
                                resolve(true);
                            }

                        } catch (error) {
                            this.setState({ loading: false, errorMsg: "Ocurrió un error inesperado intente nuevamente." });
                            // this.props.okButton({
                            //     message: "Ocurrió un error inesperado intente nuevamente.", title: "Advertencia", txtButton: "Entendido",
                            //     statusCode: 404
                            // });
                            resolve(true);

                        }
                    });


                    return P;

                }
            });

        } else {
            this.setState({ loading: false });
        }

    }

    isValueAllowed = (values) => {
        const { floatValue } = values;
        return floatValue === undefined || (floatValue >= 0 && floatValue <= 999999999.99);
    };

    render() {
        let { importe, cuentaSelected, loading, operacionExitosa, detail, errorMsg } = this.state;

        if (operacionExitosa) {
            return (
                <React.Fragment>
                    <div className="row marginLR-1r mt-1" id="detalleRecibo">
                        <div id="cabeceraComprobante">
                            <div className="col s2 center-align">
                                <img src={ICONS.LOGO_CIRCULO} alt="LOGO" />
                            </div>
                            <div className="col s6" style={{ paddingTop: "1.5rem" }}>
                                <span>Depósito a su contrato en Valmex</span>
                            </div>
                            <div className='col s4 right-align' style={{ paddingTop: "1.5rem" }}>
                                <span>{moment().format('yyyy/MM/DD HH:mm')}</span>
                            </div>
                        </div>
                        <div className="col s12 mt-1 detailCard">
                            <div className="row containerDetalleOpera">
                                <div className="col s12 navyBackground white-text" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                    <span>{cuentaSelected.DescBanco}</span>  <span className="floatRight">*****{`${cuentaSelected.Cuenta}`.substring(`${cuentaSelected.Cuenta}`.length - 4)}</span>
                                </div>
                                <div className="col s12">
                                    <p className="noMBottom ft-10">Tipo de Operación</p>
                                    <p className="noMTop">{detail.TipoOper}</p>
                                </div>
                                <div className="col s12 bgGray">
                                    <p className="noMBottom ft-10">Importe</p>
                                    <p className="noMTop">${nWithCommas(parseFloat(importe).toString())}</p>
                                </div>

                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Fecha de operación</p>
                                    <p className="noMTop">{detail.FOper}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Hora de operación</p>
                                    <p className="noMTop">{detail.Hora}</p>
                                </div>
                                <div className="col s12 bgGray">
                                    <p className="noMBottom ft-10">Fecha de liquidación</p>
                                    <p className="noMTop">{detail.FLiq}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">No. de contrato</p>
                                    <p className="noMTop">{this.props.currentContrato ? this.props.currentContrato.Contrato : ""}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Folio</p>
                                    <p className="noMTop">{detail.Folio}</p>
                                </div>
                                {
                                    detail.Concepto ?
                                        <div className="col s12 bgGray">
                                            <p className="noMBottom ft-10">Concepto</p>
                                            <p className="noMTop">{detail.Concepto}</p>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>

                        <div className="col s12 ft-12" id="footerCardDetail">
                            Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                        </div>
                    </div>
                    <div className="col s12 center-align">
                        <button onClick={() => {
                            shareDetail('detalleRecibo');
                        }} className="btn primaryBtn_oc width100 boldText mb-1" >Compartir</button>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div className="row marginLR-1r">
                <div className="col s12 mt-1">
                    <span><img src={ICONS.UNO} alt="icon uno" /></span>  Indica el importe a depositar:
                </div>
                <div className="input-field col s12 mt-1">
                    <NumberFormat
                        id="importe"
                        thousandSeparator={','}
                        decimalSeparator="."
                        value={importe}
                        isNumericString
                        decimalScale={2}
                        fixedDecimalScale
                        isAllowed={this.isValueAllowed}
                        prefix="$"
                        allowNegative={false}
                        onValueChange={(values) => {
                            this.setState({ importe: values.value })
                        }}
                    />
                    <label htmlFor="importe">Importe</label>
                    {errorMsg ?
                        <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                        : null}
                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.DOS} alt="icon dos" /></span>  Selecciona una cuenta para obtener los fondos: <i className="material-icons tooltipped cursorHelp helpIcon"
                        data-position="bottom"
                        data-tooltip="Se realizará a través del servicio cargo en cuenta, consiste en tomar de forma automática los recursos de la cuenta registrada para esta operación."
                    >help_outline</i>
                </div>
                <div className="col s12 mt-1">
                    <div className="row boxDepositoRetiro">
                        {
                            this.getTipoChequera(TIPOS_CHEQUERAS.DEPOSITO)
                        }
                    </div>
                </div>
                <div className="input-field col s12 mt-1">

                    <input id="concepto" type="text" onChange={(e) => {
                        this.setState({ concepto: e.target.value })
                    }} value={this.state.concepto} maxLength={30} />
                    <label htmlFor="concepto">Concepto</label>
                    <span className='right ft-12'>{this.state.concepto.length}/30</span>
                    {
                        this.state.dataMenuExterior.depositos && this.state.dataMenuExterior.depositos.showDisclaimerConcepto ?
                            <span className="helper-text" >{this.state.dataMenuExterior && this.state.dataMenuExterior.depositos.disclaimerConcepto ? this.state.dataMenuExterior.depositos.disclaimerConcepto : ''}</span>

                            : null
                    }


                </div>
                {

                    (cuentaSelected && importe.trim() !== "" && parseFloat(importe) >= 0.01) ?
                        <div className="col s12 mt-1 center-align">
                            {
                                loading ?
                                    <Reload action={() => { }} />

                                    :

                                    <button className="btn primaryBtn_oc width100 boldText"
                                        onClick={this.submitData}
                                    >Depositar</button>
                            }

                        </div>

                        : null

                }

            </div>
        )
    }

    getFilterChequera = (tipo) => {

        let filterChequera = this.props.chequera.filter((v, i) => {
            if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato
                && v.Opera === "1"

            ) {
                return v;
            }
        });

        if (!filterChequera.length) {
            this.props.setModalInfo({
                title: "Atención",
                text: `No cuenta con chequeras registradas para realizar depositos de efectivo, por favor contacte a su promotor.`,
                txtButton: "Entendido"
            })
        }
    }

    getTipoChequera = (tipo) => {
        let { cuentaSelected } = this.state;
        if (this.props.chequera && this.props.chequera.length) {
            let filterChequera = this.props.chequera.filter((v, i) => {
                if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato) {
                    return v;
                }
            });

            // if (filterChequera.length) {


            return (<div className="col s12">
                <ul className="collection">
                    <li className="collection-item row">

                        <div className="col s12 left-align mt-1">
                            Cuentas bancarias (chequeras)
                        </div>
                    </li>
                    {
                        filterChequera.map((v, i) => {

                            return (
                                <li className={`${cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                    'cuentaSelected '
                                    : ""} collection-item row hoverContent`}
                                    disabled={v.Opera === "0"}
                                    onClick={() => {

                                        if (v.Opera === "1") {
                                            if (v.HoraFin && v.HoraIni) {

                                                let fechaActual = new Date();
                                                let ha = fechaActual.getHours();  //hora actual
                                                let ma = fechaActual.getMinutes(); //minuto actual

                                                if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) < Date.parse(`01/01/2011 ${v.HoraIni}:00`)) {

                                                    // this.props.okButton({
                                                    //     message: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.`,
                                                    //     title: "Advertencia", txtButton: "Ok",
                                                    //     statusCode: 404
                                                    // });
                                                    this.setState({ errorMsg: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.` });

                                                } else if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) > Date.parse(`01/01/2011 ${v.HoraFin}:00`)) {
                                                    // this.props.okButton({
                                                    //     message: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.`,
                                                    //     title: "Advertencia", txtButton: "Ok",
                                                    //     statusCode: 404
                                                    // });

                                                    this.setState({ errorMsg: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.` });
                                                }
                                                else {
                                                    this.setState({ cuentaSelected: v, operacionExitosa: false });
                                                }

                                            } else {
                                                this.setState({ cuentaSelected: v, operacionExitosa: false });
                                            }
                                        }

                                    }
                                    }>
                                    <div className="col s10">
                                        <p style={{ marginTop: '0px', marginBottom: '1px' }}>
                                            <span className="title">{v.DescBanco} - *****{`${v.Cuenta}`.substring(`${v.Cuenta}`.length - 4)}</span>
                                        </p>
                                        <p style={{
                                            textTransform: 'capitalize',
                                            fontSize: '12px',
                                            marginTop: '0px'
                                        }}>{v.Nombre.toLowerCase()} - {v.Tipo}</p>
                                    </div>
                                    <div className="col s2 center-align">
                                        {
                                            cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                                <img src={ICONS.CHECK_BLACK} className="mt-1" alt='Check' />
                                                : null
                                        }

                                    </div>

                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            )

            // }



        }

        return <div></div>;

    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, coordenadas, ip
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        ip = state.commonsActions.ip || false;
        coordenadas = state.commonsActions.coordenadas || false;
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history, coordenadas, ip };
};

export default connect(mapStateToProps,
    {
        showToken, okButton, recargaCartera, getCoordenadas, setCoordenadas, setModalInfo
    })(Deposito);