import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHistory,okButton } from '../redux/actions';
import { SERVICES } from '../properties';
import { encryptValues, makeCall } from '../utils';
import Chart from 'react-apexcharts'
import M from 'materialize-css';
import Reload from './reload';
import moment from 'moment';
import 'moment/locale/es';

import Operaciones from '../components/operaciones';

class FondosInversion extends Component {

    state = {
        infoFondos: false,
        infoSelected: false,
        info: false,
        contrato: null,
        esEmpty : false

    }

    // componentDidUpdate(prevProps, prevState) {

    //     if (this.props.currentContrato && this.props.currentContrato.Contrato && this.props.dataUser) {
    //         if (this.state.contrato !== this.props.currentContrato.Contrato) {
    //             this.getInfoFondos();
    //         }
    //     }
    // }


    componentDidMount(){
        this.getInfoFondos();
    }

    makeInfoShart = (inf) => {
        let { preciosMes } = inf;
        let d = [];
        let cat = [];

        for (let pm of preciosMes) {
            d.push(pm.Precio);
            cat.push(moment(pm.Fecha).locale('es').format('DD-MMM-YY'));
        }

        if (d.length && cat.length) {

            this.setState({

                series: [{
                    name: "Precio",
                    data: d
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: cat,
                        labels:{
                            show:true,
                            rotate: -45,
                            rotateAlways: true,
                        }
                    }
                },
                info: inf

            });
        }
    }



    componentDidMount = async () => {

        this.props.setHistory(this.props.history);
        try {
            this.getInfoFondos();
            M.Modal.init(document.querySelectorAll('.model_operacion'), {
                dismissible: false
            });
        } catch (error) {

        }
    }


    getInfoFondos = async () => {
        try {
            if (this.props.currentContrato && this.props.dataUser) {
             
                this.setState({ contrato: this.props.currentContrato.Contrato });
                let bodyInfoFondos = SERVICES.INFO_FONDOS.body;
                bodyInfoFondos.Contrato = encryptValues( this.props.currentContrato ? this.props.currentContrato.Contrato : "");
                bodyInfoFondos.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");

                let resultInfoFondos = await makeCall(SERVICES.INFO_FONDOS, bodyInfoFondos);

                if (resultInfoFondos && resultInfoFondos.response) {

                    if (resultInfoFondos.response.Exito === 200) {

                        this.ordenaInfo(resultInfoFondos.response);
                    } else {
                        
                        this.props.okButton({
                            message: "Ocurrió un error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                          });
                    }
                }
            }
        } catch (error) {
             //console.log(error);
            this.props.okButton({
                message: "Ocurrió un error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                statusCode: 404
              });
        }
    }





    render() {

        let { infoFondos, info } = this.state;

        return (
            <div className="row">
                <div className="col s12 m4 l4 xl4">
                    <div className="row">



                        <div className="col s12 closeModalOk center-align boldText ft-20 titleFondos">
                            Información de fondos
                        </div>
                        {
                            infoFondos ?

                               <div className="col s12 contentInfoFondos">
                                    {
                                        Object.keys(infoFondos).map((v, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <div className="col s12 mt-1 mb-1" style={{ textTransform: 'capitalize' }}>
                                                        {v.toLowerCase()}
                                                    </div>
                                                    {
                                                        infoFondos[v].map((inf, j) => {
                                                            return (
                                                                <div className={`col s12 infoIRow`} key={j}
                                                                    onClick={() => {
                                                                        // console.log(inf)
                                                                        this.makeInfoShart(inf);
                                                                    }}>
                                                                    <div className="row ">
                                                                        <div className="col s6 left-align">
                                                                            {inf.Fondo}
                                                                        </div>
                                                                        <div className="col s6 right-align">
                                                                            {inf.PrecioMD}
                                                                        </div>
                                                                        <div className="col s6 left-align">
                                                                            <label>{inf.Operacion}</label>
                                                                        </div>
                                                                        <div className="col s6 right-align">
                                                                            <label>{inf.Plazo}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }

                                </div>

                                :
                                this.state.esEmpty ? 
                                <div className="col s12 mt-1 mb-1 center-align">
                                No se encontro información para este contrato {this.props.currentContrato.Contrato}
                            </div>
                                : 
                                <div className="col s12 mt-1 mb-1 center-align">
                                    <Reload action={() => { this.getInfoFondos() }} />
                                </div>
                        }

                    </div>
                </div>
                <div className="col s12 m8 l8 xl8" id="chart">
                    {
                        info ?
                            <div className="row mt-1">
                                <div className="col s12 left-align ml-1-4">
                                    <p>{info.Fondo}</p>
                                    <p>Último precio conocido <span className="boldText">${info.preciosMes[info.preciosMes.length - 1].Precio}</span> </p>
                                    <p>Fecha de liquidación <span className="boldText">{info.preciosMes[info.preciosMes.length - 1].Fecha.replace(/-/g, '/')}</span></p>
                                </div>

                                <div className="col s12">
                                    <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
                                </div>

                                <div className="col s12 mt-1">
                                    <div className="row notificacionContainer contentInfoFondo">
                                        <div className="col s6">
                                            <div><label>Tipo de gestión</label></div>
                                            <div>{info.TipoGestion}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Horizonte de Inversión</label></div>
                                            <div>{info.Plazo}</div>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Liquidez</label></div>
                                            <div>Diario / Mismo día</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Hora de apertura</label></div>
                                            <div>{info.HApertura}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Hora de cierre</label></div>
                                            <div>{info.HCierre}</div>
                                        </div>
                                        <div className="col s12">
                                            <p className="borderInfoF borderInfoFT paddingTopBI" >
                                                <label>
                                                    <input type="checkbox" className="filled-in" checked={
                                                        info.CpaAbierta
                                                    } />
                                                    <span>Compra abierta</span>
                                                </label>
                                            </p>
                                            <p className="borderInfoF minusMT-9">
                                                <label>
                                                    <input type="checkbox" className="filled-in" 
                                                    checked={
                                                        info.VtaAbierta
                                                    }
                                                    />
                                                    <span>Venta abierta</span>
                                                </label>
                                            </p>
                                            <p className="borderInfoF minusMT-9">
                                                <label>
                                                    <input type="checkbox" className="filled-in" 
                                                    checked={
                                                        info.FondoAbierto
                                                    } />
                                                    <span>Fondo abierto</span>
                                                </label>
                                            </p>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Valor a mercado mismo día</label></div>
                                            <div>${info.PrecioMD}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Valor a mercado 24 horas</label></div>
                                            <div>${info.Precio24}</div>
                                        </div>
                                        <div className="col s6">
                                            <div><label>Valor a mercado 48 horas</label></div>
                                            <div>${info.Precio48}</div>
                                        </div>
                                        <div className="col s12">
                                            <div><label>Deuda</label></div>
                                            <div>{info.Descripcion}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            : null}

                </div>
                <Operaciones />
            </div>
        )

    }

    ordenaInfo = (data) => {
        let info = data.TTINFOFONDOS["TT-INFOFONDOS"];

        let infoFondosOrden = {};
        //Ordena los fondos
        if (info && info.length) {
            for (let inf of info) {
                if (!infoFondosOrden[inf.Clasificacion.trim().toUpperCase()]) {
                    let filtrado = info.filter((v, i) => {
                        if (v.Clasificacion.trim().toUpperCase() === inf.Clasificacion.trim().toUpperCase()) {
                            v.preciosMes = this.ordenaPrecios(v.Fondo, data);
                            return v;
                        }
                    });
                    infoFondosOrden[inf.Clasificacion.trim().toUpperCase()] = filtrado;
                }
            }
           
            let infoToShow = false;
            let keys = Object.keys(infoFondosOrden);
            if(keys.length){
                infoToShow = infoFondosOrden[keys[0]][0];
                this.makeInfoShart(infoToShow);
            }

            this.setState({ infoFondos: infoFondosOrden, info: infoToShow });
        }else{
            this.setState({ infoFondos: false, info: false, esEmpty: true });
        }
    }

    ordenaPrecios = (fondo, data) => {
        let preciosMes = data.TTPRECIOSMES["TT-PRECIOSMES"];
        let filtrado = preciosMes.filter((v, i) => {
            if (v.Fondo.trim().toUpperCase() === fondo.trim().toUpperCase()) {
                return v;
            }
        });

        return filtrado;
    }
}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};

export default connect(mapStateToProps,
    {
        setHistory,
        okButton
    }
)(FondosInversion)