import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHistory, setOperacion, setOptionSelectedMenu } from '../redux/actions';
import firebase from 'firebase';
import M from 'materialize-css';

class Faq extends Component {

    state = {
        faq: false,
        currentP: false,
        activeCollap : false,
    }

    async componentDidMount() {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
        this.props.setHistory(this.props.history);
        try {
            await this.getPreguntas();
        } catch (error) {
        }
    }


    getPreguntas = async () => {
        try {
            let preguntasData = await firebase.firestore().collection('configuraciones/faqs/preguntas').get();

            if (!preguntasData.empty) {
                let mapCategorias = {};
                preguntasData.docs.map((e) => e.data())
                    .map((e) => {
                      
                        if (mapCategorias[e.categoria]) {
                            mapCategorias[e.categoria].push(e);
                        } else {
                            mapCategorias[e.categoria] = [e];
                        }

                        return e;
                    });

                    if(mapCategorias['Otros']){
                        const otros = mapCategorias["Otros"];

                        // Eliminar el objeto "Otros" del objeto principal
                        delete mapCategorias["Otros"];
    
                        // Agregar el objeto "Otros" al final del objeto principal
                        mapCategorias["Otros"] = otros;
                        
                    }

                   
                
                this.setState({ faq: mapCategorias }, () => {
                    setTimeout(() => {
                        var elems = document.querySelectorAll('.collapsible');
                        M.Collapsible.init(elems, {

                            onOpenStart: (v)=>{
                               
                                this.setState({activeCollap:v.id});
                            },
                            onCloseEnd: (v)=>{
                                if(this.state.activeCollap && this.state.activeCollap == v.id){
                                    this.setState({activeCollap:false});
                                }
                            }

                        });

                        M.Collapsible.getInstance(elems[0]).open(0);
                    }, 1000);
                });
            }
        } catch (error) {

        }
    }


    render() {
        let { currentP } = this.state;
        return (

            <div className="row">
                <div className="col s4 m4 l3 xl3">
                    <div className="row rightShadow">
                        <div className="col s12 closeModalOk ntitleContent">
                            <span className="title-n">Preguntas frecuentes</span>
                        </div>
                        <div className="col s12 contentNotifications">
                            {
                                this.showFaq()
                            }
                        </div>
                    </div>

                </div>
                <div className="col s8 m8 l9 xl9">
                    {
                        currentP ?
                            <div className="row mt-1 ml-10">
                                <div className="col s12 ft-20">

                                </div>
                                <div className="col s12 tituloPregunta mt-1">
                                    {currentP.titulo}
                                </div>


                                <div className="col s12 ft-16 descripcionPregunta contentFaq mt-1">
                                    { decodeURIComponent(escape(atob( currentP.descripcion )))}
                                </div>
                            </div>
                            : 
                            <div className='row mt-1 ml-10'>
                                <div className='col s12 descripcionPregunta mt-1'>
                                Seleccione una pregunta de acuerdo a la categoría de su interés.
                                </div>
                            </div>
                    }
                </div>

            </div>
        )

    }

    compararPosicion(a, b) {
        if (a.posicion < b.posicion) {
            return -1;
        }
        if (a.posicion > b.posicion) {
            return 1;
        }
        return 0;
    }

    showFaq = () => {
        if (this.state.faq) {
            let keysObj = Object.keys(this.state.faq);
            return (
                <div className='row'>
                    <ul className="collapsible noMTop">
                    
                            {
                                keysObj.map((k) => {
                                    let preguntas = this.state.faq[k].sort(this.compararPosicion);
                                    return (
                               <li key={k.toLowerCase()} id={k}>
                                   
                                        <div className="collapsible-header" style={{display:"block"}}>
                                           <span style={{color:"#202A52"}}>{k}</span> 
                                            <span className='right'>
                                                <i className='material-icons'>
                                                    {
                                                        this.state.activeCollap === k ? 
                                                        'expand_more': 'chevron_right'
                                                    }
                                                </i>

                                            </span>
                                            </div>
                                        <div className="collapsible-body" style={{padding:"1rem"}}>
                                            <div className='row'>
                                                {
                                                    preguntas.map((p,i) => {
                                                        let cp = this.state.currentP;
                                                        return (
                                                            <div className='col s12' hidden={!p.habilitado} key={i}>
                                                                <div className={`card preguntaContainer hoverContent ${(cp && `${cp.categoria}_${cp.posicion}`) === `${p.categoria}_${p.posicion}` ? 'activeNot' : ''}`}
                                                                    key={`${cp.categoria}_${cp.posicion}`}
                                                                    onClick={() => {
                                                                        this.setState({ currentP: p });
                                                                    }}
                                                                >
                                                                    <div className="card-content" style={{ padding: '10px', cursor: 'pointer' }}

                                                                    >
                                                                        <div className="row" >
                                                                            <div className="col s12 ft-16 fw-400">
                                                                                <span style={{color:"#202A52"}}>{p.titulo}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        </li>
                                    )
                                })
                            }
                        
                    </ul>
                </div>
            )
        } else {
            return null;
        }
    }


}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};

export default connect(mapStateToProps,
    {
        setHistory,
        setOperacion,
        setOptionSelectedMenu
    }
)(Faq)