import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICONS } from '../assets/icons';
import { setHistory, setOperacion, okButton, setTiposServicios } from '../redux/actions';
import { setDataUser, setContratos, setCurrentContrato, setChequera } from '../redux/actionsServices';
import PosicionCartera from './graphs/posicionCartera';
import DivisasIndicesAcciones from './graphs/divisasIndicesAcciones';
import Reload from './reload';
import 'chartjs-plugin-labels';
import M from 'materialize-css';
import moment from 'moment';


import { TEXTOS } from '../properties';
import { getMonthStringGraphs } from '../utils';


import { Bar } from 'react-chartjs-2';


class Graphs extends Component {

    state = {
        contrato: false,
        rendimientos: false,
        loadingRendimientos: true,
        monthSistema: false,
        servicios: false,
        permiteCompraVenta: true,
        yearSistema: false
    }


    async componentDidMount() {

        this.props.setHistory(this.props.history);

        if (this.props.dataUser && this.props.dataUser.FechaSistema) {
            let monthSistema = parseInt(this.props.dataUser.FechaSistema.split('-')[1]) - 1;
            let yearSistema = parseInt(this.props.dataUser.FechaSistema.split('-')[0]);
            this.setState({ monthSistema, yearSistema });
        }

        M.Modal.init(document.querySelectorAll('.modal_composicion'), {
            dismissible: false,
            opacity: 0
        });

        M.Modal.init(document.querySelectorAll('.model_operacion'), {
            dismissible: false
        });

        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});

    }



    render() {
        
        let { rendimientos, servicios, currentContrato } = this.props;

        if (rendimientos) {
            rendimientos = rendimientos.TTRENDIMIENTO['TT-RENDIMIENTO'];
        }

        let permiteCompraVenta = true;

        if (servicios && servicios.length) {
            const sf = servicios.filter((v) => {
                if (v.id === "E" && v.NoEsUsuario) {
                    return v;
                }
            });

            if (sf.length) {
                permiteCompraVenta = false;
            }

        }


        return (
            <div className="row">
                <div className="col s12 m12 l4 xl4 ">
                    {
                        !rendimientos || !servicios ?

                            <div className="row">

                                <ul className="collection with-header posicionCartera z-depth-2">
                                    <li className="collection-header">

                                        <div className="row">
                                            <div className="col s12 center-align">
                                                <Reload action={() => { }} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> :


                            <PosicionCartera
                                rendimientos={rendimientos} servicios={servicios} />
                    }

                </div>
                <div className="col s12 m12 l8 xl8">
                    <div className="row flex paddingOperaciones">
                        <div className="col s12 m12 l5 xl5 rendimientoTotal paddingRigth20">
                            {
                                !rendimientos ?
                                    <div className="row contentBarChart">
                                        <div className="col s12 center-align mt-1">
                                            <Reload action={() => { }} />
                                        </div>
                                    </div>
                                    :

                                    <div className="row contentBarChart">
                                        <div className="col s12">
                                            <p>Rendimiento neto efectivo del mes de

                                                {" " + getMonthStringGraphs(parseInt(rendimientos[0].Fecha.split("-")[1]))
                                                    + " "
                                                    + rendimientos[0].Fecha.split("-")[0]} <span>
                                                    <i className="material-icons tooltipped cursorPointer"
                                                        data-position="bottom" data-tooltip="Si desea conocer su rendimiento anualizado consulte a su promotor."
                                                    >info_outline</i></span>
                                            </p>
                                            <div className="center-align percentRendimientoTotal txtSky">
                                                {
                                                    rendimientos ?
                                                        `${parseFloat(rendimientos[0].Rendimiento).toFixed(6)}%`
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        {
                                            rendimientos ?
                                                <div className="col s12 align-left NavyPrimary mt-1">
                                                    Rendimiento neto efectivo de meses anteriores
                                                </div>
                                                : null
                                        }

                                        <div className="col s12 mt-1" >
                                            {
                                                rendimientos ?

                                                    <Bar

                                                        data={{
                                                            labels: [
                                                                getMonthStringGraphs(parseInt(rendimientos[3].Fecha.split("-")[1]))
                                                                + " "
                                                                + rendimientos[3].Fecha.split("-")[0],
                                                                getMonthStringGraphs(parseInt(rendimientos[2].Fecha.split("-")[1]))
                                                                + " "
                                                                + rendimientos[2].Fecha.split("-")[0],
                                                                getMonthStringGraphs(parseInt(rendimientos[1].Fecha.split("-")[1]))
                                                                + " "
                                                                + rendimientos[1].Fecha.split("-")[0]
                                                            ],
                                                            datasets: [{
                                                                label: '',
                                                                data: [
                                                                    rendimientos[1].Rendimiento,
                                                                    rendimientos[2].Rendimiento,
                                                                    rendimientos[3].Rendimiento,
                                                                ],
                                                                backgroundColor: [
                                                                    '#B487B8',
                                                                    '#FFCD00',
                                                                    '#9EC8FF'
                                                                ],
                                                                borderColor: [
                                                                    '#B487B8',
                                                                    '#FFCD00',
                                                                    '#9EC8FF'
                                                                ],
                                                                borderWidth: 1,
                                                            }]
                                                        }}

                                                        options={{
                                                            scales: {
                                                                yAxes: [{
                                                                    ticks: {
                                                                        beginAtZero: true,
                                                                        callback: function (value, index, values) {
                                                                            return value + '%';
                                                                        }
                                                                    }
                                                                }]
                                                            },
                                                            legend:{
                                                                labels:{
                                                                    boxWidth: 0,
                                                                    padding: 0

                                                                },
                                                                onClick: (e)=>{
                                                                    e.stopPropagation();
                                                                },
                                                                display: true,
                                                            },
                                                            plugins: {
                                                                
                                                                labels: {
                                                                    render: (args) => {
                                                                        return args.value + '%'
                                                                    },
                                                                },
                                                            },
                                                            tooltips: {
                                                                callbacks: {
                                                                    label: function (tooltipItem, data) {
                                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                        var currentValue = dataset.data[tooltipItem.index];
                                                                        return ' (' + currentValue + '%)';
                                                                    },
                                                                    title: function (tooltipItem, data) {
                                                                        return data.labels[tooltipItem[0].index];
                                                                    }
                                                                }
                                                            }
                                                        }}

                                                        type="bar"

                                                    />
                                                    : null
                                            }

                                        </div>
                                    </div>
                            }
                        </div>
                        {/* OPERACIONES */}
                        <div className="col s12 m12 l7 xl7 rendimientoTotal">
                            <div className="row contentBarChart">
                                <div className="col s12 mb-10 mt-10">
                                    <span>Operaciones</span>
                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                if (permiteCompraVenta && this.props.dataUser.Inhabil === 0 && currentContrato.Consulta == "0") {
                                                    this.props.setOperacion({ opt: "comprar", title: "Comprar" });
                                                } else {
                                                    let propsOkButton = {
                                                        message: currentContrato.Consulta === "1" ?  TEXTOS.CONTRATO_SOLO_CONSULTA : TEXTOS.NO_PERMITE_COMPRA_VENTA.message,
                                                        title: TEXTOS.NO_PERMITE_COMPRA_VENTA.title,
                                                        txtButton: TEXTOS.NO_PERMITE_COMPRA_VENTA.txtButton,
                                                        statusCode: 404
                                                    };
                                                    if (this.props.dataUser.Inhabil !== 0) {
                                                        propsOkButton = TEXTOS.DIA_INHABIL;
                                                    }
                                                    this.props.okButton(propsOkButton);
                                                }

                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.CARD} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Comprar</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                 if (permiteCompraVenta && this.props.dataUser.Inhabil === 0 && currentContrato.Consulta === "0") {
                                                    this.props.setOperacion({ opt: "vender", title: "Vender" });
                                                } else {
                                                    let propsOkButton = {
                                                        message: currentContrato.Consulta === "1" ?  TEXTOS.CONTRATO_SOLO_CONSULTA:  TEXTOS.NO_PERMITE_COMPRA_VENTA.message,
                                                        title: TEXTOS.NO_PERMITE_COMPRA_VENTA.title,
                                                        txtButton: TEXTOS.NO_PERMITE_COMPRA_VENTA.txtButton,
                                                        statusCode: 404
                                                    };
                                                    if (this.props.dataUser.Inhabil !== 0) {
                                                        propsOkButton = TEXTOS.DIA_INHABIL;
                                                    }
                                                    this.props.okButton(propsOkButton);
                                                }
                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.EXPENSIVE} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Vender</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                if (this.props.dataUser.Inhabil === 0 && currentContrato.Consulta === "0") {
                                                    this.props.setOperacion({ opt: "desposito_contrato", title: "Depositar a mi contrato" });
                                                } else {

                                                    if(currentContrato.Consulta === "1"){
                                                        let propsOkButton = {
                                                            message: TEXTOS.CONTRATO_SOLO_CONSULTA,
                                                            title: TEXTOS.NO_PERMITE_COMPRA_VENTA.title,
                                                            txtButton: TEXTOS.NO_PERMITE_COMPRA_VENTA.txtButton,
                                                            statusCode: 404
                                                        };
                                                        this.props.okButton(propsOkButton);
                                                    }else{
                                                           this.props.okButton(TEXTOS.DIA_INHABIL);
                                                    }
                                                 
                                                }

                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.WINS} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Depositar a mi contrato</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                if (this.props.dataUser.Inhabil === 0 && currentContrato.Consulta === "0") {
                                                    this.props.setOperacion({ opt: "retiro_contrato", title: "Retirar de mi contrato" })
                                                } else {


                                                    if(currentContrato.Consulta === "1"){
                                                        let propsOkButton = {
                                                            message: TEXTOS.CONTRATO_SOLO_CONSULTA,
                                                            title: TEXTOS.NO_PERMITE_COMPRA_VENTA.title,
                                                            txtButton: TEXTOS.NO_PERMITE_COMPRA_VENTA.txtButton,
                                                            statusCode: 404
                                                        };
                                                        this.props.okButton(propsOkButton);
                                                    }else{
                                                           this.props.okButton(TEXTOS.DIA_INHABIL);
                                                    }
                                                }

                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.ATM} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Retirar de mi contrato</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 mb-10">
                                    <span>Información</span>
                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                this.props.setOperacion({ opt: "movimientos", title: "Movimientos" })
                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.MOVINGS} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Movimientos</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s6 center-align">
                                    <div className="row optRowContainer">
                                        <div className="col s12 optContainer waves-effect"
                                            onClick={() => {
                                                this.props.setOperacion({ opt: "documentos", title: "Documentos" })
                                            }}
                                        >
                                            <p className="noMBottom"><img src={ICONS.DOCS} alt="hide" className="optOperacion"></img></p>
                                            <p className="optOperacionTxt noMTop">Documentos</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row paddingOperaciones ">
                        <DivisasIndicesAcciones />
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, saldoTotal, servicios, rendimientos
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        rendimientos = state.commonsActions.rendimientos || false;
        servicios = state.commonsActions.tiposServicios || false;
    }

    return {
        dataUser,
        currentContrato,
        menuPerfil,
        contratosUser,
        chequera,
        history,
        saldoTotal,
        servicios,
        rendimientos
    };
};

export default connect(mapStateToProps,
    {
        setHistory,
        setDataUser,
        setContratos,
        setCurrentContrato,
        setOperacion,
        setChequera,
        okButton,
        setTiposServicios
    }
)(Graphs)