import React, { Component } from 'react';
import { connect } from 'react-redux';
import { okButton, hide_okButton, setOperacion, hideOperacion } from '../redux/actions';
import { onlyDigits, makeCall, encryptValues, } from '../utils';
import { PATHS } from '../properties';
import { SERVICES, ACCION_CODIGO } from '../properties';
import Reload from './reload';
import M from 'materialize-css';
class TokenActivacion extends Component {

    state = {
        val1_act: "",
        val2_act: "",
        val3_act: "",
        val4_act: "",
        val5_act: "",
        val6_act: "",
        val7_act: "",
        val8_act: "",
        loading: false,
        disabledButton: true,
        aceptaGeo: false
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getValidation === false && (this.props && this.props.getValidation)) {
            this.setState({
                val1_act: "",
                val2_act: "",
                val3_act: "",
                val4_act: "",
                val5_act: "",
                val6_act: "",
                val7_act: "",
                val8_act: "",
                loading: false,
                disabledButton: true
            })
        }
    }

    componentDidMount = () => {

    }

    solicitaCodigo = async () => {
        this.setState({ loading: true });

        const { emailValida, esLogin } = this.props.data;

        let bodySolicitaCodigo = SERVICES.SOLICITA_CODIGO.body;
        bodySolicitaCodigo.IdUsuario = encryptValues(emailValida.trim().toLowerCase());
        // bodySolicitaCodigo.Passcode = encryptValues(decryptValue(responseValida.response.PassCode));
        //bodySolicitaCodigo.Celular = encryptValues(responseValida.response.Celular);
        bodySolicitaCodigo.Accion = esLogin ? encryptValues(ACCION_CODIGO.ACCESO_WEB) : encryptValues(ACCION_CODIGO.ACTIVACION_WEB);

        // bodySolicitaCodigo.Client_id = encryptValues(window.config.CLIENT_ID);
        // bodySolicitaCodigo.Client_secr = encryptValues(window.config.CLIENT_SECRET);

        let resultSolicitaCodigo = await makeCall(SERVICES.SOLICITA_CODIGO, bodySolicitaCodigo);

        this.setState({ loading: false });

        if (resultSolicitaCodigo.response && resultSolicitaCodigo.response.Exito === 200) {
            M.toast({html:' Código enviado <i class="material-icons right">check_circle</i>', classes:'green darken-3 white-text boldText'})
        }
    }

    validToken = async (event) => {
        event.preventDefault();
        const { esLogin } = this.props.data;
        let { val1_act, val2_act, val3_act, val4_act, val5_act, val6_act } = this.state;
        let serviceToCall = SERVICES.CHECK_TOKEN;
        let body = SERVICES.CHECK_TOKEN.body;
        body.IdUsuario = encryptValues(this.props.data.emailValida);
        body.Codigo = encryptValues(`${val1_act}${val2_act}${val3_act}${val4_act}${val5_act}${val6_act}`);
        body.Accion = esLogin ? encryptValues(ACCION_CODIGO.ACCESO_WEB) : encryptValues(ACCION_CODIGO.ACTIVACION_WEB);
     
        if(this.props.data && esLogin){
            body.AceptaGeoWEB = encryptValues(1);
        }

        // body.Client_id = encryptValues(window.config.CLIENT_ID);
        // body.Client_secr = encryptValues(window.config.CLIENT_SECRET);



        this.setState({ loading: true }, async () => {
            try {

                let result = await makeCall(serviceToCall, body);

                if (result && result.response) {

                    if (this.props.data && this.props.data.esLogin && parseInt(result.response.CodigoValido) === 1) {


                        this.props.setOperacion({
                            title: `Bienvenido`,
                            opt: "successLoginWeb",
                            data: {
                                esLogin: this.props.data.esLogin
                            }
                        })


                    } else {
                        if (parseInt(result.response.CodigoValido) === 1) {

                            this.props.setOperacion({
                                title: `Establecer Contraseña`,
                                opt: "estableceContrasenia",
                                data: {
                                    activacionWeb: true,
                                    email: this.props.data.emailValida,
                                    tokenSesion: this.props.data.responseValida.TokenSesion
                                }
                            })

                        } else {

                            if (result.response.Exito === 353) {
                                this.props.hideOperacion();
                                this.props.okButton({
                                    message: "Apreciable cliente, su cuenta está bloqueada, favor de realizar el proceso de desbloqueo.", title: "Advertencia",
                                    statusCode: 404,
                                    txtButton: "Entendido",
                                    actionClick: () => {
                                        // bodyCerrarSesion.idUsuario = encryptValues(email.trim().toLowerCase());

                                        // makeCall(SERVICES.TERMINA_SESION, bodyCerrarSesion, (errorCall) => {
                                        //     this.props.history.push(PATHS.DESBLOQUEO);
                                        // }).then(() => {
                                        //     this.props.history.push(PATHS.DESBLOQUEO);
                                        // });

                                        this.props.history.push(PATHS.DESBLOQUEO);

                                    }
                                });
                            }else if (result.response.Exito === 407) {
                                this.props.hideOperacion();
                                this.props.okButton({
                                    message: result.response.MensajeError, title: "Advertencia",
                                    statusCode: 404,
                                    txtButton: "Entendido",
                                    actionClick: () => {
                                        this.props.hide_okButton();
                                    }
                                });
                            }
                             else {
                                this.setState({ loading: false }, () => {
                                    this.props.okButton({
                                        message: "Apreciable cliente, el código especificado no es correcto. Por favor intente nuevamente.", title: "", txtButton: "Entendido",
                                        statusCode: 404,
                                        actionClick: () => {
                                            this.props.hide_okButton();
                                        }
                                    });
                                });
                            }


                        }
                    }

                } else {
                    this.setState({ loading: false });
                }

            } catch (error) {
                this.setState({ loading: false });
            }
        })


    }

    changeValueToken = (event) => {
        let { id, value } = event.target;
        if (value.trim() !== "" && onlyDigits(value)) {
            let nextInput = document.getElementById(`${parseInt(id) + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        } else if (value.trim() === "") {
            let lastInput = document.getElementById(`${parseInt(id) - 1}`);
            if (lastInput) {
                lastInput.focus();
            }

        }
    }

    keyPress = (event) => {

        let { keyCode, key } = event;
        let { id } = event.target;

        if (keyCode === 8 || key.toLowerCase() === "backspace") {

            let lastInput = document.getElementById(`${parseInt(id) - 1}_act`);
            if (lastInput) {

                lastInput.focus();
            }
            this.setState({ [`val${id}`]: "" }, () => {
                this.validForm();
            });
        } else {
            if (onlyDigits(key)) {
                let nextInput = document.getElementById(`${parseInt(id) + 1}_act`);
                if (nextInput) {
                    nextInput.focus();
                }
                this.setState({ [`val${id}`]: key }, () => {
                    this.validForm();
                });
            }
        }
    }

    validForm = () => {
        let { val1_act, val2_act, val3_act, val4_act, val5_act, val6_act } = this.state;
        if (`${val1_act}${val2_act}${val3_act}${val4_act}${val5_act}${val6_act}`.trim().length === 6) {

            if(this.props.data && this.props.data.muestraAceptaGeoWeb){
                if(this.state.aceptaGeo){
                    this.setDisabledButton(false);
                }else{
                    this.setDisabledButton(true);
                }
            }else{
                this.setDisabledButton(false);
            }
            
        } else {
            this.setState({ disabledButton: true });
        }
    }

    validInputs = () => {

        let { val1_act, val2_act, val3_act, val4_act, val5_act, val6_act } = this.state;
        if (`${val1_act}${val2_act}${val3_act}${val4_act}${val5_act}${val6_act}`.trim().length === 6) {
            return true;
        } else {
            return false;
        }

        
    }

    render() {
        let { val1_act, val2_act, val3_act, val4_act, val5_act, val6_act, loading, disabledButton } = this.state;
        return (

            <form className="row center-align mt-2" onSubmit={this.validToken}>
                <div className="col s12  center-align mt-1 inputTokenContainer">
                    <div className="col s2">
                        <input type="text" id="1_act"
                            value={val1_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                    <div className="col s2">
                        <input type="text" id="2_act"
                            value={val2_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                    <div className="col s2">
                        <input type="text" id="3_act"
                            value={val3_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                    <div className="col s2">
                        <input type="text" id="4_act"
                            value={val4_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                    <div className="col s2">
                        <input type="text" id="5_act"
                            value={val5_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                    <div className="col s2">
                        <input type="text" id="6_act"
                            value={val6_act}
                            maxLength="1"
                            onKeyDown={this.keyPress}
                        ></input>
                    </div>
                </div>
                <div className='row'>
                    <div className='col s12 center-align mt-3'>
                        {
                            this.props.data && this.props.data.esLogin ?

                                <span className='ft-16 NavyPrimary'>Ingresa el código que fue enviado
                                    al mail registrado para ingresar al Portal Valmex Web.
                                </span>

                                :
                                <span className='ft-16 NavyPrimary'>Ingresa el código que fue enviado al mail registrado para activar la cuenta.
                                </span>
                        }

                    </div>

                    {
                        this.props.data && this.props.data.muestraAceptaGeoWeb ?
                           <React.Fragment>
                                <div className="col s12 align-justify mt-2" >

                                    Apreciable cliente, en términos de las fracciones X y XV de las Disposiciones de carácter general a que se refiere el artículo 212 de la Ley del Mercado de Valores, se requiere dé su consentimiento para poder obtener la ubicación de su dispositivo electrónico a través del cual realice operaciones mediante el uso página electrónica de Valores Mexicanos Casa de Bolsa, S.A. de C.V., en caso contrario podrá realizar sus operaciones como normalmente la lleva a cabo por medio de su promotor.
                                </div>
                                <div className="col s12 switch mt-1 center-align">
                                    Aceptar términos
                                    <label className="">
                                        <input type="checkbox" id="aceptaGeo"
                                            onChange={this.onChangeValueCheck}
                                            checked={this.state.aceptaGeo || false}
                                        />
                                        <span className="lever"></span>
                                    </label>
                                </div>
                
                            </React.Fragment>
                            : null
                    }

                    <div className='col s12 center-align mt-2'>
                        <span className='ft-12 NavyPrimary'>
                            De clic <span className='brandTextPrimary cursorPointer'
                                onClick={() => {
                                    this.solicitaCodigo();
                                }}
                            >aquí</span> para enviar nuevamente el código a su mail.
                        </span>
                    </div>

                    <div className='col s12 center-align mt-2'>
                        {
                            loading || this.props.cargaInfo ?
                                <Reload action={() => { }} />

                                :
                                <button className="btn primaryBtn"
                                    disabled={disabledButton}
                                >Confirmar operación</button>
                        }
                    </div>
                </div>
            </form >
        )
    }

    setDisabledButton = (value) => {



        this.setState({ disabledButton: value });



    }

    onChangeValueCheck = (event) => {


        let { id } = event.target;
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let disabledButton = this.validInputs() && value ? false : true;

        this.setState({ [id]: value, disabledButton });
    }

}


const mapStateToProps = state => {
    let { getValidation, history } = false;
    if (state.commonsActions) {
        history = state.commonsActions.history || false;
        getValidation = state.commonsActions.getValidation || false;
    }

    return { getValidation, history };
};


export default connect(mapStateToProps, { okButton, hide_okButton, setOperacion, hideOperacion })(TokenActivacion)