import React, { Component } from 'react';
import { getMonthString, makeCall, encryptValues, decryptValue } from '../../utils'
import { SERVICES, TIPOS_DOCS } from '../../properties';
import { okButton,setOperacion } from '../../redux/actions';
import { connect } from 'react-redux'
import moment from 'moment';
import Reload from '../reload';
import M from 'materialize-css';

class Historico extends Component {

    state = {
        months: [],
        currentYear: false,
        loadingDoc: false,
        dates: {}
    }

    componentDidMount() {
        let date = moment();
        // let months = [];
        let dates = {};

        let fechaSplit = this.props.currentContrato ? this.props.currentContrato.FechaAlta.split('/') : [];

        for (let i = date.year(); i >= parseInt(fechaSplit[2]); i--) {
          
            dates[i] = [];
            let mS = "";
            if (i > parseInt(fechaSplit[2]) && i !== date.year()) {
                //Coloca todos los meses enero - diciembre
                for (let j = 0; j < 12; j++) {
                    mS = getMonthString(j);
                    dates[i].push({
                        name: mS,
                        month: j+1
                    });
                }
            } else if (i === date.year()) {
                for (let j = 0; j <= date.month()-1; j++) {
                    mS = getMonthString(j);
                    dates[i].push({
                        name: mS,
                        month: j+1
                    });
                }
            } else {
                //Obtiene hasta el mes que se dio de alta.
                let mesAlta = parseInt(fechaSplit[1]) - 1;
                for (let j = mesAlta; j <= 11; j++) {
                    mS = getMonthString(j);
                    dates[i].push({
                        name: mS,
                        month: j+1
                    });
                }

            }
        }

        this.setState({ currentYear: date.year(), dates }, () => {
            M.Collapsible.init(document.querySelectorAll('.collapsible'), {});
        })
    }

    render() {
        let { loadingDoc, dates } = this.state;
        let anios = Object.keys(dates).sort(function (a, b) { return b - a });
        let contrato = this.props.currentContrato ? this.props.currentContrato.Contrato : "";
        let currentDate = moment();


        return (
            <div className="row">
                <div className="row">
                    <ul className="collapsible">
                        {
                            anios.map((av, i) => {
                                let dataAnio = dates[av];
                                return (
                                    <li key={i}
                                        className={`${currentDate.year() === parseInt(av) ? 'active' : ""} `}
                                    >
                                        <div className="collapsible-header">{av}</div>
                                        <div className="collapsible-body paddingCero">
                                            <div className="row bgGray noMBottom" style={{ height: '30px' }}>
                                                <div className="col s3 center-align headerHistorico">
                                                    Mes
                                                </div>
                                                <div className="col s3 center-align headerHistorico">
                                                    Año
                                                </div>
                                                <div className="col s3">

                                                </div>
                                                <div className="col s3">

                                                </div>
                                            </div>
                                            <div className="row">
                                                {
                                                    dataAnio.map((v, j) => {
                                                     
                                                        return (
                                                            <React.Fragment key={j}>
                                                                <div className="col s3 borderRight paddingTopBI"
                                                                    style={{ height: '30px' }}>
                                                                    {v.name}
                                                                </div>
                                                                <div className="col s3 borderRight paddingTopBI"
                                                                    style={{ height: '30px' }}>
                                                                    {av}
                                                                </div>
                                                                <div className="col s3 center-align borderRight paddingTopBI"
                                                                    style={{ height: '30px' }}>
                                                                    <span
                                                                        className={`cursorPointer ${loadingDoc ?
                                                                            'brandTextPrimaryUnderlineDisabled' : 'brandTextPrimaryUnderline'} `}
                                                                        disabled={loadingDoc}
                                                                        onClick={() => {
                                                                            if (!loadingDoc) {
                                                                                this.setState({ loadingDoc: true }, () => {
                                                                                    this.props.blockReturn(true);
                                                                                    this.downloadPDF(`${contrato}_${av}${v.month}`, TIPOS_DOCS.PDF, av, v.month);
                                                                                });
                                                                            }

                                                                        }}
                                                                    >PDF</span>
                                                                </div>
                                                                <div className="col s3 center-align paddingTopBI"
                                                                    style={{ height: '30px' }}>
                                                                    <span
                                                                        className={`cursorPointer ${loadingDoc ?
                                                                            'brandTextPrimaryUnderlineDisabled' : 'brandTextPrimaryUnderline'} `}
                                                                        disabled={loadingDoc}
                                                                        onClick={() => {
                                                                            if (!loadingDoc) {
                                                                                this.setState({ loadingDoc: true }, () => {
                                                                                    this.props.blockReturn(true);
                                                                                    this.downloadPDF(`${contrato}_${av}${v.month}`, TIPOS_DOCS.XML,av, v.month);
                                                                                });
                                                                            }
                                                                        }}
                                                                    >XML</span>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <div className="row">
                                                <div className="col s12 center-align">
                                                    {
                                                        loadingDoc ?
                                                            <Reload action={() => { }} /> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                {/* <div className="row">
                    {
                        months.map((v, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="col s3 borderRight paddingTopBI"
                                        style={{ height: '30px' }}>
                                        {v.name}
                                    </div>
                                    <div className="col s3 borderRight paddingTopBI"
                                        style={{ height: '30px' }}>
                                        {currentYear}
                                    </div>
                                    <div className="col s3 center-align borderRight paddingTopBI"
                                        style={{ height: '30px' }}>
                                        <span
                                            className={`cursorPointer ${loadingDoc ?
                                                'brandTextPrimaryUnderlineDisabled' : 'brandTextPrimaryUnderline'} `}
                                            disabled={loadingDoc}
                                            onClick={() => {
                                                if (!loadingDoc) {
                                                    this.setState({ loadingDoc: true }, () => {
                                                        this.downloadPDF(`${contrato}_${currentYear}${v.month}`, TIPOS_DOCS.PDF);
                                                    });
                                                }

                                            }}
                                        >PDF</span>
                                    </div>
                                    <div className="col s3 center-align paddingTopBI"
                                        style={{ height: '30px' }}>
                                        <span
                                            className={`cursorPointer ${loadingDoc ?
                                                'brandTextPrimaryUnderlineDisabled' : 'brandTextPrimaryUnderline'} `}
                                            disabled={loadingDoc}
                                            onClick={() => {
                                                if (!loadingDoc) {
                                                    this.setState({ loadingDoc: true }, () => {
                                                        this.downloadPDF(`${contrato}_${currentYear}${v.month}`, TIPOS_DOCS.XML);
                                                    });
                                                }
                                            }}
                                        >XML</span>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div> */}

            </div>

        )
    }

    downloadPDF = async (nameDoc, type, anio , mes) => {
        try {
            let resultDoc = await this.getEdo(nameDoc, type, anio, mes);
            
            if (resultDoc && resultDoc.response.Exito === 200) {
                const downloadLink = document.createElement("a");
                const fileName = `${nameDoc}.${type.toLowerCase()}`;
                let linkSource = `data:application/pdf;base64,${resultDoc.response.Documento}`;
                if (type === TIPOS_DOCS.XML) {
                    linkSource = `data:application/xml;base64,${resultDoc.response.Documento}`;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }else{
                    this.setState({ loadingDoc: false },()=>{
                        this.props.blockReturn(false);
                        this.props.setOperacion({ opt: "pdfViewer", title: "Estado de cuenta", data: { link: linkSource, nameDoc } })
                    });
                }
               
                this.setState({ loadingDoc: false },()=>{
                    this.props.blockReturn(false);
                });

            } else {
                this.setState({ loadingDoc: false }, () => {
                    this.props.blockReturn(false);
                    this.props.okButton({
                        message: "No se localiza el documento que desea consultar, por favor intente más tarde.", title: "Advertencia", txtButton: "Entendido",
                        statusCode: resultDoc ? resultDoc.response.Exito : 404
                    });
                });
            }



        } catch (error) {

            this.setState({ loadingDoc: false },()=>{
                this.props.blockReturn(false);
            });
        }


    }

    getEdo = async (nameDoc, type, anio, mes) => {

        try {
            let bodyEstado = SERVICES.GET_DOCUMENTO.body;
            bodyEstado.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyEstado.NombreDoc = encryptValues(nameDoc);
            bodyEstado.TipoDoc = type
            bodyEstado.Anio = encryptValues(anio);
            bodyEstado.Mes = encryptValues(mes);
            bodyEstado.Tipo = "E";
            bodyEstado.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");

            bodyEstado.seccion = 1;
     
            let Documento = "";
        
            let resultEstado = await makeCall(SERVICES.GET_DOCUMENTO, bodyEstado);

            if(resultEstado && resultEstado.response.Secciones ){
                let seccionI = bodyEstado.seccion;
                Documento += resultEstado.response.Documento; 
                while(seccionI < resultEstado.response.Secciones){
                    seccionI+=1;
                    bodyEstado.seccion = seccionI;
                    let resulEstadoPlus = await makeCall(SERVICES.GET_DOCUMENTO, bodyEstado);
                    if(resulEstadoPlus && resulEstadoPlus.response){

                        Documento += resulEstadoPlus.response.Documento; 
                    }
                }
                resultEstado.response.Documento = decryptValue(Documento);
            }

            return resultEstado;
        } catch (error) {
            return false;
        }

    }

}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};



export default connect(mapStateToProps, { okButton, setOperacion })(Historico);