import React, { Component } from 'react';
import { ICONS } from '../assets/icons';
import { PATHS, SERVICES } from '../properties';
import { encryptValues, deleteSesion } from '../utils';
import { connect } from 'react-redux';

class SinAutorizacion extends Component {

    componentDidMount() {
        let bodyCerrarSesion = SERVICES.TERMINA_SESION.body;
        if (this.props.dataUser) {
            bodyCerrarSesion.idUsuario = encryptValues(this.props.dataUser.IdUsuario.toLowerCase());

            deleteSesion(this.props.dataUser.IdUsuario.toLowerCase(), this.props);
        }

    }

    render() {
        return (
            <div className="row">
                <div className="col s12 center-align mt-3">
                    <img src={ICONS.NO_SERVICES} alt="Sin atorización" />
                </div>
                <div className="col s12 center-align">
                    <p>Su sesión ha expirado inicie sesión nuevamente.</p>
                </div>
                <div className="col s12 center-align">
                    <button className="btn primaryBtn waves-effect"
                        onClick={() => {
                            if(this.props.history){
                                this.props.history.push(PATHS.LOGIN) 
                            }else{
                                window.location.href = `${PATHS.LOGIN}`;
                            }
                           
                        }}
                    >Iniciar Sesión</button>
                </div>

            </div>
        )
    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, optionSelected
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        optionSelected = state.commonsActions.optionSelected || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history, optionSelected };
};

export default connect(mapStateToProps, {})(SinAutorizacion);