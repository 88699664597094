import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideToken, okButton, hide_okButton } from '../redux/actions';
import { onlyDigits, makeCall, encryptValues, deleteSesion } from '../utils';
import { SERVICES } from '../properties';
import Logo from '../assets/VALMEX_LOGO.png';
import Reload from './reload';
import M from 'materialize-css';

class Token extends Component {

    state = {
        val1: "",
        val2: "",
        val3: "",
        val4: "",
        val5: "",
        val6: "",
        val7: "",
        val8: "",
        loading: false,
        disabledButton: true,
        intentosToken: 0
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.getValidation === false && (this.props && this.props.getValidation)) {
            this.setState({
                val1: "",
                val2: "",
                val3: "",
                val4: "",
                val5: "",
                val6: "",
                val7: "",
                val8: "",
                loading: false,
                disabledButton: true
            })
        }
    }

    componentDidMount = () => {

    }

    reenviaCodigo = async () => {
        try {
         
            let bodySolicitaCodigo = SERVICES.SOLICITA_CODIGO.body;
            bodySolicitaCodigo.IdUsuario = encryptValues(this.props.getValidation.idUsuario);
            bodySolicitaCodigo.Passcode = encryptValues(this.props.getValidation.passCode);
            bodySolicitaCodigo.Celular = encryptValues(this.props.getValidation.celular);
            bodySolicitaCodigo.Accion = encryptValues(this.props.getValidation.accion);
           
           

            // bodySolicitaCodigo.Client_id = encryptValues(window.config.CLIENT_ID);
            // bodySolicitaCodigo.Client_secr = encryptValues(window.config.CLIENT_SECRET);

            let resultSolicitaCodigo = await makeCall(SERVICES.SOLICITA_CODIGO, bodySolicitaCodigo);

            if (resultSolicitaCodigo.response && resultSolicitaCodigo.response.Exito !== 200) {
                this.props.okButton({
                    message: "No se logro solicitar el código de acceso intente de nuevo.",
                    statusCode: 404,
                    title: "Advertencia", txtButton: "Entendido"
                });
            }
            if (resultSolicitaCodigo.response && resultSolicitaCodigo.response.Exito === 200) {
                M.toast({html:' Código enviado <i class="material-icons right">check_circle</i>', classes:'green darken-3 white-text boldText'})
            }
        } catch (error) {
            this.props.okButton({
                message: "No se logro solicitar el código de acceso intente de nuevo.",
                statusCode: 404,
                title: "Advertencia", txtButton: "Entendido"
            });
        }
    }

    validToken = async (event) => {
        event.preventDefault();
        // debugger;
        let { val1, val2, val3, val4, val5, val6, intentosToken } = this.state;
        let serviceToCall = SERVICES.CHECK_TOKEN;
        let body = SERVICES.CHECK_TOKEN.body;
        body.IdUsuario = encryptValues(this.props.getValidation ? this.props.getValidation.idUsuario : "");
        body.Codigo = encryptValues(`${val1}${val2}${val3}${val4}${val5}${val6}`);
        body.Accion = encryptValues(this.props.getValidation ? this.props.getValidation.accion : "C");

        // body.Client_id = encryptValues(window.config.CLIENT_ID);
        // body.Client_secr = encryptValues(window.config.CLIENT_SECRET);


        if (this.props.getValidation.onlyPass) {
            this.setState({ loading: true }, async () => {
                await this.props.getValidation.actionClick(`${val1}${val2}${val3}${val4}${val5}${val6}`);
                this.props.hideToken();
            });

        } else {
            this.setState({ loading: true }, async () => {
                try {
                    let result = await makeCall(serviceToCall, body);
                        
                    if (result && result.response) {
                        if (parseInt(result.response.CodigoValido) === 1) {
                            if (this.props.getValidation) {
                                await this.props.getValidation.actionClick();
                            }
                            this.props.hideToken();
                        } else {

                            if (intentosToken >= 3) {
                                //Bloqueo de usuario.
                                let bodyBloque = SERVICES.BLOQUEO_USUARIO.body;
                                bodyBloque.Bloqueo = 1;
                                bodyBloque.IdUsuario = encryptValues(this.props.getValidation.idUsuario);

                                let resultBloqueo = await makeCall(SERVICES.BLOQUEO_USUARIO, bodyBloque);

                                if (resultBloqueo && resultBloqueo.response) {
                                    if (resultBloqueo.response.Exito === 200) {
                                        this.setState({ loading: false }, () => {
                                            //M.toast({ html: "Usuario Bloqueado.", classes: 'red darken-3' });
                                            this.props.okButton({
                                                message: "Apreciable cliente, su cuenta ha sido bloqueada. Para tener acceso a las plataformas digitales realice el proceso de desbloqueo.", title: "", txtButton: "Entendido",
                                                statusCode: 404,
                                                actionClick: () => {
                                                    this.props.hide_okButton();
                                                    this.props.hideToken();
                                                }
                                            });

                                        });
                                    } else {
                                        this.setState({ loading: false }, () => {
                                            this.props.okButton({
                                                message: "Error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                                                statusCode: 404,
                                                actionClick: () => {
                                                    this.props.hide_okButton();
                                                    this.props.hideToken();
                                                }
                                            });
                                            // M.toast({ html: "Error inesperado intente de nuevo.", classes: 'red darken-3' });
                                            // this.props.hideToken();
                                        });
                                    }
                                } else {
                                    this.setState({ loading: false }, () => {
                                        // M.toast({ html: "Error inesperado intente de nuevo.", classes: 'red darken-3' });
                                        // this.props.hideToken();
                                        this.props.okButton({
                                            message: "Error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                                            statusCode: 404,
                                            actionClick: () => {
                                                this.props.hide_okButton();
                                                this.props.hideToken();
                                            }
                                        });
                                    });
                                }


                            } else {
                                this.setState({ loading: false, intentosToken }, () => {
                                    this.props.okButton({
                                        message: "Apreciable cliente, el código especificado no es correcto. Por favor intente nuevamente.", title: "", txtButton: "Entendido",
                                        statusCode: 404,
                                        actionClick: () => {
                                            this.props.hide_okButton();
                                            this.props.hideToken();
                                        }
                                    });
                                    // M.toast({ html: "Código incorrecto.", classes: 'red darken-3' })
                                });
                            }


                        }
                    } else {
                        this.setState({ loading: false });
                    }

                } catch (error) {
                    this.setState({ loading: false });
                }
            })
        }

    }

    changeValueToken = (event) => {
        let { id, value } = event.target;
        if (value.trim() !== "" && onlyDigits(value)) {
            let nextInput = document.getElementById(`${parseInt(id) + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        } else if (value.trim() === "") {
            let lastInput = document.getElementById(`${parseInt(id) - 1}`);
            if (lastInput) {
                lastInput.focus();
            }

        }
    }

    keyPress = (event) => {

        let { keyCode, key } = event;
        let { id } = event.target;

        if (keyCode === 8 || key.toLowerCase() === "backspace") {

            let lastInput = document.getElementById(`${parseInt(id) - 1}`);
            if (lastInput) {

                lastInput.focus();
            }
            this.setState({ [`val${id}`]: "" }, () => {
                this.validForm();
            });
        } else {
            if (onlyDigits(key)) {
                let nextInput = document.getElementById(`${parseInt(id) + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
                this.setState({ [`val${id}`]: key }, () => {
                    this.validForm();
                });
            }
        }
    }

    validForm = () => {
        let { val1, val2, val3, val4, val5, val6 } = this.state;
        if (`${val1}${val2}${val3}${val4}${val5}${val6}`.trim().length === 6) {
            this.setDisabledButton(false);
        } else {
            this.setState({ disabledButton: true });
        }
    }

    validInputs = () =>{
        let { val1, val2, val3, val4, val5, val6 } = this.state;
        if (`${val1}${val2}${val3}${val4}${val5}${val6}`.trim().length === 6) {
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { val1, val2, val3, val4, val5, val6, loading, disabledButton } = this.state;
        return (
            <div id="modal_token" className="modal modal_token">
                <div className="closeModalOk left-align">
                    <i className="material-icons cursorPointer" onClick={() => {
                        if (this.props.getValidation && this.props.getValidation.isLogin) {
                            deleteSesion(this.props.getValidation.idUsuario, {});
                        }
                        this.props.hideToken();
                    }}>arrow_back</i>
                </div>
                <form className="modal-content" onSubmit={this.validToken}>
                    <div className="row">
                        <div className="col s12 center-align">
                            <img src={Logo} alt="VALMEX" className="responsive-img maxh-100" />
                        </div>
                        <div className="col s12 center-align">
                            <h5 className="brandTextPrimary">{

                                this.props.getValidation.title || ""
                            }</h5>
                        </div>
                        {
                            this.props.getValidation.message ?
                                <div className="col s12 center-align">
                                    {this.props.getValidation.message}
                                </div>

                                : null
                        }
                    </div>
                    <div className="row tokenInputs">
                        <div className="col s2">
                            <input type="text" id="1"
                                value={val1}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                        <div className="col s2">
                            <input type="text" id="2"
                                value={val2}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                        <div className="col s2">
                            <input type="text" id="3"
                                value={val3}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                        <div className="col s2">
                            <input type="text" id="4"
                                value={val4}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                        <div className="col s2">
                            <input type="text" id="5"
                                value={val5}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                        <div className="col s2">
                            <input type="text" id="6"
                                value={val6}
                                maxLength="1"
                                onKeyDown={this.keyPress}
                            ></input>
                        </div>
                    </div>
                    {
                        this.props.getValidation.onlyPass ?
                            null
                            :
                            <div className="row">
                                {
                                    this.props.getValidation.esRobo ?
                                        <div className="col s12 center-align">
                                            <p className="noMargin">Apreciable cliente, Ingresa el código </p>
                                            <p className="noMargin"> que recibió por correo electrónico.</p>
                                        </div>
                                        :

                                        <div className="col s12 center-align">
                                            <p className="noMargin">Apreciable cliente, Ingrese el código</p>
                                            <p className="noMargin"> que recibió por sms</p>
                                        </div>
                                }


                            </div>
                    }

                    <div className="row">
                        {
                            this.props.getValidation.muestraAceptaGeoWeb ?
                                <div className='row ft-12'>
                                    <div className="col s12 align-justify" >

                                    Apreciable cliente, en términos de las fracciones X y XV de las Disposiciones de carácter general a que se refiere el artículo 212 de la Ley del Mercado de Valores, se requiere dé su consentimiento para poder obtener la ubicación de su dispositivo electrónico a través del cual realice operaciones mediante el uso página electrónica de Valores Mexicanos Casa de Bolsa, S.A. de C.V., en caso contrario podrá realizar sus operaciones como normalmente la lleva a cabo por medio de su promotor.
                                    </div>
                                    <div className="col s12 switch mt-1 center-align">
                                        Aceptar términos
                                        <label className="">
                                            <input type="checkbox" id="aceptaGeo" 
                                             onChange={this.onChangeValueCheck}
                                             checked={this.state.aceptaGeo || false}
                                            />
                                            <span className="lever"></span>
                                        </label>
                                    </div>
                                </div>

                    : null
                        }

                        {
                            (this.props.getValidation.hasOwnProperty('esOperacion')) ?
                                <div></div>

                            :  <div className="col s12 center-align mt-1">
                            <span className='ft-12 NavyPrimary'>
                                    De clic <span className='brandTextPrimary cursorPointer'
                                        onClick={() => {
                                            this.reenviaCodigo();
                                        }}
                                    >aquí</span> para enviar nuevamente el código a su mail.
                                </span>
                            </div>
                        }

                       


                    <div className="col s12 center-align mt-2">
                        {
                            loading ?
                                <Reload action={() => { }} />

                                :
                                <button className="btn primaryBtn waves-effect"
                                    disabled={disabledButton} >

                                    {
                                        this.props.getValidation.txtButton ?
                                            `${this.props.getValidation.txtButton} `
                                            : 'Enviar'
                                    } <i className="material-icons right">send</i>

                                </button>
                        }

                    </div>
            </div>

                
                </form >
            </div >
        )
    }

    setDisabledButton = (value) => {

        

            if(this.props.getValidation.muestraAceptaGeoWeb){
                if(!this.state.aceptaGeo){
                    this.setState({disabledButton:true});
                }else{
                    this.setState({disabledButton:false});
                }
            }else{
                this.setState({disabledButton:value});   
            }
    

    }

    onChangeValueCheck = (event) => {

     
        let { id } = event.target;
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let disabledButton = this.validInputs() && value ? false : true;

        this.setState({[id]: value, disabledButton});
    }

}


const mapStateToProps = state => {
    let { getValidation } = false;
    if (state.commonsActions) {
        getValidation = state.commonsActions.getValidation || false;
    }

    return { getValidation };
};


export default connect(mapStateToProps, { hideToken, okButton, hide_okButton })(Token)