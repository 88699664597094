
import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';


class ModalInfo extends Component {

    componentDidMount(){
         M.Modal.init(document.querySelectorAll('.modal'), {});
    }

    render() {
        return (
            <div id="modalInfo" className={`modal ${this.props.infoModalData.estilo  ? this.props.infoModalData.estilo : "modalInfo"}`}>
                <div className="modal-content">
                    {
                        this.props.infoModalData.toRender ?
                            this.props.infoModalData.toRender()
                        : 

                        <div className='row'>
                        <div className='col s12 center-align'>
                            <p className='ft-24'>{this.props.infoModalData.title}</p>
                            <p className='ft-16'>{this.props.infoModalData.text}</p>
                            {
                                this.props.infoModalData.text2 ? 
                                <p className='ft-16'>{this.props.infoModalData.text2}</p>
                                : null
                            }
                        </div>
                        <div className='col s12 center-align mt-1'>
                            {
                                this.props.infoModalData.NoBtn ? 
                                null
                                :
                                <button className='btn primaryBtn modal-close' onClick={()=>{
                                    if(this.props.infoModalData.actionBtn){
                                        this.props.infoModalData.actionBtn();
                                    }
                                }}>{
                                    this.props.infoModalData.txtButton ? 
                                    this.props.infoModalData.txtButton : 'Continuar' }</button>
                            }
                           
                        </div>
                    </div>

                    }
                  
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    let { infoModalData
    } = false;

    if (state.commonsActions) {
        infoModalData = state.commonsActions.infoModalData || false;
    }


    return { infoModalData };
};


export default connect(mapStateToProps,{})(ModalInfo);