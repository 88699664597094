import React, { Component } from 'react';
import { ICONS } from '../assets/icons';

class NotFound extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 center-align">
                        
                        <h1>404</h1>
                        <img src={ICONS.NO_SERVICES} alt="Servicios no disponibles" />
                        <h2>Página no encontrada</h2>
                    </div>
                </div>
            </div>
        );
    }
}
export default NotFound;