import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showToken, setHistory, okButton, hide_okButton, setTokenToPass } from '../redux/actions';
import { PATHS, SERVICES, ACCION_CODIGO } from '../properties'
import { encryptValues, validateEmail, onlyDigits } from '../utils';
import firebase from 'firebase';
import Reload from './reload';
import { makeCall } from '../utils';
import logoSvg from '../assets/logo.svg';

class DesbloqueCuenta extends Component {

    state = {
        email: "",
        contrato: "",
        erroremail: false,
        errorcontrato: false,
        loading: false,
        disabledForm: true,
        responseIdentidad: false,
        esRobo: false,
        esDesbloqueo: false,
        title: 'Desbloquear Cuenta'
    }

    componentDidMount() {

        let title = '';
        let esRobo = false;
        let esDesbloqueo = false;
        if (this.props.location && this.props.location.pathname) {
            if (this.props.location.pathname.includes(PATHS.DESBLOQUEO)) {
                esDesbloqueo = true;
                title = 'Desbloquear Cuenta';
            } else if (this.props.location.pathname.includes(PATHS.OLVIDE_PASS)) {
                esDesbloqueo = true;
                title = 'Olvidé mi Contraseña';
            } else if (this.props.location.pathname.includes(PATHS.ROBO_EXTRAVIO)) {
                esRobo = true;
                title = 'Reporte de robo o extravío';
            }

            this.setState({ title, esRobo, esDesbloqueo });
        }
        this.props.setHistory(this.props.history);
    }

    onChangeData = (event) => {
        let { id, value } = event.target;
        // console.log(value);
        this.setState({ [`error${id}`]: false }, () => {

            if (id === "email") {
                value = value.trim().toLowerCase();
            }

            if (id === "contrato") {
                if (!onlyDigits(value)) {
                    return false;
                }
            }

            this.setState({ [id]: value }, () => {
                if (id === "email") {
                    if (!validateEmail(value)) {
                        this.setState({ erroremail: "Escribir un correo válido." });
                    }
                }
            });
        });

    }

    submitData = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        let { contrato, email, esRobo, esDesbloqueo } = this.state;

        if (contrato.trim() !== "" && email.trim() !== "") {
            //  debugger;
            //valida la identidad del usuario
       
            let accionSolicitud = ACCION_CODIGO.ACCESO;

            if (esRobo) {
                accionSolicitud = ACCION_CODIGO.ROBO;
            } else if (esDesbloqueo) {
                accionSolicitud = ACCION_CODIGO.RECUPERACION;
            }

            let bodyIdentidad = SERVICES.VALIDA_IDENTIDAD.body;
            bodyIdentidad.IdUsuario = encryptValues(email.trim().toLowerCase());
            bodyIdentidad.Contrato = encryptValues(contrato.trim());
            bodyIdentidad.Accion = encryptValues(accionSolicitud);
            firebase.analytics().logEvent('Login_verificar_correo');

            // bodyIdentidad.Client_id = encryptValues(window.config.CLIENT_ID);
            // bodyIdentidad.Client_secr = encryptValues(window.config.CLIENT_SECRET);

            let resultIdentidad = await makeCall(SERVICES.VALIDA_IDENTIDAD, bodyIdentidad);
            
            if (resultIdentidad && resultIdentidad.response && resultIdentidad.response.Exito === 200) {

                
                let bodySolicitaCodigo = SERVICES.SOLICITA_CODIGO.body;
                bodySolicitaCodigo.IdUsuario = encryptValues(email.trim().toLowerCase());
                bodySolicitaCodigo.Passcode = encryptValues(resultIdentidad.response.PassCode);
                bodySolicitaCodigo.Celular = encryptValues(resultIdentidad.response.Celular);

                let accionSolicitaCodigo = ACCION_CODIGO.RECUPERA_ACCION_WEB;

                if (esRobo) {
                    accionSolicitaCodigo = ACCION_CODIGO.ROBO;
                } 



                bodySolicitaCodigo.Accion = encryptValues(accionSolicitaCodigo);

                // bodySolicitaCodigo.Client_id = encryptValues(window.config.CLIENT_ID);
                // bodySolicitaCodigo.Client_secr = encryptValues(window.config.CLIENT_SECRET);

                let resultSolicitaCodigo = false;
                //if (esRobo) {
                resultSolicitaCodigo = await makeCall(SERVICES.SOLICITA_CODIGO, bodySolicitaCodigo);
                //}


                if (esDesbloqueo || (resultSolicitaCodigo && resultSolicitaCodigo.response)) {

                    if (resultSolicitaCodigo.response.Exito === 407) {
                        this.setState({ loading: false });
                        this.props.okButton({
                            message: resultSolicitaCodigo.response.MensajeError,
                            title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                    } else {




                        if (esDesbloqueo || resultSolicitaCodigo.response.Exito === 200) {
                            this.setState({ loading: false });
                           
                            let accionValidaCodigo = ACCION_CODIGO.RECUPERA_ACCION_WEB;

                            if (esRobo) {
                                accionValidaCodigo = ACCION_CODIGO.ROBO;
                            }

                            this.props.showToken({
                                idUsuario: email.trim().toLowerCase(),
                                accion: accionValidaCodigo,
                                celular: resultIdentidad.response.Celular,
                                passCode: resultIdentidad.response.PassCode,
                                txtButton: esRobo ? 'Bloquear cuenta' : "Validar",
                                esRobo,
                                onlyPass: esRobo ? false : true,
                                message: esRobo ? "" : 'Apreciable cliente, ingrese un código de validación generado enviado al mail registrado.',
                                title: "Código de verificación", actionClick: async (softToken) => {
                                    //Bloquea la cuenta

                                    if (esRobo) {

                                        this.props.okButton({
                                            message: esRobo ? "Apreciable cliente, su cuenta ha sido bloqueada con éxito. Para tener acceso a las plataformas digitales realice el proceso de desbloqueo." :
                                                "Apreciable cliente, su cuenta ha sido desbloqueada con éxito.",
                                            statusCode: 200, title: "", txtButton: "Entendido",
                                            actionClick: () => {

                                                this.props.history.push(PATHS.LOGIN);

                                                this.props.hide_okButton();
                                            }
                                        });

                                    } else {
                                     
                                        let accionCheckCodigo = ACCION_CODIGO.RECUPERA_ACCION_WEB;

                                        let bodyValidaToken = SERVICES.CHECK_TOKEN.body;
                                        bodyValidaToken.IdUsuario = encryptValues(email.trim().toLowerCase());
                                        bodyValidaToken.Codigo = encryptValues(softToken);
                                        bodyValidaToken.Accion = encryptValues(accionCheckCodigo);

                                        // bodyValidaToken.Client_id = encryptValues(window.config.CLIENT_ID);
                                        // bodyValidaToken.Client_secr = encryptValues(window.config.CLIENT_SECRET);

                                        let resultValidaToken = await makeCall(SERVICES.CHECK_TOKEN, bodyValidaToken);

                                        if (resultValidaToken && (resultValidaToken.response.Exito === 200 || resultValidaToken.response.Exito === 201)) {
                                            firebase.analytics().logEvent('Login_valida_codigo');
                                            this.props.setTokenToPass(Math.random().toString(36).substring(7));
                                            this.props.history.push(`${PATHS.RESET_PASS_REDIRECT}${email.trim().toLowerCase()}`);
                                        } else {
                                            this.props.okButton({
                                                message: "Ocurrió un error inesperado al validar su token intente de nuevo.",
                                                title: "Advertencia", txtButton: "Entendido",
                                                statusCode: 404
                                            });
                                        }


                                    }

                                }
                            });
                        }
                    }

                } else {
                    this.setState({ loading: false }, () => {
                        this.props.okButton({
                            message: "Ocurrió un error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                    })

                }

            } else {
                this.setState({ loading: false }, () => {
                    if (resultIdentidad.response && resultIdentidad.response.MensajeError) {
                        this.props.okButton({
                            message: 'Apreciable cliente, los datos especificados no son correctos.', title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                    } else {
                        this.props.okButton({
                            message: "Ocurrió un error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404
                        });
                    }


                });

            }

        } else {
            this.setState({ loading: false });
        }
    }


    submitDataRecibeCodigo = async (event) => {
        this.setState({ loading: true });

        event.preventDefault();
        let { responseIdentidad, email } = this.state;

        if (responseIdentidad && email && email.trim() !== "") {

            this.setState({ loading: false });
            this.props.showToken({
                idUsuario: email.trim().toLowerCase(), accion: ACCION_CODIGO.ACTIVACION,
                message: "Apreciable cliente, ingrese un código de validación generado con la aplicación Valmex Móvil.",
                txtButton: 'Validar',
                onlyPass: true,
                title: "Código de verificación", actionClick: async () => {
                    firebase.analytics().logEvent('Login_valida_codigo');
                    this.props.setTokenToPass(Math.random().toString(36).substring(7));
                    this.props.history.push(`${PATHS.RESET_PASS_REDIRECT}${email.trim().toLowerCase()}`);
                }
            });


            firebase.analytics().logEvent('Login_boton_verificar_telefono');



        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        let { email, contrato, erroremail, errorcontrato,
            loading, responseIdentidad, title, esRobo } = this.state;

        if (responseIdentidad) {
            return (
                <div className="unlockContainer valign-wrapper greyBackground">
                    <div className="row blockUnlock">
                        <div className="col s12 navyBackground white-text headerContent">
                            <i className="material-icons cursorPointer left"
                                onClick={() => {
                                    this.props.history.push(PATHS.LOGIN);
                                }}
                            >keyboard_backspace</i> {title}
                        </div>
                        <form onSubmit={this.submitDataRecibeCodigo} className="col s12" >
                            <div className="row mt-10px">
                                <div className="col s12 center-align">
                                    <img src={logoSvg} alt="VALMEX" style={{ maxWidth: '70px' }} />
                                </div>
                                {
                                    esRobo
                                        ?
                                        <div
                                            className="col s12 mb-10 left-align  ft-16"
                                        >Se enviará un código a este correo electrónico para bloquear la cuenta</div>
                                        :
                                        <div
                                            className="col s12 mb-10 center-align brandTextPrimary boldText ft-20"
                                        >Verifica tu número de teléfono.</div>
                                }


                                {
                                    esRobo ?
                                        <div className="input-field col s12 mb-0px mt-10px">
                                            <input id="correo" type="text" value={email.trim().toLowerCase()}
                                            />
                                            <label htmlFor="correo">Correo electrónico:</label>
                                        </div>

                                        :

                                        <div className="input-field col s12 mb-0px mt-10px">
                                            <input id="celular" type="text" value={this.replaceAs(responseIdentidad.Celular, 0, 8, '*********')}
                                            />
                                            <label htmlFor="celular">Teléfono:</label>
                                        </div>
                                }


                                {/* <div className="col s12 center-align mt-8">
                                        Se enviará un código a este número de teléfono
                                </div> */}

                                <div className="col s12 center-align mt-1">
                                    {
                                        loading ?
                                            <Reload action={() => { }} />

                                            :
                                            <button className="btn primaryBtn">Continuar</button>
                                    }

                                </div>
                                {
                                    esRobo ?
                                        <div className="col s12 center-align mt-1 brandTextPrimary boldText cursorPointer">
                                            No es mi correo electrónico</div>
                                        :
                                        <div className="col s12 center-align mt-1 brandTextPrimary boldText">
                                            No es mi número de teléfono</div>
                                }



                            </div>
                        </form>
                    </div>
                </div>
            )
        }

        return (
            <div className="unlockContainer valign-wrapper greyBackground">
                <div className="row blockUnlock">
                    <div className="col s12 navyBackground white-text headerContent">
                        <i className="material-icons cursorPointer left"
                            onClick={() => {
                                this.props.history.push(PATHS.LOGIN);
                            }}
                        >keyboard_backspace</i> {title}
                    </div>
                    <form onSubmit={this.submitData} className="col s12" >
                        <div className="row mt-10px">
                            <div className="col s12 mb-10">Ingresa la información de tu contrato para {esRobo ? 'bloquear' : 'desbloquear'} tu cuenta.</div>

                            <div className="input-field col s12 mb-0px mt-10px">
                                <input id="email" type="text" value={email.toLowerCase()} className={
                                    erroremail ? "invalid" : ""
                                } onChange={this.onChangeData}

                                />
                                <label htmlFor="email">Correo electrónico</label>
                                <span className="helper-text" data-error={erroremail} ></span>
                            </div>

                            <div className="input-field col s12">
                                <input id="contrato" type="text"
                                    value={contrato} onChange={this.onChangeData}
                                    disabled={erroremail || email.trim() === ""}
                                    className={
                                        errorcontrato ? "invalid" : ""
                                    } />
                                <label htmlFor="contrato">Contrato</label>
                                <span className="helper-text" data-error={errorcontrato} ></span>
                            </div>

                            <div className="col s12 center-align mt-9">
                                {
                                    loading ?
                                        <Reload action={() => { }} />

                                        :
                                        <button className="btn primaryBtn"
                                            disabled={erroremail || errorcontrato ||
                                                email.trim() === "" || contrato.trim() === ""
                                            }
                                        >Validar datos</button>
                                }

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        )
    }

    replaceAs = (origin, startIndex, endIndex, insertion) => {
        return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
    }

}

export default connect(null, {
    showToken, setHistory,
    okButton, hide_okButton,
    setTokenToPass
})(DesbloqueCuenta)
