import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHistory, setOperacion, setOptionSelectedMenu } from '../redux/actions';
import { ID_CUENTAS_REGISTRADAS } from '../properties';
import { decryptValueNot } from '../utils';
import firebase from 'firebase';
import moment from 'moment';
import M from 'materialize-css';
const TXT = "En esta sección se visualizan las notificaciones de todos sus contrato.";

class Notificaciones extends Component {

    state = {
        notificaciones: [],
        currentNot: false,
    }

    async componentDidMount() {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
        this.props.setHistory(this.props.history);
        try {
            let timeConfig = await this.getConfigTime();
            await this.getNotifications(timeConfig);
        } catch (error) {
        }
    }

    getConfigTime = async () =>{
        try {
            let timeConfig = await firebase.firestore().doc('configuraciones/notificaciones').get();

            if(timeConfig.exists && timeConfig.data().dias_mostrar){
                return timeConfig.data().dias_mostrar;
            }else{
                return 30;
            }
        } catch (error) {
            return 30;
        }
    }

    getNotifications = async (timeConfig) => {
        try {
            let userNot = this.props.dataUser ? this.props.dataUser.IdUsuario.toLowerCase() : "";
            let notData = await firebase.firestore().collection('NotificacionesPush').doc(userNot).get();
            if (notData.exists) {

                let notificaciones = notData.data().Notificaciones;
                if (notificaciones.length) {

                    const hoy = new Date(); // Fecha actual
                    const dias = timeConfig * 24 * 60 * 60 * 1000; // días en milisegundos

                    // notificaciones.forEach((not,i)=>{
                    //     console.log(new firebase.firestore.Timestamp(not.fecha.seconds, not.fecha.nanoseconds).toDate());
                    // });

                    let newNot = notificaciones.filter((not,i)=>{
                        const fechaElem = new firebase.firestore.Timestamp(not.fecha.seconds, not.fecha.nanoseconds).toDate();

                        const diff = hoy - fechaElem; // Diferencia en milisegundos entre la fecha actual y la fecha del elemento
                        return diff <= dias;
                    }).sort((a, b) => {
                        let dateB = new firebase.firestore.Timestamp(b.fecha.seconds, b.fecha.nanoseconds).toDate();
                        let dateA = new firebase.firestore.Timestamp(a.fecha.seconds, a.fecha.nanoseconds).toDate();
                        return dateB - dateA;
                    });

                    if(newNot.length){
                        this.setState({ notificaciones: newNot, currentNot: newNot[0] });
                    }else{
                        this.setState({ notificaciones: newNot, currentNot: false });
                    }

                   
                }
            }
        } catch (error) {

        }
    }


    onSelect = (notification) => {
        // console.log(notification);
        this.setState({ currentNot: notification })
    }

    getTextNotification = (currentNot) => {
        try {

            return (

                <div className="col s12 mt-1 contentNotification"
                    dangerouslySetInnerHTML={{ __html: atob(currentNot.notification.body).replaceAll('\n', '<br/>') }}
                >


                </div>
            )

        } catch (error) {
            return (

                <div className="col s12 mt-1 contentNotification">
                    {currentNot.notification.body}

                </div>
            )
        }
    }

    render() {
        let { currentNot } = this.state;
        return (

            <div className="row">
                <div className="col s4 m4 l3 xl3">
                    <div className="row rightShadow">
                        <div className="col s12 closeModalOk ntitleContent">
                            <span className="title-n">Notificaciones <i className="material-icons iconF f-r cursorPointer tooltipped"
                                            data-position="bottom" data-tooltip={TXT}
                                        >help_outline</i></span>
                        </div>
                        <div className="col s12 contentNotifications">
                            {
                                this.state.notificaciones.length ?
                                    this.state.notificaciones.map((not, i) => {

                                        let v = not.notification;
                                        
                                        let date = moment(new firebase.firestore.Timestamp(not.fecha.seconds, not.fecha.nanoseconds).toDate())
                                            .format('DD/MM/YYYY h:mm');
                                        return (
                                            <div className={`card notificacionContainer hoverContent ${v === currentNot.notification ? 'activeNot' : ''}`}
                                                key={i}>
                                                <div className="card-content" style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => this.setState({ currentNot: not })}
                                                >
                                                    <div className="row" >
                                                        {
                                                            not.contract ? 
                                                            <div className="col s12">
                                                            <span className="titleNot">
                                                                 {decryptValueNot(not.contract) !== '' ? `No. de Contrato: ${decryptValueNot(not.contract)}` : '' }
                                                            </span>
                                                        </div>
                                                            : null
                                                        }
                                                       
                                                        <div className="col s12 ft-16 fw-400">
                                                            <span className="truncate">{v.title}</span>
                                                        </div>
                                                        <div className="col s6">
                                                            <span className="ft-12 truncate fw-300">{date.toString()} hrs</span>
                                                        </div>
                                                        <div className="col s6 right-align">
                                                            <span className="ft-12 fw-300">{not.plataforma}</span>
                                                        </div>
                                                      


                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="col s12 center-align mt-1">
                                        No cuenta con notificaciones por el momento.
                                    </div>
                            }
                        </div>
                    </div>

                </div>
                <div className="col s8 m8 l9 xl9">
                    {
                        currentNot ?
                            <div className="row mt-1 ml-10">
                                <div className="col s12 ft-20">

                                </div>
                                <div className="col s12 ft-12 mt-1">
                                    <span>{moment(new firebase.firestore.Timestamp(currentNot.fecha.seconds, currentNot.fecha.nanoseconds).toDate())
                                        .format('DD/MM/YYYY h:mm')} {currentNot.plataforma}</span>
                                </div>
                                {
                                    this.getTextNotification(currentNot)
                                }

                                <div className="col s12 mt-1">
                                    {
                                        currentNot.notification.action && currentNot.notification.action.pagina && currentNot.notification.action.pagina !== "" ?
                                            <p className="link cursorPointer" onClick={this.mostrarOperacion}>De clic aquí para consultar el detalle.
                                                {/* {currentNot.notification.action.pagina} */}
                                            </p>
                                            : ""
                                    }
                                </div>
                            </div>
                            : null
                    }
                </div>

            </div>
        )

    }

    mostrarOperacion = () => {
        const { currentNot } = this.state;

        const pagina = currentNot.notification.action.pagina.toLowerCase() || "";

        if (pagina === 'terminos') {
            this.props.setOperacion({
                title: `Términos y Condiciones`,
                opt: "terminos_condiciones"
            })
        } else if (pagina === 'movimientos' || pagina.includes('movimiento')) {
            this.props.setOperacion({
                opt: "movimientos", title: "Movimientos", startDate: moment(new firebase.firestore.Timestamp(currentNot.fecha.seconds, currentNot.fecha.nanoseconds).toDate())
                    .format('DD/MM/YYYY')
            })
        } else if (pagina.includes('constancia') || pagina.includes('estadocuenta')
            || pagina.includes('estadodecuenta')
        ) {
            this.props.setOperacion({ opt: "documentos", title: "Documentos" })
        } else if (pagina.includes('privacidad')) {
            this.props.setOperacion({
                title: `Aviso de privacidad`,
                opt: "aviso_privacidad"
            })
        } else if (pagina.includes('chequera')) {

            let instance = M.Sidenav.getInstance(document.querySelectorAll('.sidenavUser')[0]);
            if (instance) {
                instance.open();
                document.getElementById(ID_CUENTAS_REGISTRADAS).click();



            }
        }
    }
}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false
    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history };
};

export default connect(mapStateToProps,
    {
        setHistory,
        setOperacion,
        setOptionSelectedMenu
    }
)(Notificaciones)