import {
    SET_DATA_USER,
    SET_CONTRATOS,
    SET_CURRENT_CONTRATO,
    SET_CHEQUERA
} from '../actionTypes';

//import M from 'materialize-css';


const servicesActions = (state = {}, action) => {
    switch (action.type) {
        case SET_DATA_USER: {
            let dataUser = action.payload;
            return { ...state, dataUser }
        }
        case SET_CONTRATOS: {
            let contratosUser = action.payload;
            return { ...state, contratosUser }
        }
        case SET_CURRENT_CONTRATO: {
            let currentContrato = action.payload;
            return { ...state, currentContrato }
        }
        case SET_CHEQUERA: {
            const chequera = action.payload;
   
            return { ...state, chequera }
        }
        default: {
            return state;
        }

    }

}

export default servicesActions;