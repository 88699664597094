import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeCall, encryptValues, deleteSesion } from '../utils';
import { SERVICES } from '../properties';
import { setCurrentContrato, setContratos, setDataUser, setChequera } from '../redux/actionsServices';
import { recargaCartera, setOptionSelectedMenu } from '../redux/actions';

class RemueveContrato extends Component {

    remueveContrato = async () => {
        if (this.props.contratoARemover && this.props.currentContrato.Contrato) {

            let body = SERVICES.BAJA_CONTRATO.body;
            body.IdUsuario = encryptValues(this.props.dataUser.IdUsuario);
            body.Contrato = encryptValues(this.props.contratoARemover);
            body.DeviceToken = this.props.dataUser.deviceToken;
            try {

                let result = await makeCall(SERVICES.BAJA_CONTRATO,body); 
            
                if (result) {
             
                    let contratos = this.props.contratosUser.TTCONTRATOS['TT-CONTRATOS'];
                    //remueve el contrato del array de contratos
                    contratos = contratos.filter(contrato => contrato.Contrato !== this.props.contratoARemover);
                    //actualiza el array de contratos

                    if (contratos.length) {
                       

                        this.props.setCurrentContrato(false); 
                        
                        this.props.setOptionSelectedMenu(false);
                        this.props.recargaCartera(true);
                    }else{
                        deleteSesion(this.props.dataUser.IdUsuario, this.props);
                    }

                }

            } catch (error) {

            }

        }
    }

    render() {
        return (
            <div id="remueveContratoModal" className="modal modal-fixed-footer remueveContratoModal">
                <div className="modal-content center-align">
                    <h4 className="boldText">Remover Contrato</h4>
                    <h4 className="boldText">{this.props.contratoARemover ? this.props.contratoARemover : ""}</h4>
                    <p>Está acción dará de baja su acceso y ya no podrá hacer uso de los servicios digítales en App Móvil y Web</p>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect btn-flat flatPrimary">Ahora no</a>
                    <button className="modal-close waves-effect btn-flat primaryBtn_oc btn ml-10 white-text"
                        onClick={this.remueveContrato}
                    >Remover</button>
                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    let { dataUser, currentContrato, contratosUser, contratoARemover } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false;
        contratosUser = state.servicesActions.contratosUser || false;
    }

    if (state.commonsActions) {
        contratoARemover = state.commonsActions.contratoARemover || false;
    }

    return { dataUser, currentContrato, contratosUser, contratoARemover };
};

export default connect(mapStateToProps, {
    setCurrentContrato,
    recargaCartera,
    setOptionSelectedMenu,
    setContratos, setDataUser, setChequera 
})(RemueveContrato);