

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteSesion } from '../utils';
import { PATHS } from '../properties';
import {
    okButton, setFingerPrint, setCoordenadas, setIP, setOptionSelectedMenu, cargaInfo, serviciosNoDisponibles, setSaldoTotal,
    setRendimientos, setTiposServicios, setPosicionCartera, recargaCartera, setSaldoLiquido, setSaldoOperacion, hide_okButton
} from '../redux/actions';
import { setDataUser, setContratos, setCurrentContrato, setChequera } from '../redux/actionsServices';


class SesionInactiva extends Component {

    state = {
        inactividadTimer: false,
        sesionTimer: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       

        if (prevProps.dataUser === false && this.props.dataUser) {
       

            document.addEventListener('mousemove', this.reiniciarInactividadAlAccionar);
            document.addEventListener('keypress', this.reiniciarInactividadAlAccionar);


            this.reiniciarTimers();
        } 
    }

    reiniciarInactividadTimer = () => {
        clearTimeout(this.state.inactividadTimer);
        let inactividadTimer = setTimeout(async () => {
            //setSesionExpirada(true);
            if (this.props.dataUser) {
              
                deleteSesion(this.props.dataUser.IdUsuario, this.props);
                this.props.okButton({
                    message: "Tu sesión  ha expirado por inactividad , te invitamos a ingresar nuevamente.", title: "Fin de Sesión", txtButton: "Entendido",
                    actionClick: () => {
                        this.props.history.push(PATHS.LOGIN);
                        this.props.hide_okButton();
                    },
                    statusCode: 404
                });
                //await cerrarSesion(appData);
                //dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_INACTIVIDAD }))
            }
        }, 5 * 60 * 1000);

        this.setState({ inactividadTimer })
    };

    reiniciarSesionTimer = () => {

        clearTimeout(this.state.sesionTimer);
        let sesionTimer = setTimeout(async () => {

            //setSesionExpirada(true);
            if (this.props.dataUser) {
                deleteSesion(this.props.dataUser.IdUsuario, this.props);

                this.props.okButton({
                    message: "Has superado el tiempo máximo de sesión permitida, te invitamos a ingresar nuevamente.", title: "Fin de Sesión", txtButton: "Entendido",
                    actionClick: () => {

                        this.props.history.push(PATHS.LOGIN);
                        this.props.hide_okButton();
                    },
                    statusCode: 404
                });
                //await cerrarSesion(appData);
                // dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
            }
        }, 20 * 60 * 1000);

        this.setState({ sesionTimer });
    };

    reiniciarInactividadAlAccionar = () => {
        
        clearTimeout(this.state.inactividadTimer);
        this.reiniciarInactividadTimer();
    };

    reiniciarTimers = () => {

        this.reiniciarInactividadTimer();
        this.reiniciarSesionTimer();
    };

    render() {
        return (
            <div hidden>

            </div>
        )
    }

}


const mapStateToProps = state => {
    let { dataUser, history } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
    }


    if (state.commonsActions) {
        history = state.commonsActions.history || false;
    }

    return {
        dataUser, history
    };
};

export default connect(mapStateToProps,
    {
        okButton,
        hide_okButton,
        setFingerPrint,
        setDataUser,
        setContratos,
        setCurrentContrato,
        setChequera,
        setCoordenadas,
        setIP,
        setOptionSelectedMenu,
        cargaInfo,
        serviciosNoDisponibles,
        setSaldoTotal,
        setRendimientos,
        setTiposServicios,
        setPosicionCartera,
        recargaCartera,
        setSaldoLiquido,
        setSaldoOperacion
    }
)(SesionInactiva)

