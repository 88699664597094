import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICONS } from '../../assets/icons';
import { showToken, okButton, recargaCartera, setCoordenadas } from '../../redux/actions';
import { OPERACION_EMISORA_FONDO, SERVICES, TIPOS_CHEQUERAS, ACCION_CODIGO } from '../../properties';
import {
    makeCall, nWithCommas, encryptValues, getSaldoLiquido, decryptValue, getComision, getIva, getCoordenadas,
    shareDetail,openTip,closeTip
} from '../../utils';
import Reload from '../reload';
import M from 'materialize-css';
import firebase from 'firebase';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Venta extends Component {

    state = {
        importe: "",
        fondos: false,
        fondo: false,
        cuentaSelected: false,
        errorMsg: false,
        fondoSelected: "",
        titulos: false,
        total: false,
        saldoLiquido: false,
        sendingData: false,
        titulosPosicion: false,
        posicionTotal: false,
        saldoTotal: 0,
        esSaldoLiquido: false,
        saldoOperacion: 0,
    }


    async componentDidMount() {
        try {
            await this.getEmisoraFondos();
            let saldoOperacion = await getSaldoLiquido({
                dataUser: this.props.dataUser,
                currentContrato: this.props.currentContrato,
                TipoSaldo: "D"
            });
            this.setState({ saldoOperacion });

        } catch (error) {

        }
        M.updateTextFields();
    }

    getEmisoraFondos = async () => {
        try {
            openTip();
            let bodyEmisoraFondos = SERVICES.EMISORA_FONDOS.body;
            bodyEmisoraFondos.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyEmisoraFondos.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyEmisoraFondos.TipoOper = OPERACION_EMISORA_FONDO.VENTA;

            let result = await makeCall(SERVICES.EMISORA_FONDOS, bodyEmisoraFondos);

            if (result && result.response && result.response.Exito === 200) {
                this.setState({ fondos: result.response['TTFONDOS']['TT-FONDOS'] });
            } else {
                this.setState({ fondos: [] });
            }

            
            closeTip();
        } catch (error) {

        }
    }

    getPosicionEmisora = async (emisora) => {
        try {

            let bodyPosicionEmisora = SERVICES.POSICION_EMISORA.body;
            bodyPosicionEmisora.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
            bodyPosicionEmisora.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
            bodyPosicionEmisora.Emisora = emisora.Emisora;
            bodyPosicionEmisora.Serie = emisora.Serie;

            let result = await makeCall(SERVICES.POSICION_EMISORA, bodyPosicionEmisora);

            if (result && result.response && result.response.Exito === 200) {
                let titulosPosicion = parseInt(result.response.Titulos);
                this.setState({ titulosPosicion })
            } else {

            }

        } catch (error) {

        }
    }

    onChangeValueCheck = (event) => {
        let { id } = event.target;
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        if (value) {
            firebase.analytics().logEvent('opera_venta_total');
            // this.setState({importe:this.state.cuentaSelected.ImpMax},()=>{
            //     M.updateTextFields();
            // });

        } else {
            this.setState({ total: false, titulos: false });
        }
        this.setState({ [id]: value }, () => {
            this.ventaPosicionTotal();
        });
    }

    ventaPosicionTotal = () => {

        let { posicionTotal, fondo, esSaldoLiquido, cuentaSelected } = this.state;

        if (posicionTotal && fondo) {

            let importeCalculado = parseFloat(parseFloat(fondo.Precio * fondo.Titulos).toFixed(2));

            let comision = getComision(fondo, importeCalculado);
            let iva = getIva(fondo, comision);
            let total = parseFloat(importeCalculado - (comision + iva)).toFixed(2);

            let errorMsg = false;

            if (fondo && !esSaldoLiquido) {
                if (parseFloat(total) > fondo.MontoMaximo) {
                    errorMsg = `El monto máximo de venta es de $${nWithCommas(`${fondo.MontoMaximo}`)}.`;
                }

                if (parseFloat(total) < fondo.MontoMinimo) {
                    errorMsg = `El monto mínimo de venta es de $${nWithCommas(`${fondo.MontoMinimo}`)}.`;
                }

                if (fondo.Titulos > fondo.TitulosMaximo) {
                    errorMsg = `Los títulos máximos a vender son ${nWithCommas(`${fondo.TitulosMaximo}`)}.`;
                }

                if (fondo.Titulos < fondo.TitulosMinimo) {
                    errorMsg = `Los títulos mínimos a vender son ${nWithCommas(`${fondo.TitulosMinimo}`)}.`;
                }
            }


            if (cuentaSelected && !esSaldoLiquido) {
                if (parseFloat(total) > parseFloat(cuentaSelected.ImpMax)) {
                    errorMsg = `El monto de venta es mayor al máximo permitido $${nWithCommas(`${parseFloat(cuentaSelected.ImpMax).toFixed(2)}`)}, verifique la configuración de sus chequeras. `
                }
            }


            if (parseFloat(total) <= 0) {
                errorMsg = "Indique un monto válido mayor a $0.00.";
            }

            let validaHorarioErr = this.validaHorario();

            if (!validaHorarioErr) {

                this.setState({
                    titulos: fondo.Titulos, total, importe: total, importeCalculado, comision: parseFloat(comision).toFixed(2), iva,
                    errorMsg
                }, () => {
                    setTimeout(() => {
                        M.updateTextFields();
                    }, 100)
                })
            } else {

                this.setState({ errorMsg: validaHorarioErr });
            }
        }


    }

    onChangeValue = async (event) => {
        let { fondo, posicionTotal, importe } = this.state;
        let { id, value } = event.target;
        let errorMsg = false;


        if (id === 'fondoSelected' && value.trim() !== "") {
            let { fondos } = this.state;
            let f = fondos[parseInt(value.trim())];
            fondo = f;
        } else if (id === 'fondoSelected' && value.trim() === "") {
            fondo = false;
        }

        this.setState({ [id]: value, errorMsg, fondo }, () => {

            posicionTotal ? this.ventaPosicionTotal() : this.getTotales();

        });
    }


    validaHorario = () => {
        let { fondo } = this.state;
        let errorMsg = false;

        if (!fondo) {
            errorMsg = "Seleccióne un fondo.";
        } else {
            let f = fondo;

            let fechaActual = new Date();
            let ha = fechaActual.getHours();  //hora actual
            let ma = fechaActual.getMinutes(); //minuto actual

            if (!f.FondoAbierto || !f.VtaAbierta) {
                errorMsg = "El fondo  está cerrado para operaciones, por favor intente más tarde";
            } else if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) < Date.parse(`01/01/2011 ${f.HApertura}:00`)) {
                errorMsg = `El horario de operación es de ${f.HApertura} a ${f.HCierre} Hrs. Horario de la Ciudad de México (GMT-6), por favor intente después.`;
            }
            else if (
                Date.parse(`01/01/2011 ${ha}:${ma}:00`) > Date.parse(`01/01/2011 ${f.HCierre}:00`)
            ) {
                errorMsg = `El horario de operación es de ${f.HApertura} a ${f.HCierre} Hrs. Horario de la Ciudad de México (GMT-6), por favor intente después.`;
            }

        }


        return errorMsg;
    }

    getTotales = () => {

        let { importe, fondo, esSaldoLiquido, cuentaSelected } = this.state;


        let validaHorarioErr = this.validaHorario();

        if (validaHorarioErr) {
            this.setState({ errorMsg: validaHorarioErr });
        } else {

            let importeCalculado = parseFloat(importe);

            let titulos = Math.floor(parseFloat(importeCalculado) / fondo.Precio);
            importeCalculado = parseFloat(parseFloat(fondo.Precio * titulos).toFixed(2));
            let comision = getComision(fondo, importeCalculado);
            let iva = getIva(fondo, comision);
            let total = parseFloat(parseFloat(importeCalculado - (comision + iva)).toFixed(2));

            if (titulos > fondo.Titulos) {
                this.setState({
                    titulos: false, total: false, comision: 0,
                    iva: 0, importeCalculado: 0, errorMsg: 'No se cuenta con títulos suficientes para realizar la operación.'
                });
            } else {
                if (total < parseFloat(importe)) {

                    while (total < parseFloat(importe)) {
                        titulos = titulos + 1;
                        importeCalculado = parseFloat(parseFloat(fondo.Precio * titulos).toFixed(2));
                        comision = getComision(fondo, importeCalculado);
                        iva = getIva(fondo, comision);
                        total = parseFloat(parseFloat(importeCalculado - (comision + iva)).toFixed(2));
                    }

                } else {
                    total = parseFloat(fondo.Precio * titulos).toFixed(2);
                    importeCalculado = total;
                }

                let errorMsg = false;

                if (fondo && parseFloat(importe) > 0 && !esSaldoLiquido) {
                    if (parseFloat(importe) > fondo.MontoMaximo) {
                        errorMsg = `El monto máximo de venta es de $${nWithCommas(`${parseFloat(fondo.MontoMaximo).toFixed(2)}`)}.`;
                    }

                    if (parseFloat(importe) < fondo.MontoMinimo) {
                        errorMsg = `El monto mínimo de venta es de $${nWithCommas(`${parseFloat(fondo.MontoMinimo).toFixed(2)}`)}.`;
                    }

                    if (titulos > fondo.TitulosMaximo) {
                        errorMsg = `Los títulos máximos a vender son ${nWithCommas(`${fondo.TitulosMaximo}`)}.`;
                    }

                    if (titulos < fondo.TitulosMinimo) {
                        errorMsg = `Los títulos mínimos a vender son ${nWithCommas(`${fondo.TitulosMinimo}`)}.`;
                    }
                }

                if (cuentaSelected && !esSaldoLiquido) {
                    if (parseFloat(total) > parseFloat(cuentaSelected.ImpMax)) {
                        errorMsg = `El monto de venta es mayor al máximo permitido $${nWithCommas(`${parseFloat(cuentaSelected.ImpMax).toFixed(2)}`)}, verifique la configuración de sus chequeras. `
                    }
                }


                if (parseFloat(total) <= 0) {
                    errorMsg = "Indique un monto válido mayor a $0.00.";
                }

                this.setState({ titulos, total, importeCalculado, comision: parseFloat(comision).toFixed(2), iva, errorMsg })

            }
        }

    }

    render() {

        let { importe,
            operacionExitosa,
            detail,
            fondos,
            fondoSelected,
            cuentaSelected,
            errorMsg,
            titulos,
            importeCalculado,
            iva,
            comision,
            total,
            sendingData,
            posicionTotal,
            esSaldoLiquido,
            fondo
        } = this.state;


        if (!fondos) {
            return (
                <div className="row marginLR-1r">
                    <div className="col s12 center-align mt-1">
                        <Reload action={() => { }} />
                    </div>
                </div>
            )
        }

        if (fondos && !fondos.length) {
            return (
                <div className="row marginLR-1r">
                    <div className="col s12 center-align mt-1">
                        No se encontrarón fondos para realizar compras.
                    </div>
                </div>
            )
        }

        if (operacionExitosa && detail.length) {
            return (
                <React.Fragment>
                    <div className="row marginLR-1r" id="detalleRecibo">
                        <div id="cabeceraComprobante">
                        <div className="col s2 center-align">
                            <img src={ICONS.LOGO_CIRCULO} alt="LOGO" />
                        </div>
                        <div className="col s6" style={{ paddingTop: "1.5rem" }}>
                            <span>Operación de Venta</span>
                        </div>
                        <div className='col s4 right-align' style={{ paddingTop: "1.5rem" }}>
                        <span>{moment().format('yyyy/MM/DD HH:mm')}</span>
                        </div>
                    </div>

                        {
                            detail.map((v, i) => {
                                let detalle = v;
                                return (
                                    <div className="col s12 mt-1 detailCard" key={i}>
                                        <div className="row containerDetalleOpera">
                                            <div className="col s12 navyBackground white-text" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: "30px", paddingTop: "5px" }}>
                                                {
                                                    detalle.Emisora && detalle.Emisora.trim() === "" ?
                                                        <span>Saldo Líquido</span>
                                                        :
                                                        <React.Fragment>
                                                            {
                                                                detalle.Emisora ?
                                                                    <span>{detalle.Emisora}</span>
                                                                    :
                                                                    <React.Fragment>
                                                                        <span>{cuentaSelected.DescBanco}</span>  <span className="floatRight">*****{`${cuentaSelected.Cuenta}`.substring(`${cuentaSelected.Cuenta}`.length - 4)}</span>
                                                                    </React.Fragment>
                                                            }

                                                            {/* <span>{cuentaSelected.DescBanco}</span>  <span className="floatRight">*****{`${cuentaSelected.Cuenta}`.substring(`${cuentaSelected.Cuenta}`.length - 4)}</span> */}
                                                        </React.Fragment>
                                                }
                                                 <span className="floatRight"> ${detalle.Importe.trim()}</span>

                                            </div>

                                            <div className="col s12">
                                                <p className="noMBottom ft-10">Tipo de Operación</p>
                                                <p className="noMTop">{detalle.TipoOper}</p>
                                            </div>

                                            {
                                                detalle.Emisora && detalle.Emisora.trim() !== "" ?

                                                    <div className="col s6 bgGray">
                                                        <p className="noMBottom ft-10">Títulos</p>
                                                        <p className={`noMTop ${detalle.Titulos ? '' : 'txtGray'}`}>
                                                            {detalle.Titulos ? detalle.Titulos : '_'}</p>
                                                    </div>

                                                    : <div className="col s6 bgGray">
                                                        <p className="noMBottom ft-10">Títulos</p>
                                                        <p className={`noMTop ${detalle.Titulos ? '' : 'txtGray'}`}>
                                                            {detalle.Titulos ? detalle.Titulos : '_'}</p>
                                                    </div>
                                            }
                                            <div className={detalle.Emisora && detalle.Emisora.trim() === "" ? 'col s12 bgGray' : 'col s6 bgGray'}>
                                                <p className="noMBottom ft-10">Precio</p>
                                                <p className="noMTop">${fondo.Precio}</p>
                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Comisión</p>
                                                {
                                                    detalle.Comision !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.Comision)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }
                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">IVA</p>
                                                {
                                                    detalle.IVA !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.IVA)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }
                                            </div>

                                            <div className="col s12 left-align bgGray">
                                                <p className="noMBottom ft-10">Neto Liquidar</p>
                                                {
                                                    detalle.NetoLiquidar !== null ?
                                                        <p className="noMTop">${decryptValue(detalle.NetoLiquidar)}</p>
                                                        : <p className="noMTop">$0.00</p>
                                                }
                                            </div>

                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Fecha de operación</p>
                                                <p className="noMTop">{detalle.FOper}</p>
                                            </div>
                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">Hora de operación</p>
                                                <p className="noMTop">{detalle.Hora}</p>
                                            </div>
                                            <div className="col s12 bgGray">
                                                <p className="noMBottom ft-10">Fecha de liquidación</p>
                                                <p className="noMTop">{detalle.FLiq}</p>
                                            </div>
                                            <div className="col s6 left-align">
                                                <p className="noMBottom ft-10">No. de contrato</p>
                                                <p className="noMTop">{this.props.currentContrato ? this.props.currentContrato.Contrato : ""}</p>
                                            </div>
                                            <div className="col s6 left-align ">
                                                <p className="noMBottom ft-10">Folio</p>
                                                <p className="noMTop">{detalle.Folio}</p>
                                            </div>
                                            <div className="col s12 bgGray"
                                                style={{
                                                    borderBottomLeftRadius: '10px',
                                                    borderBottomRightRadius: '10px'
                                                }}>
                                                <p className="noMBottom ft-10">Servicio de Inversión</p>
                                                <p className={`noMTop ${detalle.TipoServicio ? '' : 'txtGray'}`}
                                                >{detalle.TipoServicio && detalle.TipoServicio.trim() !== "" ?
                                                    decryptValue(detalle.TipoServicio) : "_"
                                                    }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                        <div className="col s12 ft-12" id="footerCardDetail">
                            Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                        </div>
                    </div>
                    <div className="col s12 center-align">
                        <button onClick={() => {
                            shareDetail('detalleRecibo');
                        }} className="btn primaryBtn_oc width100 boldText mb-1" >Compartir</button>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <div className="row marginLR-1r">

                <div className="col s12 mt-1">
                    <span><img src={ICONS.UNO} alt="icon uno" /></span>  Selecciona un fondo:
                </div>
                <div className="input-field col s12 mt-1">
                    <select className="browser-default" id='fondoSelected' onChange={this.onChangeValue} value={fondoSelected}>
                        <option value="">Selecciona una opción</option>
                        {
                            fondos.map((v, i) => {
                                return (
                                    <option key={i} value={i}>{v.Emisora} - {v.Precio} (Liquida {parseInt(v.Plazo) > 0 ? `en ${v.Plazo} días` : `hoy`})</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.DOS} alt="icon dos" /></span>  Indica el importe:
                </div>
                <div className="col s12 switch mt-2 maxRetiro">
                    Vender posición total
                    <label className="floatRight">
                        <input type="checkbox"
                            id="posicionTotal"
                            onChange={this.onChangeValueCheck}
                            checked={posicionTotal}
                            value={posicionTotal} />
                        <span className="lever"></span>
                    </label>
                </div>
                <div className="input-field col s12 mt-1">

                    <NumberFormat
                        id="importe"
                        thousandSeparator={','}
                        decimalSeparator="."
                        value={importe}
                        isNumericString
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        onClick={() => {

                            firebase.analytics().logEvent('opera_venta_importe');
                        }}
                        disabled={posicionTotal}
                        onValueChange={(values) => {

                            let errorMsg = false;

                            if (parseFloat(values.value) <= 0) {
                                errorMsg = "Indique un monto válido mayor a $0.00.";
                            }

                            let validaHorarioErr = this.validaHorario();

                            if (validaHorarioErr) {
                                errorMsg = validaHorarioErr;
                            }

                            this.setState({ importe: values.value, errorMsg }, () => {
                                if (!errorMsg) {
                                    posicionTotal ? this.ventaPosicionTotal() : this.getTotales();
                                }

                            })

                        }}
                    />

                    <label htmlFor="importe">Importe</label>
                    {errorMsg ?
                        <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                        : null}
                </div>
                {
                    titulos && fondoSelected && !errorMsg ?
                        <React.Fragment>
                            <div className="col s6 right-align">
                                Títulos
                            </div>
                            <div className="col s6 right-align boldText">
                                {nWithCommas(`${titulos}`)}
                            </div>
                            <div className="col s6 right-align">
                                Importe
                            </div>
                            <div className="col s6 right-align boldText">

                                ${importeCalculado !== null ? nWithCommas(`${importeCalculado}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                Comisión
                            </div>
                            <div className="col s6 right-align boldText">

                                ${comision !== null ? nWithCommas(`${comision}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                IVA
                            </div>
                            <div className="col s6 right-align boldText">

                                ${iva !== null ? nWithCommas(`${iva}`) : 0.00}
                            </div>
                            <div className="col s6 right-align">
                                Neto a liquidar
                            </div>
                            <div className="col s6 right-align boldText">
                                ${nWithCommas(`${total}`)}
                            </div>
                        </React.Fragment>
                        : null
                }

                <div className="col s12 mt-1">
                    <div className='row'>
                        <div className='col s1'>
                            <label><i className="material-icons left">info_outline</i></label>
                        </div>
                        <div className='col s11'>
                            <p className='noMargin'> <label> Para fondos que no liquidan el mismo día, las cantidades de precio, títulos, importe, comisión, IVA y neto a liquidar, pueden cambiar de acuerdo al precio de cierre de día. La instrucción se gira al amparo del servicio de Ejecución de Operaciones.</label></p>
                        </div>
                    </div>
                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.TRES} alt="icon tres" /></span>  Seleccione una cuenta para enviarle el efectivo de la venta:
                </div>

                <div className="col s12 mt-1">
                    <div className="row boxDepositoRetiro">
                        {
                            this.getTipoChequera(TIPOS_CHEQUERAS.RETIRO)
                        }
                    </div>
                </div>
                {
                    total && (esSaldoLiquido || cuentaSelected && fondoSelected.trim() !== "")
                        ?
                        <div className="col s12 center-align">
                            {
                                sendingData ?

                                    <Reload action={() => { }} />

                                    : <button className="btn primaryBtn_oc width100 boldText"
                                        onClick={this.sendData}
                                        disabled={parseFloat(total) <= 0 || errorMsg}
                                    >Vender</button>
                            }

                        </div>
                        : null
                }

            </div>
        )
    }

    sendData = async () => {
        try {

            let { fondo, cuentaSelected, titulos, posicionTotal, esSaldoLiquido, importe } = this.state;
            if (await getCoordenadas(this.props)) {
                this.props.showToken({
                    idUsuario: this.props.dataUser.IdUsuario, accion: ACCION_CODIGO.ACCESO,
                    title: "Código de Seguridad",
                    message: "Ingresar el código de seguridad generado en tu aplicación móvil (token) para validar tu operación.",
                    txtButton: "Confirmar Operación",
                    onlyPass: true,
                    esOperacion: true,
                    actionClick: async (softToken) => {
                        let P = await new Promise(async (resolve) => {
                            try {
                                openTip();
                                let bodyOperafondo = SERVICES.OPERA_FONDOS.body;
                                bodyOperafondo.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
                                bodyOperafondo.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
                                bodyOperafondo.TipoOper = OPERACION_EMISORA_FONDO.VENTA;
                                bodyOperafondo.VentaTotal = posicionTotal ? 1 : 0;
                                bodyOperafondo.Emisora = fondo.Emisora;
                                bodyOperafondo.Serie = fondo.Serie;
                                bodyOperafondo.Importe = importe;
                                bodyOperafondo.Precio = fondo.Precio;
                                bodyOperafondo.Titulos = titulos;
                                bodyOperafondo.TipoLiq = esSaldoLiquido ? 'S' : 'Q';
                                bodyOperafondo.Titular = !esSaldoLiquido ? cuentaSelected.Titular : null;;
                                bodyOperafondo.Banco = !esSaldoLiquido ? cuentaSelected.Banco : null;
                                bodyOperafondo.Cuenta = !esSaldoLiquido ? encryptValues(cuentaSelected.Cuenta) : null;
                                bodyOperafondo.SoftToken = encryptValues(softToken);

                                bodyOperafondo.Latitud = encryptValues(this.props.coordenadas.latitude);
                                bodyOperafondo.Longitud = encryptValues(this.props.coordenadas.longitude);
                                bodyOperafondo.Pais = encryptValues(this.props.coordenadas.pais);
                                bodyOperafondo.Ciudad = encryptValues(this.props.coordenadas.ciudad);
                                bodyOperafondo.Estado = encryptValues(this.props.coordenadas.estado);
                                bodyOperafondo.Direccion = encryptValues(this.props.coordenadas.direccion);
                                bodyOperafondo.IP = encryptValues(this.props.ip);

                                bodyOperafondo.DeviceToken = this.props.dataUser ? this.props.dataUser.deviceToken : "";

                                let resultOperaFondo = await makeCall(SERVICES.OPERA_FONDOS, bodyOperafondo);
                          

                                if (resultOperaFondo && resultOperaFondo.response) {
                                    closeTip();

                                    if (resultOperaFondo.response.Exito === 409) {
                                        this.setState({ errorMsg: resultOperaFondo.response.MensajeError })
                                    } else if (resultOperaFondo.response.Exito !== 200) {
                                        this.setState({ errorMsg: resultOperaFondo.response.MensajeError });

                                    } else if (resultOperaFondo.response.Exito === 408) {
                                        this.props.okButton({
                                            message: resultOperaFondo.response.MensajeError,
                                            txtButton: "Entendido",
                                            actionClick: () => {
                                                getCoordenadas(this.props);
                                            }
                                        })
                                    }
                                    else {
                                        let detail = resultOperaFondo.response['TTOPERACIONES']['TT-OPERACIONES'];

                                        let instance = M.FormSelect.getInstance(document.getElementById('fondoSelected'));
                                        if (instance) {
                                            instance.destroy();
                                        }

                                        this.setState({ operacionExitosa: true, detail }, () => {
                                            this.props.recargaCartera(true);
                                        });
                                    }

                                    this.setState({ sendingData: false });
                                    firebase.analytics().logEvent('Opera_venta_f');
                                    resolve(true);
                                } else {
                                    this.setState({ sendingData: false });
                                    closeTip();
                                    this.setState({ errorMsg: "Ocurrió un error inesperado intente nuevamente." });
                                    resolve(true);
                                }

                            } catch (error) {
                                this.setState({ sendingData: false });
                                closeTip();
                                this.setState({ errorMsg: "Ocurrió un error inesperado intente nuevamente." })
                                resolve(true);

                            }
                        });


                        return P;

                    }
                });
            }
        } catch (error) {

        }
    }

    getTipoChequera = (tipo) => {
        let { cuentaSelected, saldoOperacion, posicionTotal } = this.state;
        //let {saldoOperacion} = this.props;

        if (this.props.chequera && this.props.chequera.length) {
            let filterChequera = this.props.chequera.filter((v, i) => {
                if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato) {
                    return v;
                }
            });

            // if (filterChequera.length) {


            return (<div className="col s12">
                <ul className="collection">
                    <li className="collection-item row hoverContent" onClick={() => {
                        this.setState({ esSaldoLiquido: true, cuentaSelected: false }, () => {
                            posicionTotal ? this.ventaPosicionTotal() : this.getTotales();
                        });
                    }}>
                        <div className="row mb-0px">
                            <div className="col s10">
                                <div className="row mb-0px">
                                    <div className="col s12 left-align">
                                        Saldo líquido
                                    </div>
                                    <div className="col s12 left-align boldText mt-1">
                                        Contrato - {this.props.currentContrato ? this.props.currentContrato.Contrato : ""}
                                    </div>
                                    <div className="col s12 left-align">
                                        ${nWithCommas(parseFloat(saldoOperacion.totalSaldoMXP ?? 0).toFixed(2))}
                                    </div>
                                    <div className="col s12 left-align mt-1">
                                        Cuentas bancarias (chequeras)
                                    </div>
                                </div>

                            </div>
                            <div className="col s2 center-align">
                                {
                                    this.state.esSaldoLiquido ?
                                        <img src={ICONS.CHECK_BLACK} className="mt-1" alt='Check'
                                            style={{ marginRight: '1.2rem' }}
                                        />
                                        : <img src={ICONS.CIRCLE_OUTLINE} className="mt-1" alt='Check' style={{ marginRight: '1.2rem' }} />
                                }

                            </div>
                        </div>

                    </li>
                    {
                        filterChequera.map((v, i) => {
                            return (
                                <li className={`${cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                    'cuentaSelected '
                                    : ""} collection-item row hoverContent`} onClick={() => {
                                        firebase.analytics().logEvent('opera_venta_f_cta');
                                        if (v.HoraFin && v.HoraIni) {

                                            let fechaActual = new Date();
                                            let ha = fechaActual.getHours();  //hora actual
                                            let ma = fechaActual.getMinutes(); //minuto actual

                                            if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) < Date.parse(`01/01/2011 ${v.HoraIni}:00`)) {

                                                // this.props.okButton({
                                                //     message: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.`,
                                                //     title: "Advertencia", txtButton: "Ok",
                                                //     statusCode: 404
                                                // });

                                                this.setState({ errorMsg: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.` });

                                            } else if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) > Date.parse(`01/01/2011 ${v.HoraFin}:00`)) {
                                                // this.props.okButton({
                                                //     message: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.`,
                                                //     title: "Advertencia", txtButton: "Ok",
                                                //     statusCode: 404
                                                // });

                                                this.setState({ errorMsg: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.` });
                                            }
                                            else {
                                                this.setState({ cuentaSelected: v, esSaldoLiquido: false }, () => {
                                                    posicionTotal ? this.ventaPosicionTotal() : this.getTotales();
                                                });
                                            }

                                        } else {
                                            this.setState({ cuentaSelected: v, esSaldoLiquido: false }, () => {
                                                posicionTotal ? this.ventaPosicionTotal() : this.getTotales();
                                            });
                                        }
                                    }
                                    } key={i}>
                                    <div className="col s10">
                                        <p style={{ marginTop: '0px', marginBottom: '1px' }}>
                                            <span className="title">{v.DescBanco} - *****{`${v.Cuenta}`.substring(`${v.Cuenta}`.length - 4)}</span>
                                        </p>
                                        <p style={{
                                            textTransform: 'capitalize',
                                            fontSize: '12px',
                                            marginTop: '0px'
                                        }}>{v.Nombre.toLowerCase()} - {v.Tipo}</p>
                                    </div>
                                    <div className="col s2 center-align">
                                        {
                                            cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                                <img src={ICONS.CHECK_BLACK} className="mt-1" alt='Check' />
                                                : <img src={ICONS.CIRCLE_OUTLINE} className="mt-1" alt='Check' />
                                        }

                                    </div>

                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            )

            // }



        }

        return <div></div>;

    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, coordenadas, ip, saldoTotal, saldoOperacion
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false

    }

    if (state.commonsActions) {
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        coordenadas = state.commonsActions.coordenadas || false;
        ip = state.commonsActions.ip || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        saldoOperacion = state.commonsActions.saldoOperacion || 0;
    }

    return { dataUser, currentContrato, menuPerfil, contratosUser, chequera, history, coordenadas, ip, saldoTotal, saldoOperacion };
};

export default connect(mapStateToProps,
    {
        showToken, okButton, recargaCartera, getCoordenadas, setCoordenadas
    })(Venta);