import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showToken, setHistory, okButton, hideOperacion, setOperacion } from '../redux/actions';
import { REGEXS_NEW_PASS, makeCall, encryptValues } from '../utils';
import { ICONS } from '../assets/icons';
import Reload from './reload';
import { ACCION_CODIGO, PATHS, SERVICES, TEXTOS } from '../properties';
import firebase from 'firebase';


class ResetPass extends Component {

    state = {
        password: "",
        passwordCopy: "",
        errorpassword: false,
        errorpasswordCopy: false,
        showPassword: false,
        showPasswordCopy: false,
        loading: false,
        REGEX_CHECK: REGEXS_NEW_PASS,
        errors: [],
        email: '',
        palabrasRestringidas: []
    }

    async componentDidMount() {

        if (!this.props.data || !this.props.data.activacionWeb) {


            if (this.props.match && this.props.match.params) {
                let { email } = this.props.match.params;
                if (email) {
                    this.setState({ email });
                } else {
                    this.props.history.push(PATHS.LOGIN);
                }
            } else {
                this.props.history.push(PATHS.LOGIN);
            }

            if (!this.props.tokentoPass) {
                this.props.history.push(PATHS.LOGIN);
            }

            firebase.analytics().logEvent('login_contrasenia');
            this.props.setHistory(this.props.history);
        }else{
            firebase.analytics().logEvent('contrasenia_activaWeb');
            this.setState({ email: this.props.data.email });
        }

       

        await this.getPalabrasRestringidas();
    }

    getPalabrasRestringidas = async () => {
        try {
            let palabrasRestringidas = [];
            const palabras = await firebase.firestore().doc('configuraciones/contrasenia').get();

            if (palabras.exists) {
                palabrasRestringidas = palabras.data().palabras_restringidas || [];
            }

            this.setState({ palabrasRestringidas });
        } catch (error) {
            // console.log(error);
        }
    }

    onChangeData = (event) => {

        let { id, value } = event.target;

        this.setState({ [`error${id}`]: false, [id]: value }, () => {
            let { password, passwordCopy, REGEX_CHECK, palabrasRestringidas } = this.state;
            let errors = [];
            let errorpasswordCopy = false;
            let errorpassword = false;

            const filtroPalabras = palabrasRestringidas.filter((v) => {
                return value.includes(v);
            });

            if (filtroPalabras.length) {

                errorpassword = `No debe incluir 
                ${filtroPalabras.length > 1 ? `las palabras ${filtroPalabras.join(',')}`
                        : `la palabra ${filtroPalabras.join('')} `}`
            } else {
                if (id === "password") {
                    REGEX_CHECK.forEach((v, i) => {
                        REGEX_CHECK[i].isCorrect = value.trim() !== "" ? v.validator(value) : false;
                        if (!REGEX_CHECK[i].isCorrect) {
                            errors.push(REGEX_CHECK[i].isCorrect);
                        }
                    });
                    if (value.trim() !== passwordCopy.trim()) {
                        errorpasswordCopy = "Las contraseñas no coinciden.";
                    }
                    this.setState({ REGEX_CHECK, errors, errorpasswordCopy })
                } else if (id === "passwordCopy") {
                    if (value.trim() !== password.trim()) {
                        errorpasswordCopy = "Las contraseñas no coinciden.";
                    }

                }
            }

            this.setState({ errorpasswordCopy, errorpassword })

        });

    }

    submitData = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        let { email, password } = this.state;

        try {
            //debugger;
            let accionSolicitud = ACCION_CODIGO.RECUPERACION;

            if (this.props.dataUser) {
                accionSolicitud = ACCION_CODIGO.ACTUALIZA_PASS;
            }

            if (this.props.data && this.props.data.activacionWeb) {
                accionSolicitud = ACCION_CODIGO.ACTIVACION_WEB;
            }

            let bodyGuardaDatos = SERVICES.GUARDA_DATOS.body;
            bodyGuardaDatos.IdUsuario = encryptValues(email.trim().toLowerCase());
            bodyGuardaDatos.Contrasenia = encryptValues(password);
            bodyGuardaDatos.Acciones = encryptValues(accionSolicitud);
            bodyGuardaDatos.FingerPrint = encryptValues(this.props.fingerPrint);
            bodyGuardaDatos.latitude = encryptValues(this.props.coordenadas.latitude);
            bodyGuardaDatos.longitude = encryptValues(this.props.coordenadas.longitude);
            // bodyGuardaDatos.Client_id = encryptValues(window.config.CLIENT_ID);
            // bodyGuardaDatos.Client_secr = encryptValues(window.config.CLIENT_SECRET);

            if(accionSolicitud === ACCION_CODIGO.ACTIVACION_WEB){
                bodyGuardaDatos.AvisoPrivacidad = 1;
                bodyGuardaDatos.TyC = 1;
            }

            let resultGuardaDatos = await makeCall(SERVICES.GUARDA_DATOS, bodyGuardaDatos);

            if (resultGuardaDatos && resultGuardaDatos.response) {
                if (resultGuardaDatos.response.Exito === 200) {
                    // M.toast({html:TEXTOS.PASS_ACTUALIZADO.message,classes:'green'});

                    if (this.props.data && this.props.data.activacionWeb) {

                        this.props.setOperacion({
                            title: `Contraseña guardada`,
                            opt: "successActivacion"
                        })

                    } else {
                        this.props.okButton({
                            message: TEXTOS.PASS_ACTUALIZADO.message, title: "", txtButton: "Entendido",
                            statusCode: 200
                        });
                        this.props.history.push(PATHS.LOGIN);
                    }


                } else {
                    this.setState({ loading: false });
                    if (resultGuardaDatos.response.Exito === 409) {
                        this.props.okButton({
                            message: resultGuardaDatos.response.MensajeError, title: "", txtButton: "Entendido",
                            statusCode: 404
                        });
                    } else {
                        this.props.okButton({
                            message: "Ocurrió un error inesperado intente de nuevo.", title: "", txtButton: "Entendido",
                            statusCode: 404
                        });
                    }



                }
            }
        } catch (error) {
            this.props.okButton({
                message: "Ocurrió un error inesperado intente de nuevo.", title: "", txtButton: "Entendido",
                statusCode: 404
            });
            this.setState({ loading: false });
        }

    }

    render() {
        let { password, passwordCopy, errorpassword, errorpasswordCopy,
            showPassword, showPasswordCopy, REGEX_CHECK, errors,
            loading } = this.state;

           

        return (
            <div className={`unlockContainer valign-wrapper ${!this.props.data || !this.props.data.activacionWeb ? 'greyBackground' : 'whiteBackground'}`}>
                <div className="row blockUnlock">
                    {
                        !this.props.data || !this.props.data.activacionWeb ?

                            <div className="col s12 navyBackground white-text headerContent">
                                <i className="material-icons cursorPointer left"
                                    onClick={() => {
                                        this.props.history.push(PATHS.LOGIN);
                                    }}
                                >keyboard_backspace</i> Nueva Contraseña


                            </div>

                            : null
                    }

                    <form onSubmit={this.submitData} className="col s12" >
                        <div className="row mt-10px">
                            <div className="col s12 mb-10">Ingresa tu nueva contraseña para acceder al servicio de la app.</div>

                            <div className="input-field col s12">
                                <img
                                    onClick={
                                        () => {
                                            this.setState({ showPassword: !showPassword });
                                        }
                                    }
                                    src={showPassword ? ICONS.HIDE : ICONS.SHOW} alt="hide" className="visibilityEyes"></img>
                                <input id="password" type={showPassword ? "text" : "password"}
                                    value={password} onChange={this.onChangeData}
                                    className={
                                        errorpassword ? "invalid" : ""
                                    } />
                                <label htmlFor="password">Contraseña</label>
                                <span className="helper-text" data-error={errorpassword} ></span>
                            </div>

                            <div className="input-field col s12">
                                <img
                                    onClick={
                                        () => {
                                            this.setState({ showPasswordCopy: !showPasswordCopy });
                                        }
                                    }
                                    src={showPasswordCopy ? ICONS.HIDE : ICONS.SHOW} alt="hide" className="visibilityEyes"></img>
                                <input id="passwordCopy" type={showPasswordCopy ? "text" : "password"}
                                    value={passwordCopy} onChange={this.onChangeData}
                                    disabled={errorpassword || password.trim() === ""}
                                    className={
                                        errorpasswordCopy ? "invalid" : ""
                                    } />
                                <label htmlFor="passwordCopy">Confirmar Contraseña</label>
                                <span className="helper-text" data-error={errorpasswordCopy} ></span>
                            </div>

                            <div className="col s12">
                                <div className="row validationList">
                                    <div className="col s12">


                                        {
                                            REGEX_CHECK.map((v, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className={`col s1 conditionPass ${v.isCorrect ? "valid" : "invalid"}`}>
                                                            <i className="material-icons left">{
                                                                v.isCorrect ? 'check' : 'close'
                                                            }</i>
                                                        </div>
                                                        <div className={`col s11 conditionPass ${v.isCorrect ? "valid" : "invalid"}`}>
                                                            {v.text}
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='col s12 left-align' style={{ paddingLeft: "1.5rem" }}>
                                <i className="material-icons left ">info_outline</i><span className='ft-10'>Consulta más recomendaciones de seguridad en <a href='https://www.valmex.com.mx' target='_blank' className='boldText black-text'>valmex.com.mx</a></span>
                            </div>

                            <div className="col s12 center-align mt-1">
                                {
                                    loading ?
                                        <Reload action={() => { }} />

                                        :
                                        <button className="btn primaryBtn"
                                            disabled={errorpassword || errorpasswordCopy ||
                                                password.trim() === "" || passwordCopy.trim() === ""
                                                || errors.length
                                            }
                                        >Guardar Contraseña</button>
                                }

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { fingerPrint, coordenadas, tokentoPass, dataUser } = false;

    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
    }

    if (state.commonsActions) {
        coordenadas = state.commonsActions.coordenadas || false;
        fingerPrint = state.commonsActions.fingerPrint || false;
        tokentoPass = state.commonsActions.tokentoPass || false;
    }
    return { fingerPrint, coordenadas, tokentoPass, dataUser };
};


export default connect(mapStateToProps, { showToken, setHistory, okButton, hideOperacion, setOperacion })(ResetPass)
