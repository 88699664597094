import React, { Component } from 'react';
import { ICONS } from '../assets/icons';
import { connect } from 'react-redux';
import { hideOperacion } from '../redux/actions';

class SuccessLoginWeb extends Component {

    render() {

        return (
            <div className='col row'>
                <div className='col s12 center-align mt-4'>
                    <img src={ICONS.OK_SUCCESS} alt='Activación Exitosta' />
                </div>
                <div className='col s12 center-align'>
                    <p className='ft-24 NavyPrimary'>
                        Bienvenido
                    </p>
                    <p className='ft-16 NavyPrimary'>
                    Ya puedes consultar la información de tú cuenta.
                    </p>
                </div>
                <div className='col s12 center-align mt-1'>
                    <button className="btn primaryBtn"
                    onClick={()=>{
                        if(this.props.data && this.props.data.esLogin){
                            this.props.data.esLogin();
                        }
                    }}
                    >Continuar</button>
                </div>
            </div>
        )
    }

}


export default connect(null, {hideOperacion })(SuccessLoginWeb)