import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateEmail } from '../utils';
import Reload from './reload';
import { SERVICES, ACCION_CODIGO } from '../properties';
import { makeCall, encryptValues, decryptValue } from '../utils';
import { ICONS } from '../assets/icons';
import { setOperacion, hideOperacion, hide_okButton, okButton } from '../redux/actions';
import TerminosCondiciones from './terminosCondiciones';
import AvisoPrivacidad from './avisoPrivacidad';
import M from 'materialize-css';

class ValidaDatos extends Component {

    state = {
        emailValida: '',
        contrato: '',
        aceptaAviso: false,
        aceptaTerminos: false,
        erroremailValida: false,
        errorcontrato: false,
        disabledForm: false,
        step: 1,
        loading: false,
        datosErroneos: false,
        responseValida: false,
        verAviso: false,
        verTerminos: false
    }


    componentDidUpdate(prevPros) {

    }

    componentDidMount() {
        this.getDisableForm();


        if (this.props.data && this.props.data.esLogin) {
            this.setState({ step: 2, emailValida: this.props.data.email });
        }
    }

    solicitaCodigo = async () => {
        this.setState({ loading: true });
        const { responseValida, emailValida } = this.state;


        let bodySolicitaCodigo = SERVICES.SOLICITA_CODIGO.body;
        bodySolicitaCodigo.IdUsuario = encryptValues(emailValida.trim().toLowerCase());
        //bodySolicitaCodigo.Passcode = encryptValues(decryptValue(responseValida.response.PassCode));
        //bodySolicitaCodigo.Celular =this.props.data && this.props.data.esLogin ? encryptValues(this.props.data.responseValida.Celular) : encryptValues(responseValida.response.Celular);
        bodySolicitaCodigo.Accion = this.props.data && this.props.data.esLogin ? encryptValues(ACCION_CODIGO.ACCESO_WEB) : encryptValues(ACCION_CODIGO.ACTIVACION_WEB);

        // bodySolicitaCodigo.Client_id = encryptValues(window.config.CLIENT_ID);
        // bodySolicitaCodigo.Client_secr = encryptValues(window.config.CLIENT_SECRET);

        const result = await makeCall(SERVICES.SOLICITA_CODIGO, bodySolicitaCodigo);

        this.setState({ loading: false });

        let dataToOperacion = this.state;
     
        if(result.response.Exito === 200){
            if (this.props.data && this.props.data.esLogin) {
        
                dataToOperacion.esLogin = this.props.data.esLogin;
                dataToOperacion.muestraAceptaGeoWeb = this.props.data.responseValida.hasOwnProperty('AceptoGeoWEB') ?  this.props.data.responseValida.AceptoGeoWEB != 1 ? true : false : false;
    
            } else {
                dataToOperacion.esLogin = false;
            }
    
            this.props.setOperacion({
                title: `Código de seguridad`,
                opt: "tokenActivacion",
                data: dataToOperacion
            })
        }else if(result.response.Exito === 407){
            this.props.hideOperacion();
            this.props.okButton({
                message: result.response.MensajeError, title: "Advertencia",
                statusCode: 404,
                txtButton: "Entendido",
                actionClick: () => {
                    this.props.hide_okButton();
                }
            });
        }

      


    }

    submitData = async (e) => {
        e.preventDefault();
        const { contrato, emailValida } = this.state;
        this.setState({ loading: true });
        if (contrato.trim() !== "" && emailValida.trim() !== "") {
            let body = SERVICES.VALIDA_IDENTIDAD.body;
            // body.Client_id = encryptValues(window.config.CLIENT_ID);
            // body.Client_secr = encryptValues(window.config.CLIENT_SECRET);
            body.IdUsuario = encryptValues(emailValida.trim().toLowerCase());
            body.Accion = encryptValues(ACCION_CODIGO.ACTIVACION_WEB);
            body.Contrato = encryptValues(contrato)

            let result = await makeCall(SERVICES.VALIDA_IDENTIDAD, body, (errorCall) => {
                this.setState({ loading: false })
                this.serviciosDisponibles(errorCall);
            });


            if (result.response && result.response.Exito !== 200) {

                this.setState({ datosErroneos: true, loading: false });
            }

            if (result.response && result.response.Exito === 200) {

                this.setState({ loading: false, step: 2, responseValida: result });
            }


        }
    }

    onChangeData = (event) => {
        let { id, value } = event.target;

        this.setState({ [`error${id}`]: false }, () => {

            if (id === "emailValida") {
                value = value.trim().toLowerCase();
            }

            this.setState({ [id]: value }, () => {
                if (id === "emailValida") {
                    if (!validateEmail(value)) {
                        this.setState({ erroremailValida: "Escribir un correo válido." });
                    }
                }
                this.getDisableForm();
            });
            this.getDisableForm();

        });

    }

    getDisableForm() {

        let { erroremailValida, errorcontrato, emailValida, contrato, aceptaAviso, aceptaTerminos } = this.state;

        if (erroremailValida || errorcontrato
            ||
            emailValida.trim() === "" || contrato.trim() === "" || !aceptaAviso || !aceptaTerminos
        ) {
            this.setState({ disabledForm: true });
        } else {
            this.setState({ disabledForm: false });
        }
    }

    onChangeValueCheck = (event) => {


        let { id } = event.target;
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({ [id]: value }, () => {
            this.getDisableForm();
        });
    }

    render() {
        const {
            emailValida,
            erroremailValida,
            contrato,
            errorcontrato,
            disabledForm,
            step,
            loading, datosErroneos, verTerminos
        } = this.state;

        if (datosErroneos) {
            return (
                <div className='row'>
                    <div className='col s12 center-align mt-5'>
                        <img src={ICONS.ERROR_DATOS} alt="DATOS NO VALIDOS" />
                    </div>
                    <div className='col s12 center-align mt-2'>
                        <span className='ft-24 NavyPrimary'>Datos incorrectos</span>
                    </div>
                    <div className='col s12 center-align mt-2'>
                        <span className='ft-16 NavyPrimary'>Para mayor información
                            te invitamos a consultar
                            a tu promotor.</span>
                    </div>
                </div>
            )
        }

        if (step === 1) {
            return (
                <React.Fragment>
                <div className='row'>
                    <div className='col s12 left-align mt-2'>
                        Ingresa tu mail y número de contrato para que sean verificados
                    </div>
                    <form onSubmit={this.submitData} className='row'>
                        <div className="input-field col s12 mb-0px mt-2">
                            <input id="emailValida" type="text" value={emailValida.toLowerCase()} className={
                                erroremailValida ? "invalid" : ""
                            } onChange={this.onChangeData}

                            />
                            <label htmlFor="emailValida">Mail</label>
                            <span className="helper-text" data-error={erroremailValida} ></span>
                        </div>
                        <div className="input-field col s12 mb-0px">
                            <input id="contrato" type="text" value={contrato.toLowerCase()} className={
                                errorcontrato ? "invalid" : ""
                            } onChange={this.onChangeData}

                            />
                            <label htmlFor="contrato">Contrato</label>
                            <span className="helper-text" data-error={errorcontrato} ></span>
                        </div>
                        <div className='switch col s12 left-align mt-1
                        cursorPointer brandTextPrimaryUnderline
                        ' style={{ marginLeft: '-18px' }}>
                            <span onClick={() => {
                                this.setState({ verTerminos: true }, () => {
                                    let instance = M.Modal.getInstance(document.getElementById('modalATTerminos'));

                                    if (instance) {
                                        instance.open();
                                    }else{
                                        M.Modal.init(document.getElementById('modalATTerminos'), {}).open();

                                    }
                                });
                            }}>Aceptar términos y condiciones</span>
                            <label className="left">
                                <input type="checkbox" id="aceptaTerminos"
                                    onChange={this.onChangeValueCheck}
                                    checked={this.state.aceptaTerminos || false}
                                />
                                <span className="lever"></span>
                            </label>

                        </div>
                        <div className='switch col s12 left-align mt-1
                        cursorPointer brandTextPrimaryUnderline' style={{ marginLeft: '-18px' }}>
                           <span onClick={() => {
                                this.setState({ verAviso: true }, () => {
                                    let instance = M.Modal.getInstance(document.getElementById('modalATAviso'));

                                    if (instance) {
                                        instance.open();
                                    }else{
                                        M.Modal.init(document.getElementById('modalATAviso'), {}).open();

                                    }
                                });
                            }}
                           > Aceptar aviso de privacidad</span>
                            <label className="left">
                                <input type="checkbox" id="aceptaAviso"
                                    onChange={this.onChangeValueCheck}
                                    checked={this.state.aceptaAviso || false}
                                />
                                <span className="lever"></span>
                            </label>


                        </div>

                        <div className='col s12 center-align mt-3'>
                            {
                                loading || this.props.cargaInfo ?
                                    <Reload action={() => { }} />

                                    :
                                    <button className="btn primaryBtn"
                                        disabled={disabledForm || erroremailValida ||
                                            emailValida.trim() === "" || contrato.trim() === ""
                                        }
                                    >Validar datos</button>
                            }
                        </div>

                        

                    </form>

                </div>
            </React.Fragment>
            )
        }

        if (step === 2) {
            return (
                <div className='row'>
                    <div className='col s12 center-align mt-4'>
                        <span className='ft-16 NavyPrimary'>Estimado usuario enviaremos un código de seguridad al mail registrado en tu contrato. </span>
                    </div>
                    <div className='col s12 center-align mt-2'>
                        <span className='ft-16 NavyPrimary'>Te recordamos que este código vence en los próximos 5 minutos.</span>
                    </div>
                    <div className='col s12 center-align mt-3'>


                        {
                            loading || this.props.cargaInfo ?
                                <Reload action={() => { }} />

                                :
                                <button className="btn primaryBtn"
                                    onClick={() => {
                                        this.solicitaCodigo();
                                    }}
                                >Enviar</button>
                        }
                    </div>
                </div>
            )
        }

    }

}

export default connect(null, {
    setOperacion, hideOperacion, hide_okButton, okButton
})(ValidaDatos)


