import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { nWithCommas, addSuscribe } from '../../utils';
import {
    setComposicion, okButton, setComposicionSaldoLiquido, recargaCartera,
    setSaldoTotal, setRendimientos, setTiposServicios
} from '../../redux/actions';
import 'chartjs-plugin-labels';
import { PIE_PLUGIN } from '../../properties';
import Reload from '../reload';
import firebase from 'firebase';
import M from 'materialize-css';
import _ from 'lodash';

const TXT = "La posición muestra precios de valuación al cierre del día hábil anterior, los saldos y la posición de la cartera pueden verse afectados por los movimientos y operaciones que se realicen en el transcurso del día.";

class PosicionCartera extends Component {

    state = {
        loading: true,
        posicionCartera: false,
        dataCake: false,
        contrato: false,
        dataUserConfig: false,
        esRecargaCartera: false
    }


    async componentDidUpdate(prevProps, prevState) {


        if (!_.isEqual(prevProps.posicionCartera, this.props.posicionCartera)) {
            if (this.props.posicionCartera) {
                this.getDataCake(this.props.posicionCartera);
            }
        }
    }

    async componentDidMount() {

        if (this.props.posicionCartera) {
            this.getDataCake(this.props.posicionCartera);
        }

    }

    // async getSaldoTotal() {
    //     try {
    //         let saldoTotal = await getSaldoLiquido(this.props)
    //         this.props.setSaldoTotal(saldoTotal);
    //     } catch (error) {

    //     }

    // }

    getConfig = () => {
        try {

            let refSnap = firebase.firestore().doc(`/usuarios/${this.props.dataUser.IdUsuario}/configuraciones/usuario`).onSnapshot((snap) => {
                if (snap.exists) {
                    let dataUserConfig = snap.data();
                    this.setState({ dataUserConfig });
                } else {
                    this.setState({ dataUserConfig: { mostrar_saldo: true } });
                }
            })

            addSuscribe(refSnap);
        } catch (error) {

        }
    }

    getDataCake = (posicionCartera) => {
        let labels = [];
        let data = [];
        let backgroundColor = [];

        if (posicionCartera.detalle && posicionCartera.detalle && posicionCartera.detalle.length) {

            //suma de porcentajes
            let sumaPorcentajes = 0;
            for (let porcentajes of posicionCartera.detalle) {
                let porcentaje = porcentajes[0] ? porcentajes[0].Porcentaje : 0;

                sumaPorcentajes += porcentaje;

            }



            if (sumaPorcentajes > 0) {
                for (let dPC of posicionCartera.detalle) {

                    let d = dPC[0];

                    if (d.DescRama.trim() === 'EFECTIVO' && d.detalle && d.detalle.length) {
                        this.props.setComposicionSaldoLiquido(d);
                    }

                    //if (d.MontoMdoMN > 0) {
                    let descRama = d.DescRama.trim() === 'EFECTIVO' ? 'SALDO LÍQUIDO' : d.DescRama.trim();
                    labels.push(descRama);

                    data.push(d.Porcentaje);
                    backgroundColor.push(this.getColorBG(d.DescRama.trim()));
                    //}
                }
            } else {
                labels.push('Cartera sin posición');
                data.push(-100);
                backgroundColor.push('#808080');
            }




            let dataCake = {
                labels,
                datasets: [{
                    data, backgroundColor
                }]
            }

            this.setState({ dataCake, posicionCartera, loading: false }, () => {
                M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
                this.getConfig();
            });
        }
    }


    render() {
        let { posicionCartera, loading, dataCake, dataUserConfig } = this.state;
        return (
            <div className="row">

                <ul className="collection with-header posicionCartera z-depth-2">
                    <li className="collection-header">
                        {
                            loading ?
                                <div className="row">
                                    <div className="col s12 center-align">
                                        <Reload action={() => { }} />
                                    </div>
                                </div>
                                :

                                <div className="row">
                                    <div className="col s6">
                                        <p>Posición de cartera <i className="material-icons iconF cursorPointer tooltipped"
                                            data-position="bottom" data-tooltip={TXT}
                                        >help_outline</i></p>
                                        {
                                            dataUserConfig && dataUserConfig.mostrar_saldo && posicionCartera.totalCartera.length
                                                && posicionCartera.totalCartera[0].MontoMdoMN
                                                ?
                                                <p className="boldText">${nWithCommas(parseFloat(posicionCartera.totalCartera[0].MontoMdoMN).toFixed(2).toString())}</p>
                                                : <p className="boldText">${nWithCommas(parseFloat(0).toFixed(2).toString())}</p>
                                        }

                                        <p>Composición</p>
                                    </div>
                                    <div className="col s6 right-align">
                                        <p>{this.props.dataUser.FechaSistema}</p>
                                    </div>
                                    <div className="col s12 center-align">
                                        {
                                            dataCake ?
                                                <Pie data={dataCake} options={PIE_PLUGIN}
                                                    width={200} height={100}
                                                />
                                                : null
                                        }

                                    </div>
                                </div>
                        }
                    </li>
                    {
                        posicionCartera.detalle && posicionCartera.detalle.length ?
                            posicionCartera.detalle.map((data, i) => {
                                let v = data[0];
                                if (v.MontoMdoMN !== 0) {


                                    return (
                                        <li className="collection-item hoverContent" key={i}>
                                            <div className="cursorPointer" onClick={() => {
                                                // debugger;
                                                if(v.detalle && v.detalle.length){
                                                    v.esSaldoProyectado = false;
                                                    this.props.setComposicion(v);
                                                }
                                            }}>
                                                <div className="row noMBottom flex">
                                                    <div className="col s1">
                                                        <div className="colorIndicator" style={{ backgroundColor: this.getColorBG(v.DescRama) }}>

                                                        </div>
                                                    </div>
                                                    <div className="col s10">
                                                        <p className="noMargin txtCap">
                                                            {v.DescRama.trim() === "EFECTIVO" ? 'Saldo líquido' : v.DescRama.trim().toLowerCase()}
                                                        </p>
                                                        {
                                                            dataUserConfig && dataUserConfig.mostrar_saldo ?
                                                                <p className="boldText">

                                                                    ${nWithCommas(parseFloat(v.MontoMdoMN).toFixed(2).toString())} ({v.Porcentaje}%)

                                                                </p>
                                                                : null}
                                                    </div>
                                                    <div className="col s1 valign-wrapper">
                                                        <i className={v.detalle && v.detalle.length ? 'material-icons cursorPointer ' : 'material-icons cursorPointer white-text'}

                                                        >keyboard_arrow_right</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                                return null;
                            })
                            : null
                    }
                    <li className="collection-item">
                        <div className="row">
                            <div className="col s12 ft-12">
                                Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                            </div>
                            <div className="col s12 ft-12 mt-1">
                                {TXT}
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        )
    }

    getColorBG = (DescRama) => {
        if (DescRama.trim().toLowerCase() === 'efectivo') {
            return '#12C3E2';
        }

        if (DescRama.trim() === 'INSTRUMENTOS DE DEUDA Y FONDOS DE INVERSIÓN DE DEUDA') {
            return '#6CDBC7';
        }

        if (DescRama.trim() === 'ACCIONES Y FONDOS DE INVERSIÓN DE RENTA VARIABLE') {
            return '#A6D043';
        }

        if (DescRama.trim() === 'INSTRUMENTOS EN OTRAS DIVISAS Y FONDOS DE INVERSIÓN DE DEUDA EN OTRAS DIVISAS') {
            return '#A25EB5';
        }

        if (DescRama.trim() === 'DERIVADOS') {
            return '#FFA400';
        }


    }
}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, esRecargaCartera, saldoTotal, posicionCartera,
        composicion
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false

    }

    if (state.commonsActions) {
        composicion = state.commonsActions.composicion || false;
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        esRecargaCartera = state.commonsActions.esRecargaCartera || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        posicionCartera = state.commonsActions.posicionCartera || false;
    }

    return {
        dataUser, currentContrato, menuPerfil, contratosUser, chequera, history,
        esRecargaCartera, saldoTotal, posicionCartera, composicion
    };
};
export default connect(mapStateToProps, {
    setComposicion, okButton, setComposicionSaldoLiquido,
    recargaCartera, setSaldoTotal, setRendimientos, setTiposServicios
})(PosicionCartera)