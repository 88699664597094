import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setMenuPrincipal, setComposicion, okButton, setOptionSelectedMenu } from '../redux/actions';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import { MENUS } from '../properties'
import logo from '../assets/logo.png';
import logoMobile from '../assets/logoMobile.png';
import { ICONS } from '../assets/icons';
import Perfil from './perfil';
import Reload from '../components/reload';
import firebase from 'firebase';
import { addSuscribe } from '../utils';
import NavNoSesion from './navNoSesion';

class Nav extends Component {

    state = {
        posicionCartera: false,
        contrato: false,
    }


    async componentDidUpdate(prevProps, prevState) {


        if (this.props.currentContrato && this.props.currentContrato.Contrato && this.props.dataUser) {
            if (this.state.contrato !== this.props.currentContrato.Contrato) {
                this.setState({ contrato: this.props.currentContrato.Contrato }, () => {
                    this.getMenu();
                    M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
                    M.Sidenav.init(document.querySelectorAll('.sidenavUser'), {
                        edge: "right",
                        onCloseEnd: () => {

                        }
                    });

                });
            }
        }
        
        if(this.props.mostrarNav && !prevProps.mostrarNav){
            this.getMenu();
                    M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
                    M.Sidenav.init(document.querySelectorAll('.sidenavUser'), {
                        edge: "right",
                        onCloseEnd: () => {

                        }
                    });
        }
    }

    async componentDidMount() {
        this.initSide();

        M.Sidenav.init(document.getElementById('sidenavNoSesion'), {});
    }

    getMenu = () => {
        try {

            let refSnap = firebase.firestore().collection(MENUS.PRINCIPAL).orderBy('posicion', "asc").onSnapshot((doc) => {
                // debugger;
                if (doc.docs.length) {
                    this.props.setMenuPrincipal(doc.docs);
                }

            });

            addSuscribe(refSnap);

        } catch (error) {


        }
    }

    render() {

        if(!this.props.mostrarNav){
            return <div>
                
                <NavNoSesion />
         
            </div>
        }

        return (
            <React.Fragment>
                <div className="navbar-fixed">
                    <nav>
                        <div className="nav-wrapper white">
                            <Link to="/" className="brand-logo">
                                <img src={logo} alt="Casa de Bolsa Valmex" className="logoValmexFull" />
                                <img src={logoMobile} alt="Casa de Bolsa Valmex" className="logoValmexMobile" />
                            </Link>
                            <ul className="left">
                                <li>
                                    <a href="#" className="black-text sidenav-trigger sideNavBtn" data-target="slide-menu"
                                        onClick={() => {
                                            firebase.analytics().logEvent('principal_hamburguesa');
                                        }}
                                    >
                                        <i className="material-icons boldText">menu</i>
                                    </a>
                                </li>
                            </ul>
                            {
                                this.props.dataUser ?
                                    <ul className="right">
                                        <li>
                                            <a href="#" className="black-text sidenav-trigger sideNavBtn" data-target="slide-menu-user"

                                                onClick={() => {
                                                    firebase.analytics().logEvent('Principal_perfil');
                                                }}

                                            >
                                                <div className="row flex">
                                                    <div className="col s10 right-align hide-on-med-and-down">
                                                        <p className="noMargin brandTextPrimary sName">{this.nameFromProps()}</p>
                                                        {
                                                            this.props.currentContrato ?
                                                                <p className="noMargin contratoSelect">Contrato: {this.props.currentContrato.Contrato}</p>
                                                                : null
                                                        }

                                                    </div>
                                                    <div className="col s4 m4 l2 xl2 right-align avatarContainer">

                                                        {
                                                            this.props.userAvatar && this.props.userAvatar.avatar ?
                                                                <a href="#user"><img className="avatarNav" src={`data:image/png;base64,${this.props.userAvatar.avatar}`} alt="avatar" /></a>
                                                                :
                                                                <img src={ICONS.AVATAR} className="circle responsive-img avatar" alt="avatar" />
                                                        }

                                                        
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    : null
                            }

                        </div>
                    </nav>

                </div>
                <ul id="slide-menu" className="sidenav">
                    <li className="center-align">
                        <img src={logoMobile} className="logoSide" alt="Casa de Bolsa Valmex" />
                    </li>
                    {
                        this.props.menuPrincipal ?
                            this.props.menuPrincipal.filter((e) => e.data().habilitado).map((v, i) => {
                                let data = v.data();
                                return (
                                    <li className="optSide sidenav-close" key={i}>
                                        <a href="!#"
                                            onClick={(event) => {
                                                event.preventDefault();

                                                if (data.tag) {
                                                    firebase.analytics().logEvent(data.tag);
                                                }
                                                //this.setState({ showMenu: false, optionSelected: data });
                                                if (data.titulo.trim().toLowerCase() === 'Saldo Proyectado'.toLowerCase()) {
                                                    //Muestra compsicíon saldo liquido
                                                    if (this.props.composicionSL) {
                                                        let composicion = this.props.composicionSL;
                                                        composicion.esSaldoProyectado = true;
                                                        this.props.setComposicion(composicion);
                                                    } else {
                                                        this.props.okButton({
                                                            message: "No se logro recuperar la información de la cartera.", title: "Advertencia", txtButton: "Ok",
                                                            statusCode: 404
                                                        });
                                                    }


                                                } else {
                                                    //this.setState({ showMenu: false, optionSelected: data });
                                                    if (this.props.history) {
                                                        this.props.history.push(`/${data.id}`);
                                                    }
                                                }


                                            }}
                                        ><img src={ICONS[data.icon]} className="customIcon left"></img>{data.titulo} <i className="material-icons right">keyboard_arrow_right</i></a></li>
                                )
                            })
                            : <Reload action={this.getMenu} />
                    }

                </ul>

        
            
                <Perfil />
            </React.Fragment>
        )
    }

    initSide = () => {
        setTimeout(() => {
            M.Sidenav.init(document.querySelectorAll('.sidenavUser'), {
                edge: "right",
                onCloseEnd: () => {
                    this.props.setOptionSelectedMenu(false);
                }
            });
        }, 1000)

    }

    nameFromProps = () => {
        if (this.props.dataUser) {
            let { Nombre, ApMaterno, ApPaterno } = this.props.dataUser;
            return `${Nombre ? Nombre :''} ${ApPaterno ? ApPaterno : ''} ${ApMaterno ? ApMaterno :  ''}`;
        }

        return "";
    }
}

const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPrincipal, history, composicionSL, userAvatar } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
    }

    if (state.commonsActions) {
        menuPrincipal = state.commonsActions.menuPrincipal || false;
        history = state.commonsActions.history || false;
        composicionSL = state.commonsActions.composicionSL || false;
        userAvatar = state.commonsActions.userAvatar || false;
    }
    return { dataUser, currentContrato, menuPrincipal, history, composicionSL, userAvatar };
};

export default connect(mapStateToProps, { setMenuPrincipal, setComposicion, okButton, setOptionSelectedMenu })(Nav);