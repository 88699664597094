import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideOperacion, setOperacion } from '../redux/actions';
import { ICONS } from '../assets/icons';
import Reload from './reload';
import firebase from 'firebase';

import Chequera from './operaciones/chequera';
import Deposito from './operaciones/deposito';
import Retiro from './operaciones/retiro';
import Movimientos from './informacion/movimientos';
import Documentos from './informacion/documentos';
import Historico from './informacion/historico';
import Compra from './operaciones/compra';
import Venta from './operaciones/venta';
import TerminosCondiciones from './terminosCondiciones';
import AvisoPrivacidad from './avisoPrivacidad';
import BitacoraInfo from './bitacoraInfo';
import ViewPdf from './pdfViewer';
import ContactoValmex from './contactoValmex';
import ValidaDatos from './validaDatos';
import TokenActivacion from './tokenActivacion';
import ResetPass from './resetPassword';
import SuccessActivacion from './successActivacion';
import SuccessLoginWeb from './successLoginWeb';


class Operaciones extends Component {

    state = {
        filtro: false,
        blockHistorico: false
    }


    toggleFiltro = () => {
        let filtro = this.state.filtro;
        if (!filtro) {
            firebase.analytics().logEvent('Mov_filtro');
        }
        this.setState({ filtro: !filtro })
    }

    render() {
        return (
            <div id="model_operacion"
                className={`modal  modal-fixed-footer  model_operacion ${this.props.operacion ?
                    this.props.operacion.opt.trim().toLowerCase() + '_modal' : ""}`
                }
            >


                <div className="modal-content">

                    {
                        this.props.operacion ?
                            this.getOperacion(this.props.operacion)
                            : <Reload />
                    }

                </div>
                <div className="modal-footer">
                    <div className="closeModalOk left-align">
                        <i className="material-icons cursorPointer arrowBackOpt" onClick={() => {
                            if (this.props.operacion.opt === 'historicoDocs') {
                                if (!this.state.blockHistorico) {
                                    this.props.setOperacion({ opt: "documentos", title: "Documentos" })
                                }
                            } else {
                                if (!this.state.blockHistorico) {
                                    this.props.hideOperacion();
                                }
                            }

                        }}>arrow_back</i> <span className="titleModal">{this.props.operacion ? this.props.operacion.title : ""}</span>

                        {
                            this.props.operacion && this.props.operacion.title.trim().toLowerCase() === 'movimientos'
                                ?
                                <span className="iconFilter"
                                    onClick={this.toggleFiltro}
                                ><img src={ICONS.FILTER_WHITE} alt="filtro" /></span>
                                : null
                        }
                    </div>
                </div>

            </div>
        )
    }

    blockReturn = (value) => {
        this.setState({ blockHistorico: value });
    }

    getOperacion = (operacion) => {
        let toShow = "";

        switch (operacion.opt) {
            case 'desposito_contrato':
                firebase.analytics().logEvent('principal_operaciones');
                toShow = <Deposito />
                break;
            case 'retiro_contrato':
                firebase.analytics().logEvent('principal_operaciones');
                toShow = <Retiro />
                break;
            case 'movimientos':
                firebase.analytics().logEvent('principal_movimientos');
                toShow = <Movimientos filtro={this.state.filtro} toggleFiltro={this.toggleFiltro}
                    startDate={operacion.startDate}
                />
                break;
            case 'documentos':
                toShow = <Documentos blockReturn={this.blockReturn} />
                break;
            case 'chequera':
                firebase.analytics().logEvent('Perfil_config_cta');
                toShow = <Chequera data={operacion.data} />;
                break;
            case 'comprar':
                firebase.analytics().logEvent('principal_operaciones');
                toShow = <Compra />;
                break;
            case 'vender':
                firebase.analytics().logEvent('principal_operaciones');
                toShow = <Venta />;
                break;
            case 'terminos_condiciones':
                toShow = <TerminosCondiciones />
                break;
            case 'aviso_privacidad':
                toShow = <AvisoPrivacidad />
                break;
            case 'historicoDocs':
                toShow = <Historico blockReturn={this.blockReturn} />
                break;
            case 'bitacoraInfo':
                toShow = <BitacoraInfo data={operacion.data} />
                break;
            case 'pdfViewer':
                toShow = <ViewPdf data={operacion.data} />
                break;
            case 'contactoValmex':
                toShow = <ContactoValmex data={operacion.data} />
                break;
            case 'validacionDatosActivacion':
                toShow = <ValidaDatos  data={operacion.data}/>
                break;
            case 'tokenActivacion':
                toShow = <TokenActivacion data={operacion.data} />
                break;
            case 'estableceContrasenia':
                toShow = <ResetPass data={operacion.data} />
                break;
            case 'successActivacion':
                toShow = <SuccessActivacion />
                break;
            case 'successLoginWeb':
                toShow = <SuccessLoginWeb data={operacion.data} />
                break;
            default:
                toShow = "Contenido Pendiente";
                break;
        }

        return toShow;
    }
}

const mapStateToProps = state => {
    let { operacion } = false;
    if (state.commonsActions) {
        operacion = state.commonsActions.operacion || false;
    }

    return { operacion };
};

export default connect(mapStateToProps, { hideOperacion, setOperacion })(Operaciones)